import React, { useState } from "react";
import BeforeLoginInformation from "../../common/beforeLoginInformation";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { cssProperties } from "../../utils/commonCssProperties";
import { useNavigate, useSearchParams } from "react-router-dom";
import verifyImage from "../../assets/images/verify.png";
import Cookies from "js-cookie";
import PrimaryButton from "../../common/button/primaryButton";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import authActions from "../../redux/auth/actions";
import { DecryptFunction } from "../../utils/cryptoFunction";

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordReType, setShowPasswordReType] = useState(false);

  const encryptedParams = searchParams.get("cid");
  const expiryTime = searchParams.get("expirytime");
  const candidateid = DecryptFunction(encryptedParams);
  

  const handleClickShowPasswordNew = () => {
    setShowPasswordNew((prev) => !prev);
  };

  const handleClickShowPasswordReType = () => {
    setShowPasswordReType((prev) => !prev);
  };

  const onSubmit = (data) => {
    if (isLengthValid && hasLowerCase && hasUpperCase && hasNumber && hasSpecialChar) {
      dispatch({type:authActions.CANDIDATE_RESET_PASSWORD,payload:{ data:{...data,candidateid:candidateid,expiryTime:expiryTime},navigate:navigate}})
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setIsLengthValid(value.length >= 8);
    setHasLowerCase(/[a-z]/.test(value));
    setHasUpperCase(/[A-Z]/.test(value));
    setHasNumber(/\d/.test(value));
    setHasSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(value));
  };


  return (
    <>
      <Grid container spacing={3} sx={{ padding: "20px 50px" }}>
        <Grid item lg={8} md={8} xs={12}>
          <Grid
            p={4}
            sx={{
              background: `${cssProperties?.backgroundcolor?.primary2}`,
              border: `1px solid ${cssProperties?.bordercolor?.tertiary}`,
              borderRadius: "21px 21px 10px 10px",
              minHeight: "568px",
              boxShadow: "2px 3px 12px 0px #0F4F9666",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {page !== "success" ? (
              <>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 500,
                    lineHeight: "24.2px",
                    textAlign: "left",
                  }}
                >
                  Reset Password
                </Typography>
                <Grid container spacing={2} pt={2}>
                  <Grid item lg={12}>
                    <Typography
                      pb={1}
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "16.94px",
                        textAlign: "left",
                      }}
                    >
                      New Password
                    </Typography>
                    <TextField
                      id="password"
                      {...register("password", {
                        required: "New password is required",
                        minLength: {
                          value: 8,
                          message: "Password must have at least 8 characters",
                        },
                      })}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      FormHelperTextProps={{
                        style: { margin: 0 },
                      }}
                      onChange={handlePasswordChange}
                      variant="outlined"
                      type={showPasswordNew ? "text" : "password"}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPasswordNew}
                              edge="end"
                            >
                              {showPasswordNew ? <FiEyeOff /> : <FiEye />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <Typography
                      pt={2}
                      pb={1}
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "16.94px",
                        textAlign: "left",
                      }}
                    >
                      Re-type New Password
                    </Typography>
                    <TextField
                      id="retypepassword"
                      {...register("retypepassword", {
                        required: "Re-type password is required",
                        minLength: {
                          value: 8,
                          message: "Password must have at least 8 characters",
                        },
                        validate: (value) =>
                          value === watch("password") ||
                          "Password does not match",
                      })}
                      error={!!errors.retypepassword}
                      helperText={errors.retypepassword?.message}
                      FormHelperTextProps={{
                        style: { margin: 0 },
                      }}
                      variant="outlined"
                      type={showPasswordReType ? "text" : "password"}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPasswordReType}
                              edge="end"
                            >
                              {showPasswordReType ? <FiEyeOff /> : <FiEye />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container pt={3} pb={2} spacing={2} sx={{ fontSize: "14px", fontWeight: 400, color: "#00000099" }}>
                  <Grid item xs={6} sx={{ display: "flex", alignItems: "center", color: isLengthValid ? "green" : errors.password || !isLengthValid ? "red" : "#00000099" }}>
                    <IoCheckmarkCircleOutline size={18} color={isLengthValid ? "green" : errors.password || !isLengthValid ? "red"  : "#00000099"} />
                    Password should be minimum 8 characters long
                  </Grid>
                  <Grid item xs={6} sx={{ display: "flex", alignItems: "center", color: hasLowerCase ? "green" : errors.password || !hasLowerCase ? "red" : "#00000099" }}>
                    <IoCheckmarkCircleOutline size={18} color={hasLowerCase ? "green" : errors.password || !hasLowerCase ? "red"  : "#00000099"} />
                    One lowercase letter
                  </Grid>
                  <Grid item xs={6} sx={{ display: "flex", alignItems: "center", color: hasUpperCase ? "green" : errors.password || !hasUpperCase ? "red" : "#00000099" }}>
                    <IoCheckmarkCircleOutline size={18} color={hasUpperCase ? "green" : errors.password || !hasUpperCase ? "red"  : "#00000099"} />
                    One uppercase letter
                  </Grid>
                  <Grid item xs={6} sx={{ display: "flex", alignItems: "center", color: hasNumber ? "green" : errors.password || !hasNumber ? "red" : "#00000099" }}>
                    <IoCheckmarkCircleOutline size={18} color={hasNumber ? "green" : errors.password || !hasNumber ? "red" : "#00000099"} />
                    One number
                  </Grid>
                  <Grid item xs={6} sx={{ display: "flex", alignItems: "center", color: hasSpecialChar ? "green" : errors.password || !hasSpecialChar ? "red" : "#00000099" }}>
                    <IoCheckmarkCircleOutline size={18} color={hasSpecialChar ? "green" : errors.password || !hasSpecialChar ? "red" : "#00000099"} />
                    One special character
                  </Grid>
                </Grid>

                <PrimaryButton
                  text={"Update Password"}
                  handleClick={handleSubmit(onSubmit)}
                />
              </>
            ) : (
              <>
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    minHeight: "70vh",
                  }}
                >
                  <Box
                    component="img"
                    sx={{ height: "90px", width: "auto" }}
                    src={verifyImage}
                  />
                  <Typography
                    mt={2}
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontWeight: 500,
                      lineHeight: "24.2px",
                      textAlign: "center",
                    }}
                  >
                    Password Change Successfully
                  </Typography>
                  <Typography
                    mt={2}
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 400,
                      lineHeight: "20px",
                      textAlign: "center",
                    }}
                  >
                    Your password has been updated successfully. Please click
                    here to log in with your new password
                  </Typography>
                  <Box pt={2} sx={{ width: isMobile ? "15rem" : "30rem" }}>
                    <PrimaryButton
                      handleClick={() =>
                        navigate(`/${Cookies.get("mhet_cnd_project")}/login`)
                      }
                      text={"Go To Login Page"}
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          lg={4}
          md={4}
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <BeforeLoginInformation />
        </Grid>
      </Grid>
    </>
  );
}
