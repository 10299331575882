// import React, { useState } from "react";
// import Cropper from "react-easy-crop";
// import {
//   Modal,
//   Box,
//   Typography,
//   Grid,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
//   useMediaQuery,
// } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import SubmitButton from "../../common/submitButton";
// import SaveButton from "../../common/saveButton";

// export default function ImageCrop({
//   showModal,
//   handleClose,
//   image,
//   onCropDone,
//   onCropCancel,
// }) {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [zoom, setZoom] = useState(1);
//   const [croppedArea, setCroppedArea] = useState(null);
//   const [aspectratio, setAspectRatio] = useState(4 / 3);

//   const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
//     setCroppedArea(croppedAreaPixels);
//   };

//   const onAspectRatioChange = (event) => {
//     setAspectRatio(parseFloat(event.target.value));
//   };

//   return (
//     <Modal
//       open={showModal}
//       // onClose={handleClose}
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//     >
//       <Box
//         sx={{
//           bgcolor: "background.paper",
//           boxShadow: 24,
//           p: isMobile ? 2 : 4,
//           width: isMobile ? "90%" : 800,
//           maxWidth: isMobile ? "100%" : "none",
//         }}
//       >
//         <Typography id="crop-image-modal-title" variant="h6" component="h2">
//           Crop Image
//         </Typography>
//         <Box sx={{ height: isMobile ? "40vh" : "50vh", position: "relative", mt: 2 }}>
//           <Cropper
//             image={image}
//             aspect={aspectratio}
//             crop={crop}
//             zoom={zoom}
//             onCropChange={setCrop}
//             onZoomChange={setZoom}
//             onCropComplete={onCropComplete}
//             style={{
//               containerStyle: {
//                 height: "100%",
//                 width: "100%",
//                 backgroundColor: "#fff",
//               },
//             }}
//           />
//         </Box>
//         <RadioGroup
//           row
//           aria-label="aspect-ratio"
//           name="aspect-ratio"
//           value={aspectratio}
//           onChange={onAspectRatioChange}
//           sx={{
//             mt: 2,
//             display: "flex",
//             justifyContent: "center",
//             flexWrap: isMobile ? "wrap" : "nowrap",
//           }}
//         >
//           <FormControlLabel value={1 / 1} control={<Radio />} label="1:1" />
//           <FormControlLabel value={5 / 4} control={<Radio />} label="5:4" />
//           <FormControlLabel value={4 / 3} control={<Radio />} label="4:3" />
//           <FormControlLabel value={3 / 2} control={<Radio />} label="3:2" />
//           <FormControlLabel value={5 / 3} control={<Radio />} label="5:3" />
//           <FormControlLabel value={16 / 9} control={<Radio />} label="16:9" />
//           <FormControlLabel value={3 / 1} control={<Radio />} label="3:1" />
//         </RadioGroup>
//         <Grid
//           container
//           justifyContent="flex-end"
//           spacing={2}
//           sx={{ mt: 2 }}
//         >
//           <Grid item>
//             <SaveButton name="cancel" handleDraft={onCropCancel} />
//           </Grid>
//           <Grid item>
//             <SubmitButton
//               name="Crop & Apply"
//               handleSubmit={() => {
//                 onCropDone(croppedArea);
//               }}
//             />
//           </Grid>
//         </Grid>
//       </Box>
//     </Modal>
//   );
// }
import React, { useRef, useState } from "react";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal, Box, Typography, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SubmitButton from "../../common/submitButton";
import SaveButton from "../../common/saveButton";
import './imageCrop.css';

const MIN_DIMENSION = 75;
const MAX_IMAGE_SIZE = 450; // Max size for width and height

export default function ImageCrop({
  showModal,
  handleClose,
  image,
  onCropDone,  
  onCropCancel
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const imgRef = useRef(null);
  const [crop, setCrop] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(1);
  const [scaledDimensions, setScaledDimensions] = useState({ width: 0, height: 0 });
  const [scaleFactor, setScaleFactor] = useState(1);

  const onImageLoad = (e) => {
    const naturalWidth = e.target.naturalWidth;
    const naturalHeight = e.target.naturalHeight;

    let scaledWidth = naturalWidth;
    let scaledHeight = naturalHeight;

    // Scale down if the image is larger than 600x600
    if (naturalWidth > MAX_IMAGE_SIZE || naturalHeight > MAX_IMAGE_SIZE) {
      const factor = MAX_IMAGE_SIZE / Math.max(naturalWidth, naturalHeight);
      scaledWidth = Math.round(naturalWidth * factor);
      scaledHeight = Math.round(naturalHeight * factor);

      setScaleFactor(factor); // Store the scaling factor for later use
    } else {
      setScaleFactor(1); // No scaling applied if within max dimensions
    }

    setScaledDimensions({ width: scaledWidth, height: scaledHeight });

    const initialCrop = makeAspectCrop(
      { unit: "px", width: scaledWidth },
      aspectRatio,
      scaledWidth,
      scaledHeight
    );
    const centeredCrop = centerCrop(initialCrop, scaledWidth, scaledHeight);
    setCrop(centeredCrop);
  };

  const onCropChange = (newCrop) => {
    const adjustedCrop = {
      ...newCrop,
      width: Math.max(newCrop.width, MIN_DIMENSION),
      height: Math.max(newCrop.height, MIN_DIMENSION),
    };
    setCrop(adjustedCrop);
  };

  const onCropImage = () => {
    if (imgRef.current && crop) {
      // Convert crop to pixel-based using scaled dimensions
      const pixelCrop = convertToPixelCrop(crop, scaledDimensions.width, scaledDimensions.height);

      const canvas = document.createElement("canvas");
      // Adjust canvas size for the crop and apply scaling factor to maintain quality
      canvas.width = pixelCrop.width / scaleFactor;
      canvas.height = pixelCrop.height / scaleFactor;
      const ctx = canvas.getContext("2d");

      // Draw the cropped area, accounting for the scaling factor
      ctx.drawImage(
        imgRef.current,
        pixelCrop.x / scaleFactor,
        pixelCrop.y / scaleFactor,
        pixelCrop.width / scaleFactor,
        pixelCrop.height / scaleFactor,
        0,
        0,
        pixelCrop.width / scaleFactor,
        pixelCrop.height / scaleFactor
      );

      const dataURL = canvas.toDataURL("image/jpeg");
      onCropDone(dataURL, pixelCrop);
    }
  };

  return (
    <Modal open={showModal} onClose={handleClose} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Box
        sx={{
          bgcolor: "background.paper",
          boxShadow: 24,
          p: isMobile ? 2 : 0,
          height: "90%",
          width: "70%",
          maxWidth: isMobile ? "100vh" : "none",
        }}
      >
        <Typography pt={1} px={2} id="crop-image-modal-title" variant="h6" component="h2">
          Crop Image
        </Typography>
        {image && (
          <Box sx={{ height: "80%", overflow: "auto", display: "flex", alignItems: "top", justifyContent: "center" }}>
            <ReactCrop crop={crop} onChange={onCropChange} aspect={aspectRatio} style={{width: scaledDimensions.width, height: scaledDimensions.height }}>
              <img ref={imgRef} src={image} alt="Crop preview" onLoad={onImageLoad} style={{ width: scaledDimensions.width, height: scaledDimensions.height }} />
            </ReactCrop>
          </Box>
        )}
        <Grid container justifyContent="flex-end" spacing={2} sx={{ pr: 3,pb:1 }}>
          <Grid item>
            <SaveButton name="Cancel" handleDraft={onCropCancel} />
          </Grid>
          <Grid item>
            <SubmitButton name="Crop & Apply" handleSubmit={onCropImage} />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
