import React, { useEffect, useState } from "react";
import { Typography, TextField, Grid, Button } from "@mui/material";
import authActions from "../../redux/auth/actions";
import { cssProperties } from "../../utils/commonCssProperties";
import { FaArrowLeftLong } from "react-icons/fa6";
import verifiedIcon from "../../assets/images/icons/verified.svg";
import ForgetEmail from "./fogetemail";
import {
  handleEmailValidation,
  InputTrimAndLowercaseFunction,
} from "../../utils/validations";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const ForgetPassword = ({ show, setShow }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [reset, setReset] = useState(true);
  const [email, setEmail] = useState(false);
  const [verified, setVerified] = useState(false);
  const [emailid, setEmailid] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch({
      type: authActions.CANDIDATE_FORGETPASSWORD,
      payload: {
        navigate: navigate,
        data: data,
        setReset: setReset,
        setVerified: setVerified,
        setEmailid:setEmailid
      },
    });
    dispatch({
      type: authActions.FORGOTPASSWORD_MOBILEVERIFICATION,
      payload: { validated: false },
    });
  };

  const handleRedirectLink = () => {
    dispatch({
      type: authActions.CANDIDATE_FORGETPASSWORD,
      payload: {
        data: { email: `${emailid}` },
      },
    });
  };


//   useEffect(() => {
//     if (!emailid) {
//       navigate(`/${Cookies.get("mhet_cnd_project")}/login`);
//     }
//   }, [emailid]);
  return (
    <div>
      {reset && !email && !verified ? (
        <Grid
          container
          spacing={2}
          p={3}
          direction="column"
          alignItems="center"
        >
          <Grid item xs={12} sx={{ width: "100%" }}>
            <Typography
              sx={{
                marginBottom: 1,
                fontWeight: "500",
                fontSize: "20px",
                color: `#504E50`,
                lineHeight: "24px",
                textAlign: "left",
                display: "flex",
                gap: "5px",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <FaArrowLeftLong
                onClick={(e) => {
                  e.preventDefault();
                  setShow(true);
                  window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
                style={{ cursor: "pointer", marginRight: "8px" }} // Adds space between arrow and text
              />
              Forgot Password
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <Typography
              sx={{
                marginBottom: 1,
                fontWeight: "500",
                fontSize: "14px",
                color: `#504E50`,
                lineHeight: "16.94px",
              }}
            >
              Registered Email ID
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <Typography
              sx={{
                marginBottom: 1,
                fontWeight: "400",
                fontSize: "12px",
                color: `rgba(80, 78, 80, 0.7)`,
                lineHeight: "14.54px",
              }}
            >
              Please enter your registered email address to receive a password
              reset link
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ width: "100%" }}>
            <TextField
              fullWidth
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  minHeight: "52px",
                  "& fieldset": {
                    border: "2px solid rgba(189, 216, 254, 1)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(100, 150, 255, 1)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(50, 100, 255, 1)",
                  },
                  "&.Mui-error fieldset": {
                    borderColor: "red",
                  },
                },
                "& .MuiFormHelperText-root": {
                  margin: 0,
                  color: "red",
                },
              }}
              error={!!errors.email}
              {...register("email", {
                required: "Emailid is required",
                validate: handleEmailValidation,
              })}
              onInput={InputTrimAndLowercaseFunction}
              helperText={errors.email?.message}
            />
          </Grid>

          <Grid item xs={12} sx={{ width: "100%", textAlign: "right" }}>
            <Typography
              sx={{
                marginBottom: 1,
                fontWeight: "bold",
                fontSize: "14px",
                color: `rgba(15, 79, 150, 1)`,
                lineHeight: "16.94px",
              }}
            >
              <span
                style={{
                  color: `rgba(15, 79, 150, 1)`,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEmail(true);
                  setReset(false);
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                I forgot my Email ID
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleSubmit(onSubmit)}
              sx={{
                background: "rgba(15, 79, 150, 1)",
                color: "#D7E2E9",
                padding: "10px",
                fontWeight: "600",
                fontSize: "14px",
                textTransform: "none",
                borderRadius: "6px",
                minHeight: "40px",
                lineHeight: "16.4px",
                "&:hover": {
                  backgroundColor: `${cssProperties?.color?.buttonHover}`,
                },
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      ) : null}

      {verified && (
        <Grid
          container
          spacing={2}
          p={3}
          direction="column"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sx={{
              width: "100%",
              marginBottom: 2,
              display: "flex",
              gap: "5px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={verifiedIcon}
              alt="verified"
              style={{
                width: "96px",
                height: "91.73px",
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <Typography
              sx={{
                marginBottom: 1,
                fontWeight: "bold",
                fontSize: "20px",
                color: `rgba(0, 0, 0, 0.87)`,
                lineHeight: "24px",
                display: "flex",
                gap: "5px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Reset Link Sent
            </Typography>
          </Grid>
          <Grid item xs={12} lg={3} sx={{ width: "100%" }}>
            <Typography
              sx={{
                marginBottom: 1,
                fontWeight: "400",
                fontSize: "14px",
                color: `rgba(0, 0, 0, 0.87)`,
                lineHeight: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              A link to reset your password has been sent to your email.
              <br />
              Please check your inbox and follow the instructions
            </Typography>
          </Grid>
          <Grid item xs={12} lg={3} sx={{ width: "100%" }}>
            <Typography
              sx={{
                marginBottom: 1,
                fontWeight: "400",
                fontSize: "14px",
                color: `rgba(0, 0, 0, 0.87)`,
                lineHeight: "24px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Did not receive the Email?
            </Typography>
          </Grid>
          <Grid item xs={12} lg={3} sx={{ width: "100%" }}>
            <Typography
              sx={{
                marginBottom: 1,
                fontWeight: "400",
                fontSize: "14px",
                color: `rgba(15, 79, 150, 1)`,
                lineHeight: "17px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              //   onClick={(e) => {
              //     e.preventDefault();
              //     setShow(true);
              //   }}
              onClick={() => handleRedirectLink()}
            >
              Resend link
            </Typography>
          </Grid>
        </Grid>
      )}

      {email && (
        <ForgetEmail
          setShow={setShow}
          setReset={setReset}
          setemailForget={setEmail}
          emailForget={email}
          setVerify={setVerified}
        />
      )}
    </div>
  );
};

export default ForgetPassword;
