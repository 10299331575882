


import React, { useState, useEffect, useCallback, useRef } from "react";
import { IoCheckmarkOutline, IoCloseOutline, IoCloudUploadOutline } from "react-icons/io5";
import imageCompression from "browser-image-compression";
import { useSelector, useDispatch } from "react-redux";
import candidatesActions from "../../redux/candidates/actions";
import masterActions from "../../redux/master/action";
import SubmitButton from "../../common/submitButton";
import SaveButton from "../../common/saveButton";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PiX } from "react-icons/pi";
import pdf from "../../assets/images/uploads/pdf.png";
import jpeg from "../../assets/images/uploads/jpeg.png";
import word from "../../assets/images/uploads/docs.png";
import png from "../../assets/images/uploads/png.jpg";
import ImageCrop from "./imageCrop";
import ImageCropSignature from "./imageCropSignature";
import BackButton from "../../common/backButton";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
  TextField
} from "@mui/material";
import Cookies from "js-cookie";
import { LuCheck, LuPencilLine } from "react-icons/lu";
import { BiSolidUser } from "react-icons/bi";
import signature from "../../../src/assets/images/sidebar/signature 1.png";
import userphoto from "../../../src/assets/images/example.png";
import sign from "../../../src/assets/images/sign.png";
import ReactCrop, {
  convertToPixelCrop
} from "react-image-crop";
import { FiEye, FiEyeOff } from "react-icons/fi";

import { GrAttachment, GrTextWrap } from "react-icons/gr";
import commonActions from "../../redux/common/actions";
import { DecryptObjectFunction } from "../../utils/cryptoFunction";

export default function UploadsForm() {
  const [photofilename, setPhotoImage] = useState("");
  const [photoSize, setPhotoSize] = useState("");
  const [photoSizeCompressed, setPhotoSizeCompressed] = useState("");
  const [signfilename, setSignatureImage] = useState("");
  const [signatureSize, setSignatureSize] = useState("");
  const [signatureSizeCompressed, setSignatureSizeCompressed] = useState("");
  const [size, setSize] = useState("");
  const [photoError, setError] = useState("");
  const [signatureError, setSignatureError] = useState("");
  const [documentTypeError, setDocumentTypeError] = useState("");
  const [documentError, setDocumentError] = useState("");
  const [photoCheckError, setPhotoCheckError] = useState("");
  const [signatureCheckError, setSignatureCheckError] = useState("");
  const [documentid, setDocumentId] = useState("");
  const [photoURL, setPhotoURL] = useState("");
  const [signURL, setSignURL] = useState("");
  const [docURL, setDocURL] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const fileInputRef = useRef(null);
  const fileInputRefSignature = useRef(null);
  const [open, setOpen] = useState(false);
  const [signatureOpen, setSignatureOpen] = useState(false);
  const location = useLocation();
  const [filetype, setFiletype] = useState()

  const fromPreview = location?.state?.fromPreview
  const courseid = location.state?.courseid;
  const coursename = location.state?.coursename;

  const doctyperef = useRef(null);
  const docref = useRef(null)
  const [searchParams] = useSearchParams();
  const encryptedParams = searchParams.get('c_data');
  let decrypted = DecryptObjectFunction(encryptedParams);
  const editStatus = decrypted?.status;
  const courseidValue = decrypted?.courseid;
  const coursenameValue = decrypted?.coursename;

  const handleIconClick = () => {
    setOpen(true);
  };

  const handleNextClick = () => {



    setOpen(false);
    fileInputRef.current.click();
  };

  const handleIconClickSignature = () => {
    setSignatureOpen(true);
  };

  const handleNextSignatureClick = () => {
    setSignatureOpen(false);
    fileInputRefSignature.current.click();
  };

  const handleDialogClose = () => {
    setOpen(false);
    setSignatureOpen(false);
  };


  const [documentName, setDocumentName] = useState("");
  const [documentfilename, setDocument] = useState("");
  const [showModal, setShowModal] = useState({ show: false, data: null });
  const [showModalSignature, setShowModalSignature] = useState({
    show: false,
    data: null,
  });

  const [confirmPhotoImage, setConfirmPhotoImage] = useState(true);
  const [confirmSignatureImage, setConfirmSignatureImage] = useState(true);

  const [cropPhotoImage, setCropPhotoImage] = useState(null);
  const [cropSignatureImage, setCropSignatureImage] = useState(null);

  const { candidateTokenDetails } = useSelector((state) => state.authReducer);
  const candidateid = candidateTokenDetails?.candidateid;

  const { uploadDetails } = useSelector((state) => state.candidatesReducer);
  const { documentType } = useSelector((state) => state.masterReducer);
  const doc = uploadDetails ? uploadDetails?.find((item, index) => item?.documenttype == 3)?.filename : 'document name'
  
  const match = doc?.match(/\.(jpeg|jpg|pdf)$/);
  const extension = match ? match[1] : null;

  const fileType2 = extension


  const candidateuploaddraftid = uploadDetails[0]?.candidateuploaddraftid
    ? uploadDetails[0]?.candidateuploaddraftid
    : uploadDetails[1]?.candidateuploaddraftid
      ? uploadDetails[1]?.candidateuploaddraftid
      : uploadDetails[2]?.candidateuploaddraftid;
  const candidateuploadid = uploadDetails[0]?.candidateuploadid
    ? uploadDetails[0]?.candidateuploadid
    : uploadDetails[1]?.candidateuploadid
      ? uploadDetails[1]?.candidateuploadid
      : uploadDetails[2]?.candidateuploadid;

  useEffect(() => {
    if (candidateid) {
      setTimeout(() => {
        dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
      }, 1000)
    }
    dispatch({ type: masterActions.GET_DOCUMENT_TYPE });
  }, [candidateid]);

  useEffect(() => {
    if (uploadDetails?.length > 0) {
      setDocumentId(uploadDetails[0]?.documentid || "");
      const photoUrl = uploadDetails?.find((item, index) => item?.documenttype == 1 && item?.fileurl)?.fileurl
      const signUrl = uploadDetails?.find((item, index) => item?.documenttype == 2 && item?.fileurl)?.fileurl
      const docUrl = uploadDetails?.find((item, index) => item?.documenttype == 3 && item?.fileurl)?.fileurl

      setPhotoURL(photoUrl);
      setSignURL(signUrl);
      setDocURL(docUrl);

    }
  }, [uploadDetails]);

  
  const handleDocumentUpload = (event) => {
    const file = event.target.files[0];
   
   const docSize = file?.size

   if(docSize <= 2097152){

   
    const fileName = file.name;
    
    const fileType = fileName.split('.').pop().toLowerCase();
    // if (fileType === 'docx') {
    //   setFiletype('docx')
    // } 
    if (fileType === 'pdf') {
      setFiletype('pdf')
    }
    else if (fileType === 'jpeg') {
      setFiletype('jpeg')
    }
    else if (fileType === 'jpg') {
      setFiletype('jpeg')
    }
    // else if (fileType === 'png') {
    //   setFiletype('png')
    // }
    else {
      setDocumentError("Document Type Not Supported. Please upload JPG/PDF format only")
      return
    }

    setDocumentName(fileName);
    setDocument(file);
    setDocumentError("");
    
    event.target.value = null;
   }else{

    setDocumentName("");
    setDocument("");
    setDocumentError("You cannot upload file larger than 2MB")

    event.target.value = null;

   }
  };

  const handleDocumentDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setDocumentName(file.name);
      setDocument(file);
      setDocumentError("");
      event.target.value = null;
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleCloseDocument = () => {
    setDocumentName("");
  };

  const handleDocumentId = (event) => {
    setDocumentId(event.target.value);
    setDocumentTypeError("");
  };

  const handleClose = () => {
    setShowModal({ show: false, data: null });
  };

  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const { draftButtonLoader } = useSelector((state) => state.commonReducer);

  const {
    showImage,
    showCompressedImage,
    showSignature,
    showCompressedSignature,
  } = useSelector((state) => state.candidatesReducer);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const fileSizeInKB = file.size / 1024;
    setPhotoSize(fileSizeInKB.toFixed(2));
    setSize(fileSizeInKB);

    // Assuming that the modal should open regardless of file size
    setShowModal({ show: true, data: null });
    if (fileSizeInKB > 300) {
      try {
        const options = {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        const unCompressedFile = URL.createObjectURL(file);
        const convertedFileCompressed = URL.createObjectURL(compressedFile);

        setConfirmPhotoImage("");
        setCropPhotoImage(convertedFileCompressed);

        setConfirmPhotoImage(false);
        setSize("");
        setPhotoImage("");
        setPhotoImage(compressedFile);
        setPhotoSizeCompressed("");
        setPhotoSizeCompressed(compressedFile);
      } catch (err) {
        console.log(err);
      }

      // Reset the file input field
      event.target.value = null;
    } else {


      try {
        const options = {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        const unCompressedFile = URL.createObjectURL(file);
        const convertedFileCompressed = URL.createObjectURL(compressedFile);

        setConfirmPhotoImage("");
        setCropPhotoImage(unCompressedFile);

        setConfirmPhotoImage(false);
        setSize("");
        setPhotoImage("");
        setPhotoImage(file);
        setPhotoSizeCompressed("");
        setPhotoSizeCompressed(file);
      } catch (err) {
        console.log(err);
      }

      // Reset the file input field
      event.target.value = null;


    }

  };

  const handleSignatureUpload = async (event) => {
    const file = event.target.files[0];
    const fileSizeInKB = file.size / 1024;
    setSize(fileSizeInKB);
    setSignatureSize(fileSizeInKB.toFixed(2));
    // if (fileSizeInKB > 300) {
    setShowModalSignature({ show: true, data: null });
    try {
      const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);
      const unCompressedFile = URL.createObjectURL(file);
      const convertedFileCompressed = URL.createObjectURL(compressedFile);
  
      setCropSignatureImage(convertedFileCompressed);
      setSignatureSizeCompressed(compressedFile);
      // setConfirmSignatureImage(false);
      setSignatureImage(compressedFile);
    } catch (err) {
      console.log(err);
    }

    event.target.value = null;

  };

  function base64ToFile(base64String, fileName) {
    if (!base64String) {
      return null;
    }
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new File([ab], fileName, { type: mimeString });
  }

  // const handleSubmit = () => {


  //   const operation = candidateuploadid ? 1 : 0;
  //   // console.log(uploadDetails,'<====upload details')

  //   const base64DocumentImage = uploadDetails[0]?.document;
  //   const base64DocumentSignature = uploadDetails[1]?.document;
  //   const base64DocumentDocument = uploadDetails[2]?.document;
  //   const currentSignature = base64ToFile(
  //     base64DocumentSignature,
  //     `${candidateid}_signature.jpeg`
  //   );
  //   const currentImage = base64ToFile(
  //     base64DocumentImage,
  //     `${candidateid}_photo.jpeg`
  //   );
  //   const currentDocument = base64ToFile(
  //     base64DocumentDocument,
  //     `${uploadDetails[2]?.filename}`
  //   );
  

  //   if (
  //     (photofilename || uploadDetails[0]?.document || cropPhotoImage || photoURL) &&
  //     (signfilename || uploadDetails[1]?.document || cropSignatureImage || signURL) &&
  //     (documentfilename || uploadDetails[2]?.document || docURL) &&
  //     (documentid || uploadDetails[0]?.documentid)
  //     // &&
  //     // (cropPhotoImage ? confirmPhotoImage : uploadDetails[0]?.filename) &&
  //     // (cropSignatureImage ? confirmSignatureImage : uploadDetails[1]?.filename)
  //   ) {
  //     const data = {
  //       photofilename: photofilename ? photofilename : null,
  //       signfilename: signfilename ? signfilename : null,
  //       documentfilename: documentfilename ? documentfilename : null,
  //       photoUrl: photoURL ? photoURL : null,
  //       signUrl: signURL ? signURL : null,
  //       docUrl: docURL ? docURL : null,
  //       courseid: courseid,
  //       coursename: coursename,
  //       fromPreview: fromPreview,
  //       filetype: filetype
  //     };

  //     console.log("submitdata", {
  //       ...data,
  //       operation: operation,
  //       candidateid: candidateid,
  //       documentid: documentid ? documentid : uploadDetails[0].documentid,
  //       status: 2
  //     });
  //     dispatch({
  //       type: candidatesActions.PROFILE_UPLOADS_DETAILS_CREATION,
  //       payload: {
  //         data: {
  //           ...data,
  //           operation: operation,
  //           candidateid: candidateid,
  //           documentid: documentid ? documentid : uploadDetails[0].documentid,
  //           status: 2,
  //           photoname: uploadDetails ? uploadDetails[0]?.filename : null,
  //           signname: uploadDetails ? uploadDetails[1]?.filename : null,
  //           docname: uploadDetails ? uploadDetails[2]?.filename : null
  //         },
  //         navigate: navigate,
  //         editStatus: editStatus,
  //         courseid: courseidValue,
  //         coursename: coursenameValue
  //       },
  //     });

  //     dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });

  //   } else {
  //     if (!cropPhotoImage && !uploadDetails[0]?.filename) {
  //       setError("Image is required");
  //       fileInputRef?.current?.focus()
  //     }
  //     if (!cropSignatureImage && !uploadDetails[1]?.filename) {
  //       setSignatureError("Signature is required");
  //       fileInputRefSignature?.current?.focus()

  //     }
  //     if (documentid && !documentfilename && !uploadDetails[2]?.filename) {
  //       setDocumentError("Document is required");
  //       doctyperef?.current?.focus()

  //     }
  //     if (!documentid) {
  //       setDocumentTypeError("Select one document type");
  //       docref?.current?.focus()

  //     }
      
  //   }

  // };

  const handleSubmit = () => {
    const operation = candidateuploadid ? 1 : 0;
    const base64DocumentImage = uploadDetails[0]?.document;
    const base64DocumentSignature = uploadDetails[1]?.document;
    const base64DocumentDocument = uploadDetails[2]?.document;
  
    const currentSignature = base64ToFile(base64DocumentSignature, `${candidateid}_signature.jpeg`);
    const currentImage = base64ToFile(base64DocumentImage, `${candidateid}_photo.jpeg`);
    const currentDocument = base64ToFile(base64DocumentDocument, `${uploadDetails[2]?.filename}`);
  
    // Clear any previous errors
    setError(null);
    setSignatureError(null);
    setDocumentError(null);
    setDocumentTypeError(null);
  
    let hasError = false; // Track if there are any errors
  
    // Validate photo input
    if (!(photofilename || uploadDetails[0]?.document || cropPhotoImage || photoURL)) {
      setError("Image is required");
      if (fileInputRef?.current) fileInputRef.current.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll to photo input
      hasError = true;
    }
  
    // Validate signature input
    if (!(signfilename || uploadDetails[1]?.document || cropSignatureImage || signURL)) {
      setSignatureError("Signature is required");
      if (fileInputRefSignature?.current) fileInputRefSignature.current.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll to signature input
      hasError = true;
    }
  
    // Validate document input
    if (documentid && !(documentfilename || uploadDetails[2]?.document || docURL)) {
      setDocumentError("Document is required");
      if (doctyperef?.current) doctyperef.current.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll to document input
      hasError = true;
    }
  
    // Validate document type
    if (!documentid) {
      setDocumentTypeError("Select one document type");
      if (docref?.current) docref.current.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll to document type input
      hasError = true;
    }
  
    // If there's any error, stop further execution
    if (hasError) {
      return; // Stop form submission and prevent dispatching actions
    }
  
    // If no errors, prepare the data for submission
    const data = {
      photofilename: photofilename ? photofilename : null,
      signfilename: signfilename ? signfilename : null,
      documentfilename: documentfilename ? documentfilename : null,
      photoUrl: photoURL ? photoURL : null,
      signUrl: signURL ? signURL : null,
      docUrl: docURL ? docURL : null,
      courseid: courseid,
      coursename: coursename,
      fromPreview: fromPreview,
      filetype: filetype
    };
  
    // Dispatch action
    dispatch({
      type: candidatesActions.PROFILE_UPLOADS_DETAILS_CREATION,
      payload: {
        data: {
          ...data,
          operation: operation,
          candidateid: candidateid,
          documentid: documentid ? documentid : uploadDetails[0].documentid,
          status: 2,
          photoname: uploadDetails ? uploadDetails[0]?.filename : null,
          signname: uploadDetails ? uploadDetails[1]?.filename : null,
          docname: uploadDetails ? uploadDetails[2]?.filename : null
        },
        navigate: navigate,
        editStatus: editStatus,
        courseid: courseidValue,
        coursename: coursenameValue
      },
    });
  
    // Call another dispatch to get the uploaded personal details
    dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
  };
  
  
  const handleDraft = () => {


    const operation = candidateuploaddraftid ? 1 : 0;
    const base64DocumentImage = uploadDetails[0]?.document ? uploadDetails[0]?.document : null;
    const base64DocumentSignature = uploadDetails[1]?.document ? uploadDetails[1]?.document : null;
    const base64DocumentDocument = uploadDetails[2]?.document ? uploadDetails[2]?.document : null;

    const data = {
      photofilename: photofilename ? photofilename : null,
      signfilename: signfilename ? signfilename : null,
      documentfilename: documentfilename ? documentfilename : null,
      photoUrl: photoURL ? photoURL : null,
      signUrl: signURL ? signURL : null,
      docUrl: docURL ? docURL : null,
      filetype: filetype
    };
    dispatch({
      type: candidatesActions.PROFILE_UPLOADS_DETAILS_DRAFT_CREATION,
      payload: {
        data: {
          ...data,
          operation: parseInt(operation),
          candidateid: parseInt(candidateid),
          documentid: documentid ? documentid : uploadDetails[0]?.documentid ? uploadDetails[0]?.documentid : null,
          status: 1,
        },
      },
    });

  };


  const onCropDone = (dataURL, crop) => {


    if (!dataURL || !crop) return;

    // Convert base64 to binary data for creating a file
    const byteString = atob(dataURL.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    // Create a Blob and File from the binary data
    const blob = new Blob([uint8Array], { type: "image/jpeg" });
    const file = new File([blob], "croppedImage.jpg", { type: "image/jpeg" });

    // Set up the file for further use or uploading
    const dataTransfer = new DataTransfer();

    dataTransfer.items.add(file);

    // Create a hidden file input to hold the cropped file
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.files = dataTransfer.files;
    setPhotoImage(fileInput.files[0]);

    // Dispatch the cropped image URL and any other necessary actions
    dispatch({
      type: candidatesActions.SHOW_IMAGE,
      payload: dataURL,
    });
    dispatch({
      type: candidatesActions.SHOW_COMPRESSED_IMAGE,
      payload: dataURL,
    });

    // Close the modal and reset crop state
    setShowModal({ show: false, data: null });
  };


  const onCropDoneSignature = (dataURL, crop) => {
   
    if (!dataURL || !crop) return;

    // Convert base64 to binary data for creating a file
    const byteString = atob(dataURL.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    // Create a Blob and File from the binary data
    const blob = new Blob([uint8Array], { type: "image/jpeg" });
    const file = new File([blob], "croppedImage.jpg", { type: "image/jpeg" });

    // Set up the file for further use or uploading
    const dataTransfer = new DataTransfer();

    dataTransfer.items.add(file);

    // Create a hidden file input to hold the cropped file
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.files = dataTransfer.files;
    setSignatureImage(fileInput.files[0]);

    // Dispatch the cropped image URL and any other necessary actions
    dispatch({
      type: candidatesActions.SHOW_SIGNATURE,
      payload: dataURL,
    });
    dispatch({
      type: candidatesActions.SHOW_COMPRESSED_SIGNATURE,
      payload: dataURL,
    });;
    setShowModalSignature({ show: false, data: null });

  };

  const onCropCancelSignature = () => {
    setShowModalSignature({ show: false, data: null });
    dispatch({
      type: candidatesActions.SHOW_SIGNATURE,
      payload: null,
    });
    dispatch({
      type: candidatesActions.SHOW_COMPRESSED_SIGNATURE,
      payload: null,
    });

  };

  const onCropCancel = () => {
    setShowModal({ show: false, data: null });
    dispatch({
      type: candidatesActions.SHOW_IMAGE,
      payload: null
    })
    dispatch({
      type: candidatesActions.SHOW_COMPRESSED_IMAGE,
      payload: null
    })
  };

  const handleBack = () => {
    Cookies.remove('draftclicked')
    navigate(
      `/${Cookies.get("mhet_cnd_project")}/registration?page=otherdetails`
    );
    dispatch({
      type: candidatesActions.SHOW_IMAGE,
      payload: null,
    });
    dispatch({
      type: candidatesActions.SHOW_SIGNATURE,
      payload: null,
    });

    setPhotoImage("");
    setPhotoSize("");
    setSignatureImage("");
    setSignatureSize("");
    // window.location.reload();
  };


  useEffect(() => {
    if (candidateid) {
      dispatch({ type: commonActions.SHOW_SIDEBAR, payload: uploadDetails?.length == 0 ? false : uploadDetails[0]?.candidateuploaddraftid ? false : true });
    }
  }, [uploadDetails]);

  return (
    <Grid
      style={{
        padding: "20px 25px 0px 25px",
        minHeight: "60vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        border: " 1px solid #0F4F96",
      }}
    >
      <Grid container>
        <Grid item lg={7} >
          {
            uploadDetails?.find((item, index) => item?.documenttype == 1)?.fileurl &&
              !showImage ? (
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <InputLabel style={{ fontWeight: "bold" }}      ref={fileInputRef}>
                  Upload Image
                  <span className="error" style={{ color: "#666666" }}>
                    *
                  </span>
                </InputLabel>
                {/* {uploadDetails[0]?.filename} */}
                <Box
              
                  sx={{
                    display: "flex",
                    gap: "2%",
                    marginTop: ".4rem",
                    flexDirection: isMobile ? "column" : "",
                  }}
                >
                  {/* {showImage && ( */}
                  <Box style={{ display: "flex", flexDirection: "column" }}                     
                  >
                    <Box
                      style={{ position: "relative", display: "inline-block", width: "150px" }}
                    >
                      <img
                        src={uploadDetails?.find((item, index) => item?.documenttype == 1)?.fileurl}
                        alt="Original Image"
                        style={{
                          height: "140px", width: "auto", borderRadius: "4px"
                        }}
                      />

                      <IconButton
                        onClick={handleIconClick} // Trigger the file input when the icon is clicked
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-11px",
                          backgroundColor: "#0F4F96",
                          borderRadius: "50%",
                          padding: "4px",
                          color: "#ffffff",
                          border: "2px solid #FFFFFF",
                        }}
                      >
                        <LuPencilLine style={{ width: "16px", height: "16px" }} />
                      </IconButton>

                      <input
                      ref={fileInputRef}
                        type="file"
                        name="photofilename"
                        id="photofilename"
                        accept=".jpg, .jpeg, .png"
                        style={{ display: "none" }} // Hide the input
                        onChange={handleImageUpload} // Handle file upload
                      />
                    </Box>
                   
                  </Box>
             
                </Box>
                {(photoError && !cropPhotoImage) && <small className="error">{photoError}</small>}
                <br />
                {photoCheckError && (
                  <small className="error">{photoCheckError}</small>
                )}
              </Box>
            ) : showImage ? (
              <>
                <InputLabel style={{ fontWeight: "bold" }}>
                  Upload Image
                  <span className="error" style={{ color: "#666666" }}>
                    *
                  </span>
                </InputLabel>
                <Box style={{ display: "flex", gap: "10%" }}>
                  {showImage && (
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        style={{ position: "relative", display: "inline-block" }}
                      >
                        <img
                          src={showImage}
                          alt="Original Image"
                          style={{ height: "180px", width: "auto", borderRadius: "4px" }}
                        />
                        <IconButton
                          onClick={handleIconClick} // Trigger the file input when the icon is clicked
                          style={{
                            position: "absolute",
                            top: "-5px",
                            right: "-18px",
                            backgroundColor: "#0F4F96",
                            borderRadius: "50%",
                            padding: "4px",
                            color: "#ffffff",
                            border: "2px solid #FFFFFF",
                          }}
                        >
                          <LuPencilLine
                            style={{ width: "16px", height: "16px" }}
                          />
                        </IconButton>
                      </Box>

                      <input
                        type="file"
                        ref={fileInputRef} // Reference to file input
                        name="photofilename"
                        id="photofilename"
                        accept=".jpg, .jpeg, .png"
                        style={{ display: "none" }} // Hide the input
                        onChange={handleImageUpload} // Handle file upload
                      />

                      <Typography
                        variant="caption"
                        style={{ paddingTop: "10px" }}
                      >
                        Uploaded image
                      </Typography>

                      {(cropPhotoImage !== "" && photoSize > 300) && (
                        <small className="photo-size">{photoSize} KB</small>
                      )}
                    </Box>
                  )}
                  {(showCompressedImage && photoSize > 300) && (
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      <img
                        src={showCompressedImage}
                        alt="Compressed Image"
                        style={{ height: "180px", width: "auto" }}
                      />
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          style={{ paddingTop: "10px" }}
                        >
                          Compressed image
                        </Typography>
                       
                      </Box>
                      {cropPhotoImage !== "" && (
                        <small className="photo-size">
                          {(photoSizeCompressed.size / 1024).toFixed(2)} KB
                        </small>
                      )}
                    </Box>
                  )}


                </Box>
                {photoCheckError && (
                  <small className="error">{photoCheckError}</small>
                )}
              </>
            ) : (
              <>
                <InputLabel style={{ fontWeight: "bold" }}>
                  Upload Image
                  <span className="error" style={{ color: "#666666" }}>
                    *
                  </span>
                </InputLabel>
                <Box
                  style={{
                    backgroundColor: "#CFDCEA",
                    width: "120px",
                    height: "140px",
                    borderRadius: "8px",
                    padding: "3px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "8px",
                  }}
                >
                  <IconButton
                    onClick={handleIconClick} // Handle click to trigger file input
                    style={{
                      position: "absolute",
                      top: "-3px",
                      right: "-5px",
                      backgroundColor: "#0F4F96",
                      borderRadius: "50%",
                      padding: "1px",
                      border: "2.08px solid #FFFFFF",
                    }}
                  >
                    <LuPencilLine
                      style={{ color: "#ffffff", width: "19px", height: "19px" }}
                    />
                  </IconButton>

                  <input
                    type="file"
                    ref={fileInputRef} // Reference for the file input
                    name="photofilename"
                    id="photofilename"
                    accept=".jpg, .jpeg, .png"
                    style={{
                      display: "none", // Hide the file input element
                    }}
                    onChange={handleImageUpload}
                  />

                  <BiSolidUser
                    style={{ width: "78px", height: "78px", color: "#0F4F96" }}
                  />
                </Box>
                <Typography
                  variant="body1"
                  mt={1}
                  sx={{
                    color: "#0000008A",
                    lineHeight: "22px",
               
                  }}
                >
                  (JPEG,JPG or png)
                  <br />
                  (max size - 300 kB)
                </Typography>

                {photoError && <small className="error">{photoError}</small>}
                <br />
                {photoCheckError && (
                  <small className="error">{photoCheckError}</small>
                )}
              </>
            )}

          {
            uploadDetails?.find((item, index) => item?.documenttype == 2)?.fileurl &&
              !showSignature ? (
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <InputLabel style={{ fontWeight: "bold" }}>
                  Upload Signature
                  <span className="error" style={{ color: "#666666" }}>
                    *
                  </span>
                </InputLabel>
                {/* {uploadDetails[1]?.filename} */}
                <Box
                  style={{
                    display: "flex",
                    gap: "2%",
                    marginTop: ".4rem",
                    flexDirection: isMobile ? "column" : "",
                  }}
                >
                  {/* {showSignature && ( */}
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Box
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={uploadDetails?.find((item, index) => item?.documenttype == 2)?.fileurl} // Second element for signature
                        alt="Original Signature"
                        style={{ height: "140px", width: "195px", borderRadius: "4px" }}
                      />

                      <IconButton
                        onClick={handleIconClickSignature} // Trigger the file input when the icon is clicked
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-18px",
                          backgroundColor: "#0F4F96",
                          borderRadius: "50%",
                          padding: "4px",
                          color: "#ffffff",
                          border: "2px solid #FFFFFF",
                        }}
                      >
                        <LuPencilLine style={{ width: "16px", height: "16px" }} />
                      </IconButton>

                      <input
                        type="file"
                        ref={fileInputRefSignature} 
                        name="signaturefilename"
                        id="signaturefilename"
                        accept=".jpg, .jpeg, .png"
                        style={{ display: "none" }} 
                        onChange={handleSignatureUpload} 
                      />
                    </Box>
                  </Box>

                </Box>
                {(signatureError && !cropSignatureImage) && (
                  <small className="error">{signatureError}</small>
                )}
                <br />
                {signatureCheckError && (
                  <small className="error">{signatureCheckError}</small>
                )}
              </Box>
            ) : showSignature ? (
              <>
                <InputLabel style={{ fontWeight: "bold" }}>
                  Upload Signature
                  <span className="error" style={{ color: "#666666" }}>
                    *
                  </span>
                </InputLabel>
                <Box style={{ display: "flex", gap: "10%" }}>
                  {showSignature && (
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        style={{ position: "relative", display: "inline-block" }}
                      >
                        <img
                          src={showSignature}
                          alt="Original Signature"
                          style={{ height: "140px", width: "195px", borderRadius: "4px" }}
                        />
                        <IconButton
                          onClick={handleIconClickSignature}
                          style={{
                            position: "absolute",
                            top: "-5px",
                            right: "0",
                            backgroundColor: "#0F4F96",
                            borderRadius: "50%",
                            padding: "4px",
                            color: "#ffffff",
                            border: "2px solid #FFFFFF",
                          }}
                        >
                          <LuPencilLine
                            style={{ width: "16px", height: "16px" }}
                          />
                        </IconButton>
                      </Box>
                      <input
                        type="file"
                        ref={fileInputRefSignature} // Reference to file input
                        name="signaturefilename"
                        id="signaturefilename"
                        accept=".jpg, .jpeg, .png"
                        style={{ display: "none" }} // Hide the input
                        onChange={handleSignatureUpload} // Handle file upload
                      />
                      <Typography
                        variant="caption"
                        style={{ paddingTop: "10px" }}
                      >
                        Uploaded signature
                      </Typography>

                      {(cropSignatureImage !== "" && signatureSize > 300) && (
                        <small className="photo-size">{signatureSize} KB</small>
                      )}
                    </Box>
                  )}
                  {(showCompressedSignature && signatureSize > 300) && (
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      <img
                        src={showCompressedSignature}
                        alt="Compressed Signature"
                        // style={{ height: "140px", width: "auto", borderRadius: "4px" }}
                        style={{ height: "140px", width: "195px", borderRadius: "4px" }}

                      />
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          style={{ paddingTop: "10px" }}
                        >
                          Compressed signature
                        </Typography>
                      
                      </Box>
                      {cropSignatureImage !== "" && (
                        <small className="photo-size">
                          {(signatureSizeCompressed.size / 1024).toFixed(2)} KB
                        </small>
                      )}
                    </Box>
                  )}

                </Box>
                {signatureCheckError && (
                  <small className="error">{signatureCheckError}</small>
                )}
              </>
            ) : (
              <>
                <br />
                <InputLabel
                  htmlFor="signfilename"
                  className="mt-3"
                  style={{ fontWeight: "bold" }}
                >
                  Upload Signature
                  <span className="error" style={{ color: "#666666" }}>
                    *
                  </span>
                </InputLabel>
                <Box
            
                  style={{
                    backgroundColor: "#CFDCEA",
                    width: "240px",
                    height: "119px",
                    borderRadius: "8px",
                    padding: "3px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "8px",
                  }}
                >
                  <IconButton
                    onClick={handleIconClickSignature}
                    style={{
                      position: "absolute",
                      top: "-3px",
                      right: "-5px",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "50%",
                      padding: "1px",
                      backgroundColor: "#0F4F96",
                      border: " 2.08px solid #FFFFFF",
                    }}
                  >
                    <LuPencilLine
                      style={{ color: "#ffffff", width: "19px", height: "19px" }}
                    />
                  </IconButton>
                  <img
                    src={signature}
                    style={{
                      // width: "179px",
                      // height: "62px",

                      height: "140px", width: "195px",
                    }}
                  />
                  <input
                    type="file"
                    ref={fileInputRefSignature}
                    name="signfilename"
                    id="signfilename"
                    accept=".jpg, .jpeg, .png"
                    onChange={handleSignatureUpload}
                    style={{
                      width: "0.1px",
                      height: "0.1px",
                      opacity: 0,
                      overflow: "hidden",
                      position: "absolute",
                      zIndex: -1,
                    }}
                  />

                </Box>
             
                <Typography
                  variant="body1"
                  mt={1}
                  sx={{
                    color: "#0000008A",
                    lineHeight: "22px",
                   
                  }}
                >
                  (JPEG,JPG or png)
                  <br />
                  (max size - 300 kB)
                </Typography>

                {signatureError && (
                  <small className="error">{signatureError}</small>
                )}
                <br />
                {signatureCheckError && (
                  <small className="error">{signatureCheckError}</small>
                )}
              </>
            )}
          <br />
          <InputLabel htmlFor="document" style={{ fontWeight: "bold" }}    ref={doctyperef}>
            Photo Identity
            <span className="error" style={{ color: "#666666" }}>
              *
            </span>
          </InputLabel>
          <Grid
            container
            mt={1}
            spacing={1}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid
                ref={doctyperef}
              container
              mt={1}
              spacing={1}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid item md={10} sx={{ marginLeft: "7px", marginRight: "8px" }}  ref={doctyperef}>
                <FormControl fullWidth  ref={doctyperef}>
                  <InputLabel id="select-doc-label">
                    Select Document Type*
                  </InputLabel>
                  <Select
                    label="Select Document Type*"
                    value={documentid}
                    id="documentid"
                    onChange={handleDocumentId}
                    sx={{
                      borderRadius: "8px",
                    }}
                    ref={doctyperef}
                  >
                    <MenuItem value="" disabled>Select One</MenuItem>
                    {documentType?.map((val, i) => (
                      <MenuItem
                        sx={{ textWrap: "wrap" }}
                        value={val?.documenttypeid}
                        key={i}
                      >
                        <span style={{ textWrap: "wrap" }}>
                          {val?.documenttype}
                        </span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>


              {documentName && (
                <Grid item md={10}
                ref={docref}>
                  <Box
                    style={{
                      height: "fit-content",
                      border: "1px solid #D5D5D5",
                      display: "flex",
                      alignItems: "center",
                      padding: "8px",
                      marginTop: "1rem",
                      margin: "6px 0px 0px 8px",
                      borderRadius: "8px",
                      position: "relative",
                      overflow: "hidden",

                    }}

                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        flexGrow: 1,
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={filetype === "docx" ? word :
                          filetype === "pdf" ? pdf :
                            filetype === "jpeg" ? jpeg :
                              filetype === "png" ? png :
                                null
                        }
                        style={{ height: "auto", width: "30px", marginLeft: "5px" }}
                        alt="pdf"
                      />
                      <span
                        style={{
                          wordBreak: "break-word",
                          whiteSpace: "normal",
                          paddingRight: "24px",
                        }}
                      >
                        {documentName}
                      </span>
                    </Box>
                    <Box
                      style={{
                        position: "relative",
                        right: "10px",
                        marginLeft: "auto",
                        cursor: "pointer",
                      }}
                      onClick={handleCloseDocument}
                    >
                      <PiX />
                    </Box>
                  </Box>
                </Grid>
              )}



              {(documentTypeError && !documentid) && (
                <small
                  className="error"
                  style={{ padding: "0px 8px", marginTop: ".2rem" }}
                >
                  &nbsp; &nbsp; {documentTypeError}
                </small>
              )}
            </Grid>

          </Grid>

          {
            uploadDetails?.find((item, index) => item?.documenttype == 3) &&
            !documentName && (
              <>
                <Grid item md={10}>
                  <Box
                    component="a"
                    style={{
                      height: "60px",
                      border: "1px solid #D5D5D5",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between", // Align the content inside the box properly
                      gap: "1rem",
                      marginTop: "1rem",
                      overflow: "hidden",
                      padding: "6px 10px",
                      borderRadius: "8px",
                      cursor: "pointer",
                      textDecoration: "none", // Remove underline for anchor
                    }}
                    target="_blank"
                  >
                    {/* Left Content - Image and Text */}
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px", // Add spacing between image and text
                      }}
                    >
                      <img
                        src={
                          fileType2 === "word" || fileType2 === "docx"
                            ? word
                            : fileType2 === "pdf"
                              ? pdf
                              : fileType2 === "jpeg" || fileType2 === "jpg"
                                ? jpeg
                                : fileType2 === "png"
                                  ? png
                                  : null
                        }
                        style={{ height: "40px", width: "40px", marginLeft: "5px" }}
                        alt="file-type-icon"
                      />
                      <span
                        style={{
                          wordBreak: "break-word",
                          whiteSpace: "normal",
                          fontSize: "14px",
                          color: "#333",
                        }}
                      >
                        {uploadDetails
                          ? uploadDetails?.find((item) => item?.documenttype === 3)?.filename
                          : "Document name"}
                      </span>
                    </Box>

                    {/* Right Content - View Button */}
                    <Box
                      style={{
                        backgroundColor: "#f0f0f0",
                        padding: "6px 12px",
                        borderRadius: "4px",
                        cursor: "pointer",
                        fontSize: "14px",
                        color: "#007BFF",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        const targetUrl = uploadDetails
                          ? uploadDetails.find((item) => item?.documenttype === 3)?.fileurl
                          : "";

                        if (targetUrl) {
                          window.open(targetUrl, "_blank", "noopener,noreferrer");
                        } else {
                          console.error("No URL found to redirect to.");
                        }
                      }}
                    >
                      <FiEye/>
                    </Box>
                  </Box>
                </Grid>

             
              </>
            )}
          <Grid item md={10}>

            <Box
              style={{
                border: "1px solid #0000003D",
                borderRadius: "8px",
                // maxWidth: "280px",
                minHeight: "52px",
                display: "flex",
                alignItems: "center",
                marginTop: "16px",
                padding: "0px"
              }}
            >
              <input
                type="file"
                name="documentfilename"
                id="documentfilename"
                // accept=".jpeg,.jpg,.png,.pdf,.doc,.docx"
                accept=".jpeg,.jpg,.pdf"
                style={{
                  width: "0.1px",
                  height: "0.1px",
                  opacity: 0,
                  overflow: "hidden",
                  position: "absolute",
                  zIndex: -1,
                }}
                onChange={handleDocumentUpload}
              />
              <label
                htmlFor="documentfilename"
                style={{
                  cursor: "pointer",
                  display: "flex",
                  padding: "18px",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  color: "0000003D",
                }}
              >
                {(uploadDetails && uploadDetails[2]?.filename) ? "Upload New Document" : "Upload Document"}
                <GrAttachment size={22} />
              </label>
            </Box>
          </Grid>
          <small
            className="error"
            style={{ color: "#666666", textWrap: "wrap" }}
          >
            {" "}
            {/* Only JPEG, PNG, PDF, and DOC/DOCX files are allowed */}
            {`Only JPG and PDF files are allowed  (File size max of 2 MB)`}
          </small>
          <br />
          {(documentError && !documentfilename) && <small className="error">{documentError}</small>}
        </Grid>
        <Grid item lg={5}>
          <Box
            sx={{
              minHeight: "5rem",
              maxWidth: "541px",
              padding: "1rem",
              backgroundColor: "#F3F6FA",
              marginTop: "10px",
              marginBottom: "10px",
              border: "1px solid #0F4F96",
              borderRadius: "20px",
            }}
          >
            <Typography variant="h6" sx={{ color: "#504E50", fontWeight: 500 }}>
              Instructions for Photo:-
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: "1rem" }}>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  1. Your photo must be taken within the last six months to
                  reflect your current appearance.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  2. Your head should be centered and facing forward, covering
                  about 50-70% of the photo.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  3. Both of your ears should be visible, and your eyes should
                  be open and clearly visible.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  4. Use a plain white or off-white background, free from
                  shadows or other decorations in your photo.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  5. Ensure your photo is well-lit with no shadows on your face
                  or in the background.
                </Typography>
              </Grid>
            </Grid>

            <Typography
              variant="h6"
              sx={{ color: "#504E50", fontWeight: 500, marginTop: "1rem" }}
            >
              Instructions for Signature:-
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: "1rem" }}>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  1. Make sure your signature is clear and legible without any
                  smudges or overlaps.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  2. Use a plain white background for your signature to ensure
                  clear visibility.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  3. Use a black or dark blue ink pen to sign. Avoid using other
                  colors as they may not scan well.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  4. The signature should be done by you personally, not by any
                  other person or digitally generated.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        pb={2}
        pt={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth="xs"
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{ textAlign: "center", position: "relative" }}
          >
            SAMPLE IMAGE
            <IconButton
              aria-label="close"
              onClick={handleDialogClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "red",
              }}
            >
              <IoCloseOutline />
            </IconButton>
          </DialogTitle>

          <DialogContent
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <img
                src={userphoto}
                alt="Sample"
                style={{
                  width: "135px",
                  height: "148px",
                  borderRadius: "8px",
                }}
              />
            </Box>

            {/* Instructions Section */}
            <Typography variant="h6" sx={{ color: "#504E50", fontWeight: 500 }}>
              Instructions for Photo:-
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: "3px" }}>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  1. Your photo must be taken within the last six months to
                  reflect your current appearance.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  2. Your head should be centered and facing forward, covering
                  about 50-70% of the photo.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  3. Both of your ears should be visible, and your eyes should
                  be open and clearly visible.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  4. Use a plain white or off-white background, free from
                  shadows or other decorations in your photo.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  5. Ensure your photo is well-lit with no shadows on your face
                  or in the background.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={handleNextClick}
              name={buttonLoader ? "Next" : "Next"}
            />
          </DialogActions>
        </Dialog>

        <Dialog
          open={signatureOpen}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth="xs"
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{ textAlign: "center" }}
          >
            SAMPLE SIGNATURE{" "}
            <IconButton
              aria-label="close"
              onClick={handleDialogClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "red",
              }}
            >
              <IoCloseOutline />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <img
                src={sign}
                alt="Sample"
                style={{
                  width: "181px",
                  height: "88px",
                  borderRadius: "8px",
                  marginTop: "15px",
                }}
              />
              <IoCheckmarkOutline
                style={{
                  width: "23px",
                  height: "23px",
                  right: 14,
                  position: "relative",
                  top: -31,
                  backgroundColor: "#03A600",
                  borderRadius: "50%",
                  color: "#FFFFFF",
                }}
              />
            </Box>

            {/* Instructions Section */}
            <Typography variant="h6" sx={{ color: "#504E50", fontWeight: 500 }}>
              Instructions for signature:-
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: "3px" }}>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  1. Make sure your signature is clear and legible without any
                  smudges or overlaps.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  2. Use a plain white background for your signature to ensure
                  clear visibility.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  3. Use a black or dark blue ink pen to sign. Avoid using other
                  colors as they may not scan well.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  4.The signature should be done by you personally, not by any
                  other person or digitally generated.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={handleNextSignatureClick}
              name={buttonLoader ? "Next" : "Next"}
            />
          </DialogActions>
        </Dialog>

        <Box sx={{ display: "flex", gap: "8px" }}>
          <BackButton handleBack={handleBack} />
        </Box>

        <Box sx={{ display: "flex", gap: "8px" }}>
          {/* <BackButton name={"clear"} /> */}

          <SaveButton name={"Save As Draft"} handleDraft={handleDraft} />
          <SubmitButton
            handleSubmit={handleSubmit}
            name={buttonLoader ? "Submit" : "Submit"}
          />
        </Box>
      </Grid>
      {cropPhotoImage && (
        <ImageCrop
          showModal={showModal?.show}
          handleClose={handleClose}
          image={cropPhotoImage}
          onCropDone={onCropDone}
          onCropCancel={onCropCancel}
        />
      )}
      {cropSignatureImage && (
        <ImageCropSignature
          showModal={showModalSignature?.show}
          handleClose={handleClose}
          image={cropSignatureImage}
          onCropDone={onCropDoneSignature}
          onCropCancel={onCropCancelSignature}
        />
      )}
    </Grid>
  );
}
