import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  styled,
} from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import PrintDownloadButton from "../../common/button/printDownloadButton";

import GovernmentImage from "../../assets/images/courseRegistration/Government.png";
import CetImage from "../../assets/images/courseRegistration/Cet.png";
import { MdDownload, MdPrint } from "react-icons/md";
import { cssProperties } from "../../utils/commonCssProperties";
import DataTable from "../../common/dataTable";
import { useReactToPrint } from "react-to-print";
import { usePDF } from "react-to-pdf";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DecryptFunction } from "../../utils/cryptoFunction";

const Index = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  let transactionid = DecryptFunction(useParams()?.id);
  // console.log("params--->", params);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const paymentReceiptList = useSelector(
    (state) => state.candidatesReducer?.paymentReceiptList
  );
  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: "GET_PAYMENT_RECEIPT_LIST",
        payload: { candidateid: candidateid },
      });
    }
  }, [candidateid]);

  useEffect(() => {
    if (paymentReceiptList.length > 0) {
      let found = paymentReceiptList.find(
        (obj) => parseInt(obj.transactionsid) === parseInt(transactionid)
      );
      setData(found);
    }
  }, [paymentReceiptList, transactionid]);

  // let data = {
  //   id: 24315,
  //   referencenumber: 113400086495,
  //   name: "Daniel",
  //   transactionamount: 800,
  //   address_1: "Address line 1",
  //   address_2: "Address line 2",
  //   address_3: "Address line 3",
  //   date: "06/30/2024 8:05:31 PM",
  //   payment_method: "cash",
  //   payment_status: "paid",
  //   mode_of_payment: "Payment Gateway (Powered By Razorpay)",
  //   purpose: "Application Fee",
  // };
  const columnData = [
    {
      name: "S No",
      accessor: "s_no",
    },
    {
      name: "Payment For",
      accessor: "payment_for",
    },
    {
      name: "Amount",
      accessor: "amount",
    },
    {
      name: "Gst",
      accessor: "gst",
    },
  ];
  let tableData = [
    {
      s_no: 1,
      payment_for: "Registration",
      amount: 1000,
      gst: "200",
    },
    {
      s_no: 2,
      payment_for: "Admission",
      amount: 1000,
    },
  ];
  const theme = useTheme();
  const { toPDF, targetRef } = usePDF({ filename: "Payment Receipt.pdf" });

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const style = styled({
    fontStyle: {
      fontSize: isMobile ? 18 : 20,
      fontWeight: 600,
    },
  });
  const classes = style();
  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
  });
  // return (
  //     <Box >

  //         <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 2 }}>
  //             <Typography pb={1} variant="h5"  >Payment Receipt</Typography>

  //         </Box>
  //         <Box

  //             sx={{
  //                 maxWidth: 800,
  //                 margin: '0 auto',
  //                 minHeight: "50vh",
  //                 backgroundColor: "#fff",
  //                 borderRadius: "8px",
  //                 paddingBottom: 2,
  //                 border: `1px solid ${cssProperties.bordercolor.secondary3}`
  //             }}>
  //             <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', padding: 2 }}>
  //                 <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
  //                     <PrintDownloadButton name="Print Receipt" handleSubmit={handlePrint} startIcon={<MdPrint />} />
  //                     <PrintDownloadButton name="Download Receipt" handleSubmit={()=>toPDF()} startIcon={<MdDownload />} />
  //                 </Box>
  //             </Box>
  //             <div style={{
  //                 backgroundColor: "#fff",
  //                 borderRadius: "8px",

  //             }} id='receipt' ref={targetRef}>
  //                 <Grid container sx={{ display: "flex", justifyContent: "flex-start", padding: '25px 50px 0   50px', }}>
  //                     <Grid item md={3}>
  //                         <img style={{ height: "141px", width: "auto" }} src={CetImage} alt="logo_image" />
  //                     </Grid>
  //                     <Grid item md={6} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
  //                         <Typography sx={classes.fontStyle}>Government of Maharashtra</Typography>
  //                         <Typography sx={classes.fontStyle}>State Common Entrance Test Cell, Mumbai</Typography>
  //                     </Grid>
  //                     <Grid item md={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
  //                         <img style={{ height: "143px", width: "auto" }} src={GovernmentImage} alt="logo_image" />
  //                     </Grid>
  //                 </Grid>
  //                 <Box sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
  //                     <Box sx={{ flex: 1, padding: 2, background: cssProperties.color.primary }}></Box>
  //                     <Box sx={{ flex: 1, padding: 2, background: '#fff', textAlign: 'center', minWidth: 200 }}>
  //                         <Typography variant='h6'>Payment Receipt</Typography>
  //                     </Box>
  //                     <Box sx={{ flex: 1, padding: 2, background: cssProperties.color.primary }}></Box>
  //                 </Box>
  //                 <Box sx={{ display: 'flex', alignItems: 'start', padding: '0 25px 25px 25px' }}>
  //                     <Box sx={{ width: 250 }}>
  //                         <Typography variant='p' fontWeight={800}>Name</Typography>

  //                     </Box>
  //                     <Box sx={{ flex: 1 }}>
  //                         <Typography variant='p'>{data.name}</Typography>

  //                     </Box>
  //                 </Box>
  //                 <Box sx={{ display: 'flex', alignItems: 'start', padding: '0 25px 25px 25px' }}>
  //                     <Box sx={{ width: 250, }}>
  //                         <Typography variant='p' fontWeight={800}>Address</Typography>

  //                     </Box>
  //                     <Box sx={{ flex: 1, }}>

  //                         <Typography component="p" variant='p'>{data.address_1}</Typography>
  //                         <Typography component="p" variant='p'>{data.address_2}</Typography>
  //                         <Typography component="p" variant='p'>{data.address_3}</Typography>

  //                     </Box>
  //                 </Box>
  //                 <Box sx={{ display: 'flex', alignItems: 'start', padding: '0 25px 25px 25px' }}>
  //                     <Box sx={{ width: 250, }}>
  //                         <Typography variant='p' fontWeight={800}>Date</Typography>

  //                     </Box>
  //                     <Box sx={{ flex: 1, }}>
  //                         <Typography variant='p'>{data.date}</Typography>

  //                     </Box>
  //                 </Box>
  //                 <Box sx={{ display: 'flex', alignItems: 'start', padding: '0 25px 25px 25px' }}>
  //                     <Box sx={{ width: 250, }}>
  //                         <Typography variant='p' fontWeight={800}>Payment Method</Typography>

  //                     </Box>
  //                     <Box sx={{ flex: 1, }}>
  //                         <Typography variant='p'>{data.payment_method}</Typography>

  //                     </Box>
  //                 </Box>
  //                 <Box sx={{ minHeight: '50vh', padding: '25px 25px 0   25px', }}>
  //                     <DataTable keys={columnData} values={tableData} />
  //                     <Box sx={{ height: 2, background: cssProperties.color.primary, marginTop: 2 }}></Box>
  //                     <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: 2, paddingTop: 2 }}>
  //                         <Typography variant='p' component="p" fontWeight={800}>Total</Typography>
  //                         <Typography variant='p' component="p" >{tableData.reduce((total, item) => total + (item.amount || 0), 0)}</Typography>

  //                     </Box>
  //                 </Box>
  //             </div>
  //         </Box>

  //     </Box>

  // )

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // const getStatus = (status) => {
  //   return status === 1
  //     ? "Success"
  //     : status === 2
  //     ? "Failed"
  //     : status === 3
  //     ? "Cancelled"
  //     : status === 4
  //     ? "Refund"
  //     : status === 5
  //     ? "Chargeback"
  //     : "Failed";
  // };
  const getStatus = (status) => {
    return status === 1 ? "Success" : "Failed";
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 2,
        }}
      >
        <Typography pb={1} variant="h5">
          Payment Receipt
        </Typography>
      </Box>
      <Box
        sx={{
          maxWidth: 800,
          margin: "0 auto",
          minHeight: "50vh",
          backgroundColor: "#fff",
          borderRadius: "8px",
          paddingBottom: 2,
          border: `1px solid ${cssProperties.bordercolor.secondary3}`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <PrintDownloadButton
              name="Print Receipt"
              handleSubmit={handlePrint}
              startIcon={<MdPrint />}
            />
            {/* <PrintDownloadButton
              name="Download Receipt"
              handleSubmit={() => toPDF()}
              startIcon={<MdDownload />}
            /> */}
          </Box>
        </Box>
        <div
          style={{
            backgroundColor: "#fff",
            borderRadius: "8px",
          }}
          id="receipt"
          ref={targetRef}
        >
          <Box sx={{ maxWidth: 800, margin: "0 auto" }}>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                padding: "25px 0",
                border: `5px solid ${cssProperties.bordercolor.primary}`,
                textAlign: "center",
              }}
            >
              <Grid
                item
                sm={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 24 }}
                >
                  STATE COMMON ENTRANCE TEST CELL, MAHARASHTRA STATE
                </Typography>
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 500, fontSize: 16 }}
                >
                  8th Floor, New Excelsior Building, A.K.Nayak Marg, Fort,
                  Mumbai-400001. (M.S.)
                </Typography>
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 24 }}
                >
                  Detailed Payment Receipt
                </Typography>
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 18 }}
                >
                  ({data?.coursename})
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",

                padding: "25px 0",
                border: `5px solid ${cssProperties.bordercolor.primary}`,
              }}
              rowSpacing={1}
            >
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Payee Id
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {data?.applicantid}
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Name Of Payee
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {data?.name}
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Reference Number
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {data?.receiptno}
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Transaction Amount
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {data?.amount}
                </Typography>
              </Grid>

              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Payment Initiation Date
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {data?.orderdate}
                </Typography>
              </Grid>

              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Payment Status
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {data?.statustext}
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Payment Completion Date
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {data?.paymentcompletiondate}
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Mode Of Payment
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  {data?.gatewaycourseid == 1
                    ? "Billdesk"
                    : data?.gatewaycourseid == 2
                    ? "PayU"
                    : ""}
                </Typography>
              </Grid>

              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "end",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 400, fontSize: 16 }}
                >
                  Purpose
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  padding: `0 5px`,
                  justifyContent: "start",
                }}
              >
                <Typography
                  sx={{ ...classes.fontStyle, fontWeight: 600, fontSize: 16 }}
                >
                  Registration fee - {data?.coursename?.trim()}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default Index;
