export const handleNameValidation = name => {
  if (name === null || name === undefined || name === '') {
    return null;
  }
  if (name.length > 35) {
    return 'Name must be less than or equal to 35 characters';
  }
  const isValid = /^[a-zA-Z\s.]*$/.test(name);
  if (!isValid) {
    return 'Only alphabets are allowed in the name field';
  }
  return null;
};


export const AlphabetsValidation = (e) => {
  let value = e.target.value;

  value = value.replace(/[^a-zA-Z\s.]/g, '');

  if (value[0] === ' ' || value.split('.').length > 2) {
    value = value.slice(0, -1);
  }

  if (value === '.' || /^[a-zA-Z\s]*\.$/.test(value)) {
    e.target.value = value;
  } else {
    e.target.value = value.replace(/\./g, '');
  }

  return e.target.value;
};
export const AlphabetsValidationUppercase = (e) => {
  let value = e.target.value;

  value = value.replace(/[^a-zA-Z\s.]/g, '');

  if (value[0] === ' ' || value.split('.').length > 2) {
    value = value.slice(0, -1);
  }

  if (value === '.' || /^[a-zA-Z\s]*\.$/.test(value)) {
    e.target.value = value.toUpperCase();
  } else {
    e.target.value = value.replace(/\./g, '').toUpperCase();
  }

  return e.target.value;
};



export const AlphabetsandNumbersValidation = (e) => {
  let value = e.target.value.replace(/[^a-zA-Z0-9\s]/gi, '');

  if (value[0] === ' ') {
    value = value.slice(1);
  }

  e.target.value = value;
  return value;
};

export const AddressValidation = (address) => {
  if (!address) {
    return null;
  }
  const isValid = /^[a-zA-Z0-9\s]*$/.test(address);
  let error = null;

  if (!isValid) {
    error = 'Only alphabets and numbers are allowed.';
  } else if (address.length > 50) {
    error = 'Address must be less than or equal to 50 characters.';
  }
  return error;
};

// export const AddressValidationFirstField = (address) => {
//   const isValid = /^[a-zA-Z0-9\s,\/-]*$/.test(address);
//   let error = null;

//   if (!isValid) {
//     error = 'Special characters are not allowed.';
//   } else if (address?.length > 50) {
//     error = 'Address must be less than or equal to 50 characters.';
//   }
//   return error;
// };
export const AddressValidationFirstField = (address) => {
  const isValid = /^[a-zA-Z0-9\s.,\/\-():]*$/.test(address);
  let error = null;

  if (!isValid) {
    error = 'Only the following characters are allowed: alphabets (A-Z, a-z), numbers (0-9), spaces, period (.), comma (,), hyphen (-), forward slash (/), parentheses ( ), and colon (:).';
  } else if (address?.length > 50) {
    error = 'Address must be less than or equal to 50 characters.';
  }
  return error;
};

export const pincodeValidation = (input) => {
  if (!input) {
    return null;
  }
  const isValid = /^[0-9]{6}$/.test(input);
  let error = null;
  if (!isValid) {
    error = 'Invalid input. Pincode must be 6 digits long.';
  }
  return error;
};



export const NumbersValidation = (e) => {
  let value = e.target.value.replace(/[^0-9]/g, '');
  e.target.value = value;
  return value;
};

export const MobileNumberValidation = (e) => {
  const validValue = e.target.value.replace(/\D/g, '');
  const containsOnlyZeroes = /^0+$/.test(validValue);
  if (containsOnlyZeroes) {
    e.target.value = '';
  } else {
    e.target.value = validValue;
  }
};

export const MobileNumberFirstNumberValidation = (number) => {
  if (number?.length > 0) {
    let value = number;
    if (parseInt(value?.slice(0, 1)) < 6) {
      return 'Enter valid mobile number';
    }
  }
};


export const telephoneNumberFirstNumberValidation = (number) => {
  if (number?.length > 0) {
    let value = number;
    if (parseInt(value?.slice(0, 1)) < 2) {
      return 'Enter valid mobile number';
    }
  }
};
export const percentageValidation = (input) => {
  let error = null;

  // Allow null or undefined values (skip validation)
  if (input === null || input === undefined || input === "") {
    return error; // No error for null, undefined, or empty input
  }

  // Check if input is not a number
  if (isNaN(input)) {
    error = 'Invalid input. Please enter a number.';
    return error;
  }

  const inputStr = input.toString();
  const decimalPlaces = inputStr.split('.')[1]?.length || 0;

  // Check for more than two decimal places
  if (decimalPlaces > 2) {
    error = 'Invalid input. Percentage cannot have more than two decimal places.';
    return error;
  }

  const value = parseFloat(inputStr);

  // Check for negative values
  if (value < 1) {
    error = 'Invalid input. Percentage should be greater than 1.';
  }
  // Check for values greater than 100
  else if (value > 100) {
    error = 'Invalid input. Percentage cannot be greater than 100.';
  }
  // Check if percentage is greater than 99.99 and has more than 5 characters
  else if (value > 100.00 && inputStr.length > 5) {
    error = 'Invalid input. Percentage with two digits cannot exceed 100.00.';
  }

  return error;
};

// export const cgpaValidation = (input) => {
//     let error = null;

//   // Allow null or undefined values (skip validation)
//   if (input === null || input === undefined || input === "") {
//     return error; // No error for null, undefined, or empty input
//   }

//   if (isNaN(input)) {
//     error = 'Invalid input. Please enter a number.';
//     return error;
//   }

//   const inputStr = input.toString();
//   const decimalPlaces = inputStr.split('.')[1]?.length || 0;
//   if (decimalPlaces > 2) {
//     error = 'Invalid input. Value cannot have more than two decimal places.';
//     return error;
//   }

//   const value = parseFloat(inputStr);
//   if (value < 0) {
//     error = 'Invalid input. Value cannot be negative.';
//   }
//   else if (value > 10.0) {
//     error = 'Invalid input. Value cannot be greater than 10.0.';
//   }

//   return error;
// };

export const cgpaValidation = (input) => {
  let error = null;

  // Allow null or undefined values (skip validation)
  if (input === null || input === undefined || input === "") {
      return error; // No error for null, undefined, or empty input
  }

  if (isNaN(input)) {
      error = 'Invalid input. Please enter a number.';
      return error;
  }

  const inputStr = input.toString();
  const decimalPlaces = inputStr.split('.')[1]?.length || 0;
  if (decimalPlaces > 2) {
      error = 'Invalid input. Value cannot have more than two decimal places.';
      return error;
  }

  const value = parseFloat(inputStr);
  if (value < 1.0) {
      error = 'Invalid input. Value must be greater than 1.0.';
  } else if (value < 0) {
      error = 'Invalid input. Value must be greater than 1.0.';
  } else if (value > 10.0) {
      error = 'Invalid input. Value cannot be greater than 10.0.';
  }

  return error;
};


export const pincodeNumberValidation = (e) => {
  let value = e.target.value.replace(/[^0-9]/g, '').slice(0, 6);
  e.target.value = value;
  return value;
};

// export const percentageNumberValidation = (e) => {
//   let value = e.target.value;
//   value = value.replace(/[^0-9.]/g, '');
//   const decimalIndex = value.indexOf('.');
//   if (decimalIndex !== -1 && value.length - decimalIndex > 3) {
//     value = value.slice(0, decimalIndex + 3);
//   }
//   const numericValue = parseFloat(value);
//   if (numericValue <= 0) {
//     value = '';
//   } else if (numericValue > 900) {
//     value = '900';
//   } else if (numericValue > 900 && value.length > 3) {
//     value = '899.99';
//   }

//   e.target.value = value;
//   return value;
// };

export const percentageNumberValidation = (input) => {
  let error = null;

  // Allow null, undefined, or empty values (skip validation)
  if (input === null || input === undefined || input === "") {
    return error; // No error for null, undefined, or empty input
  }

  if (isNaN(input)) {
    error = "Invalid input. Marks can only contain numbers and a decimal point.";
    return error;
  }

  const inputStr = input.toString();
  const decimalPlaces = inputStr.split(".")[1]?.length || 0;

  if (decimalPlaces > 2) {
    error = "Invalid input. Value cannot have more than two decimal places.";
    return error;
  }

  const value = parseFloat(inputStr);

  if (value < 1) {
    error = "Invalid input. Value must be greater than 1.";
  } 
  else if (value > 900) {
    error = "Invalid input. Value cannot be greater than 900.";
  }

  return error;
};

// export const markValidation = (input) => {
//   let error = null;
//   if (input === null || input === undefined || input === "") {
//     return error;
//   }
//   const inputStr = input.toString();

//   if (inputStr.includes('.')) {
//     error = 'Invalid input. Marks cannot have decimal places.';
//     return error;
//   }

//   const value = parseInt(inputStr, 10);
//   if (value < 1) {
//     error = 'Invalid input. Marks must be greater than 1';
//   }
//   else if (value > 1000) {
//     error = 'Invalid input. Marks cannot be greater than 1000.';
//   }
//   else if (inputStr.length > 4) {
//     error = 'Invalid input. Marks cannot have more than four digits.';
//   }

//   return error;
// };
export const markValidation = (input) => {
  let error = null;

  // Check if the input is null, undefined, or empty
  if (input === null || input === undefined || input === "") {
    return error;
  }

  const inputStr = input.toString();

  // Allow only numeric values and at most one decimal point
  const numericRegex = /^[0-9]+(\.[0-9]+)?$/; // Accepts integers and decimals
  if (!numericRegex.test(inputStr)) {
    error = "Invalid input. Marks can only contain numbers and a decimal point.";
    return error;
  }

  const value = parseFloat(inputStr); // Use parseFloat to handle decimals

  // Ensure the value is not less than 1
  if (value < 1) {
    error = "Invalid input. Marks must be greater than or equal to 1.";
  } else if (value > 1000) {
    error = "Invalid input. Marks cannot be greater than 1000.";
  } else if (inputStr.length > 6) {
    // Allow up to 4 digits and 2 decimal places (e.g., 9999.99)
    error = "Invalid input. Marks cannot have more than four digits and two decimal places.";
  }

  return error;
};


// export const outOfMarkValidation = (input, obtained) => {
//   let error = null;
//   if (input === null || input === undefined || input === "") {
//     return error;
//   }
//   const inputStr = input.toString();

//   if (inputStr.includes('.')) {
//     error = 'Invalid input. Marks cannot have decimal places.';
//     return error;
//   }

//   const value = parseInt(inputStr, 10);
//   const obt = parseInt(obtained);
//   if (value < 1) {
//     error = 'Invalid input.Marks must be greater than 1.';
//   }
//   else if (value < obt) {
//     error = 'Invalid input. out of Mark cannot be lesser than obtained mark.';
//   }
//   else if (inputStr.length > 4) {
//     error = 'Invalid input. Marks cannot have more than four digits.';
//   }

//   return error;
// };




// export const handleEmailValidation = email => {
//   // eslint-disable-next-line no-useless-escape
//   const isValid = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
//     email
//   );
//   let error;

//   if (!isValid) {
//     error = 'Enter valid Email id';
//   }

//   return error;
// };


export const outOfMarkValidation = (input, obtained) => {
  let error = null;

  if (input === null || input === undefined || input === "") {
    return error;
  }

  // Check if input is a valid number (allowing only digits and a single dot)
  const isValidNumber = /^[0-9]*\.?[0-9]+$/.test(input); // Allow decimals
  if (!isValidNumber) {
    return "Invalid input. Only numbers and a single decimal point are allowed.";
  }

  const value = parseFloat(input); // Use parseFloat to handle decimals
  const obt = parseFloat(obtained); // Ensure obtained is parsed as float too

  // Check if value is less than 1
  if (value < 1) {
    return "Invalid input. Marks must be greater than or equal to 1.";
  }

  // Check if value is less than obtained marks
  if (value < obt) {
    return "Invalid input. Out of Mark cannot be lesser than obtained mark.";
  }

  // Check if value has more than four digits before the decimal
  // const [integerPart] = input?.split(".");
  // if (integerPart.length > 4) {
  //   return "Invalid input. Marks cannot have more than four digits before the decimal point.";
  // }

  return error;
};


export const handleEmailValidation = email => {
  const isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  let error;

  if (!isValid.test(email)) {
    error = 'Enter a valid email address';
  }

  return error;
};

export const passwordValidation = (password) => {

  let errors = [];

  // Check for minimum length
  if (password.length < 8) {
    errors.push('Password must be at least 8 characters long.');
  }

  // Check for uppercase letter
  if (!/[A-Z]/.test(password)) {
    errors.push('Password must contain at least one uppercase letter.');
  }

  // Check for lowercase letter
  if (!/[a-z]/.test(password)) {
    errors.push('Password must contain at least one lowercase letter.');
  }

  // Check for number
  if (!/\d/.test(password)) {
    errors.push('Password must contain at least one number.');
  }

  // Check for special character
  if (!/[!@#$%^&*]/.test(password)) {
    errors.push('Password must contain at least one special character.');
  }

  return errors.length > 0 ? errors.join(' ') : true;
};

export const InputTrimFunction = (e) => {
  if (e.target.value?.length > 0) {
    return e.target.value = e.target.value?.trim();
  }
};

export const InputTrimAndLowercaseFunction = (e) => {
  if (e.target.value?.length > 0) {
    return e.target.value = e.target.value?.trim()?.toLowerCase();
  }
};

export const calculateDaysDifference = (startDate, endDate) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const diffDays = Math.round(Math.abs((endDate - startDate) / oneDay));
  return diffDays || "0";
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

export const hashMobile = (mobile) => {
  const hashedMobile = mobile.substring(0, 4) + '****' + mobile.substring(8, 10);
  return hashedMobile;
};