import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import candidatesActions from "../redux/candidates/actions";
import Sidebar from "../layouts/sideBar";
import Layout from "../layouts/layout";
import Header from "../layouts/header";
import { Box, Grid, Typography } from "@mui/material";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { FaHourglassStart } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import authActions from "../redux/auth/actions";
import PrimaryButton from "./primaryButton";
import { BILLDESK_URL } from "../utils/constants";

export default function BilldeskStatus() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState("");

  const [searchParams] = useSearchParams();
  const courseid = searchParams.get("courseid");
  const coursename = searchParams.get("coursename");
  const receiptno = searchParams.get("receiptno");
  const isextrafee = searchParams.get('isextrafee');
  const subjectgroup = searchParams.get('subjectgroup');
  const { courseList } = useSelector((state) => state.candidatesReducer);


  const candidateDetails = useSelector(
    (state) => state.candidatesReducer.FullCandidateDetails
  );
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );

  useEffect(() => {
    if (candidateid) dispatch({ type: candidatesActions.GET_ALL_COURSE_LIST });
  }, [candidateid]);
  useEffect(() => {
    dispatch({ type: authActions.CANDIDATE_TOKEN_VERIFY });
    if (candidateid) {
      dispatch({ type: candidatesActions.GET_FULL_CANDIDATE_DETAILS });
      getTransactions();
    }
  }, [candidateid]);

  const getTransactions = async () => {

    const response = await axios.post(
      `${BILLDESK_URL}/billdesk-mhcet/get-transactions`,
      { candidateid: candidateid, courseid: courseid, receiptno: receiptno,isextrafee:isextrafee,
        subjectgroup:subjectgroup }
    );
    const result = response.data;
    // if (!result.data) {
    //   navigate("/home?page=preview");
    // }
    if (parseInt(result?.data) === 1) {
      setSuccess("1");
      dispatch({ type: candidatesActions.GET_FULL_CANDIDATE_DETAILS });
    } else {
      setSuccess("0");
    }
  };

  return (
    <>
      <Layout>
        <Header />
        <Sidebar />
        <Grid
          sx={{
            height: "70vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              borderRadius: 2,
              p: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              fontWeight: 600,
            }}
          >
            {" "}
            {success === "1" ? (
              <IoCheckmarkCircleOutline
                size={45}
                style={{ fontSize: 90, color: "#06C270" }}
              />
            ) : success === "0" ? (
              <MdOutlineCancel
                size={45}
                style={{ fontSize: 90, color: "#E6393E" }}
              />
            ) : (
              <FaHourglassStart
                size={45}
                style={{ fontSize: 90, color: "#FFFF00" }}
              />
            )}
            <Typography variant="h6" sx={{ mt: 1, textAlign: "center" }}>
              Payment Status -{" "}
              {success === "1"
                ? "Success"
                : success === "0"
                ? "Failed"
                : "In Process"}
            </Typography>
            {candidateDetails[0]?.applicantid && success !== "0" && (
              <Typography
                variant="h6"
                pb={1}
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#3F41D1",
                }}
              >
                Your application no - {candidateDetails[0]?.applicantid}
              </Typography>
            )}
            {(success === "1" || success === "0") && (
              <PrimaryButton
                title={
                  success === "1" ? "Done" : success === "0" ? "Back" : null
                }
                onClick={() => {
                  if (success === "1") {
                    navigate(
                      `/mhcet/course-registration?page=printapplication`,
                      { state: { courseid: courseid,coursename:courseList?.all?.find((obj)=>parseInt(obj.courseid) === parseInt(courseid))?.coursename } }
                    );
                    window.location.reload();
                  } else if (success === "0") {
                    navigate("/mhcet/confirm-payment", {
                       state: { courseid: courseid,coursename:courseList?.all?.find((obj)=>parseInt(obj.courseid) === parseInt(courseid))?.coursename } 
                  });
                  }
                }}
              />
            )}
          </Box>
        </Grid>
      </Layout>
    </>
  );
}
