import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { MdOutlineCancel } from "react-icons/md";
import { useSearchParams, Link } from "react-router-dom";
import PrimaryButton from "../primaryButton";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import authActions from "../../redux/auth/actions";

export default function VerifiedEmailPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = searchParams.get("status");
  const handleMobileNoVerification = () => {

    dispatch({
      type: authActions.SEND_OTP_FOR_MOBILE_VERIFICATION,
      payload: { data: { mobileno: Cookies.get('mhet_cnd_mobileno'), navigate: navigate } },
    });

  }
  
  return (
    <Grid
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px"
      }}
    >
      {status === "failed" ? (
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: 2,
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <MdOutlineCancel
            size={45}
            style={{ fontSize: 90, color: "#E6393E" }}
          />
          <Typography variant="h6"  sx={{fontSize:'20px',fontWeight:500,color:'#212121', mt: 2, textAlign: "center" }}>
            Your email verification line has been expired, Please try again
          </Typography>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Box pt={2}>
              <PrimaryButton title="Try Again" />
            </Box>
          </Link>
        </Box>
      ) : (
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: 2,
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontWeight: 600,
          }}
        >
          <IoCheckmarkCircleOutline
            size={45}
            style={{ fontSize: 90, color: "#06C270" }}
          />
          <Typography variant="h6"  sx={{fontSize:'20px',fontWeight:500,color:'#212121', mt: 2, textAlign: "center" }}>
            Your email has been verified successfully!
          </Typography>
          <PrimaryButton title="Done" onClick={handleMobileNoVerification} />
        </Box>
      )}
    </Grid>
  );
}
