import {
  Box,
  Checkbox,
  Grid,
  Typography,
  Link as MuiLink,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BluePrimaryButton from "../../common/button/bluePrimaryButton";
import { cssProperties } from "../../utils/commonCssProperties";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import candidatesActions from "../../redux/candidates/actions";
import PageLoader from "../../common/pageLoader";
export default function Index() {
  const { courseid } = useLocation().state;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { courseList } = useSelector((state) => state.candidatesReducer);
  const [course, setCourse] = useState(null);
  const [check, setCheck] = useState(false);
  const handleClick = () => {
    navigate(
      `/${Cookies.get(
        "mhet_cnd_project"
      )}/course-registration?page=personalinformation`,
      { state: { courseid: courseid,coursename:course?.coursename} }
    );
    dispatch({
      type: candidatesActions.GET_COURSE_TAB,
      payload: {
        data: {
          courseid: courseid 
        },
      },
    });
  };
  useEffect(() => {
    dispatch({ type: candidatesActions.GET_ALL_COURSE_LIST })
  }, [])
  useEffect(() => {
    courseid && setCourse(courseList?.all?.find((obj) => obj.courseid === courseid));
  }, [courseList, courseid]);

  if (course) {
    return (
      <Grid
        p={2}
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid rgba(213, 213, 213, 1)",
          borderRadius: "8px",
          minHeight: "50vh",
          widht: "100vw",
          wordBreak: "break-word",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box>
            <Typography variant="h5" fontWeight={600}>
              {" "}
              Pre-Requisite for {course?.coursename}{" "}
            </Typography> 
          </Box>
          {course?.brochurelocation && course?.brochurelocation !== "" && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: 600,
                padding: "15px 0px",
              }}
            >
              <MdKeyboardDoubleArrowRight />
              <MuiLink
                target="_blank"
                href={course?.brochurelocation}
                download="brochure.pdf"
              >
                {" "}
                Download information Brochure for {course?.coursename}{" "}
              </MuiLink>
            </Box>
          )}
        </Box>
        <Grid  spacing={2}>
          <Grid item lg={6} sm={12} xs={12}>
            <div style={{ borderRadius: "8px", textWrap: "wrap",backgroundColor: "#FCDEDC", padding: "30px",  }} dangerouslySetInnerHTML={{ __html: course?.prerequisite }} />
          </Grid>
        </Grid>

        <Box
          mt={3}
          sm={12}
          md={12}
          lg={12}
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Checkbox onChange={(e) => setCheck(e.target.checked)} />{" "}
          <Box sx={{ fontWeight: 500, color: "#E6393E" }}>
            I have downloaded and reviewed the information brochure for {course?.coursename} . I have thoroughly read and understood all the instructions provided in the brochure, as well as those mentioned above. Furthermore, I confirm that I have carefully read and understood the eligibility criteria for the {course?.coursename}  and I will complete the application form accordingly.
          </Box>
        </Box>
        <Box
          mt={3}
          sm={12}
          md={12}
          lg={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          {" "}
          {MauvelousPrimaryButton({
            title: "Proceed to complete the application form",
            handleClick: handleClick,
            disabled: !check,
          })}{" "}
        </Box>
      </Grid>
    );
  }
  return <PageLoader />;
}
