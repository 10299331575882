import { Grid, Box } from "@mui/material";
import React, { useRef, useState } from "react";
import ApplicationForm from "./applicationForm";
import PrintDownloadButton from "../../common/button/printDownloadButton";
import { IoMdDownload } from "react-icons/io";
import { MdPrint } from "react-icons/md";
import { useReactToPrint } from "react-to-print";
import { useLocation, useSearchParams } from "react-router-dom";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useDispatch, useSelector } from "react-redux";
import candidatesActions from "../../redux/candidates/actions";
import { DecryptObjectFunction } from "../../utils/cryptoFunction";
import ApplicationFormPreview from "./applicationFormPreview";

export default function PrintApplicationForm() {
  const location = useLocation();
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const courseid = location.state?.courseid;
  
  const [searchParams] = useSearchParams();
  const encryptedParams = searchParams.get("c_data");
  let decrypted = DecryptObjectFunction(encryptedParams);
  const courseidVal = decrypted?.courseid;
  const targetRef = useRef();
  const dispatch = useDispatch();
  const [print,setPrint] = useState(false)


  const handlePrint = useReactToPrint({
    content: () => targetRef.current,
  });

  const handleDownloadPDF = () => {
    const input = targetRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
  
      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save('application_form.pdf');
    });
  };

  const handlePrintLog = () => {
    dispatch({ type:candidatesActions.CREATE_PRINT_LOG,
      payload: { data : { candidateid : candidateid, courseid: courseid }}
    })
    setPrint(false)
  }

  const handlePrintState = () => {
    setPrint(true)
  }
  return (
    <Grid
      style={{
        minHeight: "50vh",
        backgroundColor: "#fff",
        borderRadius: "8px",
      }}
    >
      <Grid
        container
        pt={1}
        sx={{ display: "flex", justifyContent: "flex-end"}}
      >
        {/* <PrintDownloadButton
          name="Download Application Form"
          startIcon={<IoMdDownload />}
          handleSubmit={handleDownloadPDF}
        /> */}
           <PrintDownloadButton
          name=     {location.pathname + location.search ==
            "/mhcet/course-registration?page=preview"  ? "Print Preview Form" : "Print Application Form"}
          startIcon={<MdPrint />}
          handleSubmit={() => {
      setTimeout(handlePrintState,1000)  ;
        setTimeout(handlePrint,2000);
            setTimeout(handlePrintLog,3000);
          }}
        />
      </Grid>
      <Grid pt={2}>
        <Box>
          {/* <ApplicationForm courseId={courseid ? courseid : courseidVal} targetRef={targetRef} /> */}
          <ApplicationFormPreview courseId={courseid ? courseid : courseidVal} targetRef={targetRef} print ={print} />

        </Box>
      </Grid>
    </Grid>
  );
}
