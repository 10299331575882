import React, { useEffect, useRef, useState,Suspense  } from "react";
import {
  Box,
  Checkbox,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  CircularProgress,
} from "@mui/material";
import { MdOutlineModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import candidatesActions from "../../redux/candidates/actions";
import masterActions from "../../redux/master/action";
import dayjs from "dayjs";
import authActions from "../../redux/auth/actions";
import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
import GovernmentImage from "../../assets/images/courseRegistration/Government.png";
import CetImage from "../../assets/images/courseRegistration/Cet.png";
import araImg from "../../assets/images/courseRegistration/Ara.jpeg";
import { cssProperties } from "../../utils/commonCssProperties";
import "react-lazy-load-image-component/src/effects/blur.css";

// import { DecryptObjectFunction } from "../../utils/cryptoFunction";
import {
  DecryptObjectFunction,
  EncryptObjectFunction,
} from "../../utils/cryptoFunction";

import { masterFieldLabel } from "../../utils/courseValues";
const LazyLoadImage = React.lazy(() =>
  import('react-lazy-load-image-component').then((module) => ({ default: module.LazyLoadImage }))
);
export default function ApplicationFormPreview({ targetRef, courseId, print }) {
  const [CheckboxValue, setCheckBoxValue] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const courseid = location.state?.courseid;
  const coursename = location.state?.coursename;
  const [isImageLoaded, setIsImageLoaded] = useState(true);
  const [searchParams] = useSearchParams();
  const encryptedParams = searchParams.get("c_data");
  let decrypted = DecryptObjectFunction(encryptedParams);
  const coursenameVal = decrypted?.coursename;
  const [showImage, setShowImage] = useState(false);

  const PGPPreference = [
    { value: 1, label: "Engineering" },
    { value: 2, label: "Pharmacy" },
    { value: 3, label: "Agriculture" },
  ];
  const courseIdVal = decrypted?.courseid;
  const edit = decrypted?.edit;
  const isExtraFee = decrypted?.isExtraFee;
  const courseNameVal = decrypted?.coursename;
  let subjectgroup = decrypted?.subjectgroup;

  const { masterFields } = useSelector((state) => state.candidatesReducer);

  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid },
      });
      dispatch({
        type: candidatesActions.GET_MASTER_FIELDS,
        payload: {
          data: { courseid: courseid },
        },
      });
    }
  }, [courseid]);

  console.log(isImageLoaded, "isImageLoaded");

  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );

  //   const { courseFormDetails, personalDetails } = useSelector(
  //     (state) => state.candidatesReducer
  //   );

  const {
    courseFormDetails,
    personalDetails,
    examState,
    courseList,
    otbsVenueDetails,
    currentOtbsVenue,
  } = useSelector((state) => state.candidatesReducer);

  const applicantid = useSelector(
    (state) => state.candidatesReducer.applicantid.cnd_getapplicantid_bycourseid
  );
  const Language = [
    { value: 1, label: "English" },
    { value: 2, label: "Marathi" },
    { value: 3, label: "Urdu" },
    { value: 4, label: "Hindi" },
  ];
  const months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];
  const options = [
    { value: 1, label: "Option 1" },
    { value: 2, label: "Option 2" },
    { value: 3, label: "Option 3" },
    { value: 4, label: "Option 4" },
    { value: 5, label: "Option 5" },
  ];
  const { educationClass, educationStream } = useSelector(
    (state) => state.masterReducer
  );

  useEffect(() => {
    dispatch({ type: authActions.CANDIDATE_TOKEN_VERIFY });
    dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
    dispatch({ type: masterActions.GET_EDUCATION_CLASS });
    dispatch({ type: masterActions.GET_EDUCATION_STREAM });
  }, []);

  const { uploadDetails, courseUploadDetails } = useSelector(
    (state) => state.candidatesReducer
  );
  const fileUrl =
    courseUploadDetails?.find((item) => item?.documenttype === 1)?.fileurl ||
    uploadDetails?.find((item) => item?.documenttype === 1)?.fileurl;
  const fileUrl2 =
    courseUploadDetails?.find((item) => item?.documenttype === 2)?.fileurl ||
    uploadDetails?.find((item) => item?.documenttype === 2)?.fileurl;

  useEffect(() => {
    if (fileUrl && fileUrl2) {
      const timer = setTimeout(() => {
        setShowImage(true);
      }, 5000); // Delay of 3 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [fileUrl]);
  const status = courseFormDetails[0]?.status;
  const isPaid = courseFormDetails[0]?.ispaid;

  //   const handleProcced = () => {
  //     if (CheckboxValue) {
  //       dispatch({ type:candidatesActions.COURSE_PREVIEW_UPDATION,
  //         payload :{ data : { courseid:courseid, candidateid:candidateid }, coursename:coursename, navigate:navigate}
  //        })
  //     } else {
  //       if (!CheckboxValue) {
  //         setError("Select the checkbox is required");
  //       }
  //     }
  //   };
  // const handleCheckbox = (e) => {
  //   if(e && e?.target){

  //     const checked = e.target.checked;
  //     setCheckBoxValue(checked);
  //     setError("");
  //   }
  //   };

  const handleCheckbox = (e) => {
    if (e && e.target) {
      const checked = e.target.checked;
      setCheckBoxValue(checked);
      setError("");
    } else {
      console.error("Event or target is undefined.");
    }
  };

  const handleProcced = () => {
    if (CheckboxValue && edit) {
      let encryptedParams = {
        courseid: courseIdVal,
        isExtraFee: isExtraFee,
        coursename: courseNameVal,
        edit: edit,
        subjectgroup: subjectgroup,
      };
      navigate(
        isExtraFee
          ? `/${Cookies.get(
              "mhet_cnd_project"
            )}/course-registration?page=applicationfee&&c_data=${EncryptObjectFunction(
              encryptedParams
            )}`
          : `/${Cookies.get(
              "mhet_cnd_project"
            )}/course-registration?page=printapplication&&c_data=${EncryptObjectFunction(
              encryptedParams
            )}`
      );
    } else if (CheckboxValue) {
      dispatch({
        type: candidatesActions.COURSE_PREVIEW_UPDATION,
        payload: {
          data: { courseid: courseid || courseIdVal, candidateid: candidateid },
          coursename: coursename,
          navigate: navigate,
        },
      });
    } else {
      if (!CheckboxValue) {
        setError("Select the checkbox is required");
      }
    }
  };

  useEffect(() => {
    if (candidateid) {
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid || courseId },
      });
      dispatch({
        type: candidatesActions.GET_COURSE_APPLICANTID,
        payload: {
          courseid: courseid || courseId,
          candidateid: candidateid,
        },
      });
    
    }
  }, [candidateid]);

  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    if (uploadDetails) {
      if (uploadDetails[2]?.document) {
        try {
          const base64Data =
            uploadDetails[2]?.document.split(",")[1] ||
            uploadDetails[2]?.document;
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/pdf" });
          const url = URL.createObjectURL(blob);
          setPdfUrl(url);
          return () => URL.revokeObjectURL(url);
        } catch (error) {
          console.error("Error decoding Base64 string:", error);
        }
      }
    }
    dispatch({
      type: candidatesActions.GET_COURSE_UPLOAD_PERSONAL_DETAILS,
      data: {
        courseid: courseFormDetails
          ? courseFormDetails[0]?.courseid || courseId
          : null,
      },
    });
    // setTimeout(() => {
    //   dispatch({
    //     type: candidatesActions.GET_COURSE_UPLOAD_PERSONAL_DETAILS,
    //     data: {
    //       courseid: courseFormDetails
    //         ? courseFormDetails[0]?.courseid || courseId
    //         : null,
    //     },
    //   });
    // }, 2000);
  }, [uploadDetails[2]?.document, courseFormDetails]);

  if (
    (courseFormDetails?.length > 0 &&
      personalDetails?.length > 0 &&
      uploadDetails?.length > 0) ||
    (courseUploadDetails?.length > 0 
      &&
      fileUrl2 &&
      showImage
      //  &&
      // isImageLoaded == false
    )
  ) {
    return (
      <Box
        //   p={3}
        style={{
          minHeight: "50vh",
          backgroundColor: "#fff",
          // borderRadius: "8px",
          border: "2px solid #000",
        }}
        ref={targetRef}
      >
        <Box
          style={{
            borderBottom: "1px solid #000",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {location.pathname + location.search ===
            "/mhcet/course-registration?page=preview" && (
            <Typography
              fontWeight={600}
              sx={{ textAlign: "center", width: "100%", fontSize: 15 }}
            >
              {/* Online Registration For {coursename || coursenameVal} */}
              Preview Form for Online Registration For{" "}
              {coursename || coursenameVal}
            </Typography>
          )}
          {location.pathname + location.search ===
            "/mhcet/course-registration?page=printapplication" && (
            <Typography
              fontWeight={600}
              sx={{ textAlign: "center", width: "100%", fontSize: 15 }}
            >
              {/* Online Registration For {coursename || coursenameVal} */}
              Online Registration For {coursename || coursenameVal}
            </Typography>
          )}
          {/* <Typography fontWeight={600} sx={{textWrap:"nowrap",fontSize:15}}>
          Preview Form
        </Typography> */}
        </Box>
        <Grid
          container
          //   p={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "1px solid #000",
          }}
        >
          <Grid item sx={{ width: "100px" }}>
            <img
              style={{ height: "100px", width: "auto" }}
              src={CetImage}
              alt="image"
            />
          </Grid>
          <Grid
            item
            // md={8}

            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              flex: 1,
            }}
          >
            <img
              style={{ height: "75px", width: "auto" }}
              src={GovernmentImage}
              alt="image"
            />
            <Typography style={{ fontSize: 15 }} fontWeight={600}>
              GOVERNMENT OF MAHARASHTRA
            </Typography>
            <Typography style={{ fontSize: 15 }} fontWeight={600}>
              STATE COMMON ENTRANCE TEST CELL, MAHARASHTRA STATE
            </Typography>
            <Typography sx={{ fontSize: 12 }} fontWeight={600}>
              8th Floor, New Excelsior Building, A.K.Nayak Marg, Fort,
              Mumbai-400001. (M.S.)
            </Typography>
            {/* <Typography variant="p" sx={{ marginTop: 1 }} fontWeight={600}>
          Preview Form for Online Registration For{" "}
            {coursename || coursenameVal}
          </Typography> */}
            {/* <Typography>APPLICATION FORM</Typography> */}
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "flex-end", width: "100px" }}
          >
            <img
              style={{ height: "100px", width: "auto" }}
              src={araImg}
              alt="image"
            />
          </Grid>
        </Grid>
        <Box sx={{ borderBottom: "1px solid #000" }}>
          {location.search.split("&&")[0] === "?page=printapplication" &&
            applicantid !== "" && (
              <Typography
                variant="h6"
                sx={{
                  textWrap: "wrap",
                  fontWeight: "bold",
                  color: "#000",
                  textAlign: "center",
                }}
              >
                Application No : {applicantid} {"  "}
                Version No : {courseFormDetails[0]?.versionno}
              </Typography>
            )}
        </Box>
        <Grid container sx={{ width: "100%" }}>
          <Grid item xs={12}>
            <Box
              sx={{
                borderBottom: "1px solid #000",
                display: "flex",
                justifyContent: "space-between",
                paddingX: 1,
              }}
            >
              <Box>
                <Typography variant="p" sx={{ fontSize: 18 }}>
                  Personal Details
                </Typography>
              </Box>

              {location.search !== "?page=printapplication" &&
                !edit &&
                !print && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      color: "#3F41D1",
                    }}
                    onClick={() =>
                      navigate(
                        `/${Cookies.get(
                          "mhet_cnd_project"
                        )}/course-registration?page=personalinformation`,
                        {
                          state: {
                            courseid: courseid,
                            coursename: coursename,
                            status: status >= 5 ? 1 : 0,
                          },
                        }
                      )
                    }
                  >
                    <MdOutlineModeEdit size={20} /> &nbsp;
                    <Typography sx={{ fontSize: 18 }}>Edit</Typography>
                  </Box>
                )}
            </Box>

            <Grid container xs={12} sx={{ minWidth: "700px" }}>
              <Grid item md={7} sx={{ width: "450px" }}>
                <Grid container xs={12}>
                  <Grid
                    item
                    md={7}
                    sx={{ border: "1px solid #000", width: "250px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      {" "}
                      Candidate’s Full name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{ border: "1px solid #000", width: "200px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {personalDetails[0]?.candidatename}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={7}
                    sx={{ border: "1px solid #000", width: "250px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      {" "}
                      Father’s Full Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{ border: "1px solid #000", width: "200px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.fathersname}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={7}
                    sx={{ border: "1px solid #000", width: "250px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      {" "}
                      Mother’s Full Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{ border: "1px solid #000", width: "200px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.mothername}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    md={7}
                    sx={{ border: "1px solid #000", width: "250px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      {" "}
                      Gender
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{ border: "1px solid #000", width: "200px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.gender}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    md={7}
                    sx={{ border: "1px solid #000", width: "250px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      {" "}
                      Marital Status
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{ border: "1px solid #000", width: "200px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(courseFormDetails[0]?.maritalstatusid) === 1
                        ? "Married"
                        : parseInt(courseFormDetails[0]?.maritalstatusid) === 2
                        ? "Unmarried"
                        : "-"}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    md={7}
                    sx={{ border: "1px solid #000", width: "250px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      {" "}
                      Spouse Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{ border: "1px solid #000", width: "200px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails?.length > 0 &&
                        (courseFormDetails[0]?.spousename || "-")}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    md={7}
                    sx={{ border: "1px solid #000", width: "250px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      {" "}
                      Date of Birth
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{ border: "1px solid #000", width: "200px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {personalDetails[0]?.dob &&
                        dayjs(personalDetails[0]?.dob).format("DD-MM-YYYY")}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    md={7}
                    sx={{ border: "1px solid #000", width: "250px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      {" "}
                      Religion
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{ border: "1px solid #000", width: "200px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.religion}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={7}
                    sx={{ border: "1px solid #000", width: "250px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      {" "}
                      Region
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{ border: "1px solid #000", width: "200px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.region}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    md={7}
                    sx={{ border: "1px solid #000", width: "250px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      {" "}
                      Mother Tongue
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{ border: "1px solid #000", width: "200px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.mothertongue}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    md={7}
                    sx={{ border: "1px solid #000", width: "250px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      {" "}
                      Annual Family Income
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{ border: "1px solid #000", width: "200px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.incomerange}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    md={7}
                    sx={{ border: "1px solid #000", width: "250px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      {" "}
                      Nationality
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={5}
                    sx={{ border: "1px solid #000", width: "200px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.nationality}
                    </Typography>
                  </Grid>

                  {location.pathname + location.search ===
                    "/mhcet/course-registration?page=printapplication" && (
                    <>
                      {" "}
                      <Grid
                        item
                        md={7}
                        sx={{ border: "1px solid #000", width: "250px" }}
                      >
                        <Typography
                          sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                        >
                          {" "}
                          {coursename || coursenameVal} Application Fee Paid
                          (&#x20B9;)
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={5}
                        sx={{ border: "1px solid #000", width: "200px" }}
                      >
                        <Typography
                          sx={{
                            fontSize: 12,
                            px: 1,
                            textAlign: "left",
                            color: "black",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          {courseFormDetails[0]?.amount} /-
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {/* <Grid
                  item
                  md={7}
                  sx={{ border: "1px solid #000", width: "250px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    {coursename || coursenameVal} Application Fee Paid
                    (&#x20B9;)
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={5}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.amount} /-
                  </Typography>
                </Grid> */}
                </Grid>
              </Grid>
              {/* <Grid item md={5} sx={{ border: "1px solid #000", width: "250px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img
                  style={{ width: "120px", height: "auto",borderBottom:"1" }}
                  //   src={
                  //     courseUploadDetails
                  //       ? courseUploadDetails?.find(
                  //           (item, index) => item?.documenttype == 1
                  //         )?.fileurl
                  //       : ""
                  //   }

                  src={
                    courseUploadDetails?.length
                      ? courseUploadDetails?.find(
                          (item) => item?.documenttype == 1
                        )?.fileurl
                      : uploadDetails?.find((item) => item?.documenttype == 1)
                          ?.fileurl
                  }
                  alt="photo"
                />

                <img
                  style={{ width: "120px", height: "auto" }}
                  //   src={
                  //     courseUploadDetails
                  //       ? courseUploadDetails?.find(
                  //           (item, index) => item?.documenttype == 2
                  //         )?.fileurl
                  //       : ""
                  //   }

                  src={
                    courseUploadDetails?.length
                      ? courseUploadDetails?.find(
                          (item, index) => item?.documenttype == 2
                        )?.fileurl
                      : uploadDetails?.find(
                          (item, index) => item?.documenttype == 2
                        )?.fileurl
                  }
                  alt="signatute"
                />
              </Box>
            </Grid> */}
              <Grid
                item
                md={5}
                sx={{ border: "1px solid #000", width: "250px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      borderBottom: "3px solid #000",
                      paddingBottom: "8px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {/* {fileUrl && (
  <img
    style={{ width: "120px", height: "auto" }}
    src={fileUrl}
    alt="photo"
  />
)} */}

                    {/* {fileUrl && showImage && ( */}
                      <>
                        <img
      style={{
        width: "120px",
        height: "auto",
        // aspectRatio: "4 / 3",
        // display: isImageLoaded ? "block" : "none",
      }}
      src={fileUrl}
      alt="signature"
    />
                        
                      </>
                    {/* )} */}
                  </Box>

                  <Box
                    sx={{
                      paddingTop: "10px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {/* {fileUrl2 && showImage && ( */}
                      <>
                        <img
                    style={{ width: "120px", height: "auto" }}
                    src={
                      courseUploadDetails?.length
                        ? courseUploadDetails?.find(
                            (item) => item?.documenttype == 2
                          )?.fileurl
                        : uploadDetails?.find((item) => item?.documenttype == 2)
                            ?.fileurl
                    }
                    alt="signature"
                    // onLoad={() => setIsImageLoaded(true)}

                  />
               
                                         
                      </>
                    {/* )} */}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={6}>
              <Box
                sx={{
                  //   height: "48px",
                  //   backgroundColor: "#EDEDED",
                  display: "flex",
                  //   alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #000",
                  // width: "348px",
                  // borderRadius: "8px 8px 0px 0px",
                  paddingX: 1,
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: 18 }}>
                    Permanent Address
                  </Typography>
                </Box>
                {location.search !== "?page=printapplication" &&
                  !edit &&
                  !print && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#3F41D1",
                      }}
                      onClick={() =>
                        navigate(
                          `/${Cookies.get(
                            "mhet_cnd_project"
                          )}/course-registration?page=address`,
                          {
                            state: {
                              courseid: courseid,
                              coursename: coursename,
                              status: status >= 5 ? 1 : 0,
                            },
                          }
                        )
                      }
                    >
                      <MdOutlineModeEdit size={20} /> &nbsp;
                      <Typography sx={{ fontSize: 18 }}>Edit</Typography>
                    </Box>
                  )}
              </Box>
              <Grid container md={12}>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "148px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    Address Line 1
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.paddressline1}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container md={12}>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "148px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Address Line 2
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.paddressline2}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container md={12}>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "148px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Address Line 3
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails?.length > 0 &&
                      (courseFormDetails[0]?.paddressline3 || "-----")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container md={12}>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    State
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.pstate}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    District
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.pdistrict}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container md={12}>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Taluka
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.ptaluka}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Village
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.pvillage}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container md={12}>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Pin code
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "274px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.ppincode}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  border: "1px solid #000",
                  // width: "350px",
                  display: "flex",
                  justifyContent: "space-between",
                  paddingX: 1,
                  // borderRadius: "8px 8px 0px 0px",
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: 18 }}>
                    Address for Correspondence
                  </Typography>
                </Box>
                {location.search !== "?page=printapplication" &&
                  !edit &&
                  !print && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#3F41D1",
                      }}
                      onClick={() =>
                        navigate(
                          `/${Cookies.get(
                            "mhet_cnd_project"
                          )}/course-registration?page=address`,
                          {
                            state: {
                              courseid: courseid,
                              coursename: coursename,
                              status: status >= 5 ? 1 : 0,
                            },
                          }
                        )
                      }
                    >
                      <MdOutlineModeEdit size={20} /> &nbsp;
                      <Typography sx={{ fontSize: 18 }}>Edit</Typography>
                    </Box>
                  )}
              </Box>
              <Grid container md={12}>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "148px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    {" "}
                    Address Line 1
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.caddressline1}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container md={12}>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "148px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Address Line 2
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.caddressline2}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container md={12}>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "148px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Address Line 3
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "200px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails?.length > 0 &&
                      (courseFormDetails[0]?.caddressline3 || "-----")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container md={12}>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    State
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.cstate}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    District
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.cdistrict === null
                      ? "Not Applicable"
                      : courseFormDetails[0]?.cdistrict}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container md={12}>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Taluka
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.ctaluka === null
                      ? "Not Applicable"
                      : courseFormDetails[0]?.ctaluka}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Village
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "100px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.cvillage === null
                      ? "Not Applicable"
                      : courseFormDetails[0]?.cvillage}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container md={12}>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "74px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Pin code
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "274px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.cpincode}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} sx={{ border: "1px solid #000" }}>
              <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                Telephone No
              </Typography>
            </Grid>
            <Grid item md={6} sx={{ border: "1px solid #000" }}>
              <Typography
                sx={{
                  fontSize: 12,
                  px: 1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                }}
              >
                {courseFormDetails[0]?.phoneno}
              </Typography>
            </Grid>
            <Grid item md={6} sx={{ border: "1px solid #000" }}>
              <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                STD code
              </Typography>
            </Grid>
            <Grid item md={6} sx={{ border: "1px solid #000" }}>
              <Box sx={{ width: "346px" }}>
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {courseFormDetails[0]?.stdcode}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                border: "1px solid #000",
                display: "flex",
                justifyContent: "space-between",
                paddingX: 1,
              }}
            >
              <Box>
                <Typography sx={{ fontSize: 18 }}>
                  Domicile and Category Details
                </Typography>
              </Box>

              {location.search !== "?page=printapplication" &&
                !edit &&
                !print && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      color: "#3F41D1",
                    }}
                    onClick={() =>
                      navigate(
                        `/${Cookies.get(
                          "mhet_cnd_project"
                        )}/course-registration?page=domicileandcategorydetails`,
                        {
                          state: {
                            courseid: courseid,
                            coursename: coursename,
                            status: status >= 5 ? 1 : 0,
                          },
                        }
                      )
                    }
                  >
                    <MdOutlineModeEdit size={20} /> &nbsp;
                    <Typography sx={{ fontSize: 18 }}>Edit</Typography>
                  </Box>
                )}
            </Box>
            <Grid container md={12}>
              {courseFormDetails[0]?.ismhcandidate && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Are You Domiciled In The State Of Maharastra
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(courseFormDetails[0]?.ismhcandidate) == 1
                        ? "Yes"
                        : parseInt(courseFormDetails[0]?.ismhcandidate) == 0
                        ? "No"
                        : "----"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.category && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Category
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.category}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.castename && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Caste Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.castename}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.caste && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Caste Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.caste}
                    </Typography>
                  </Grid>
                </>
              )}
              {courseFormDetails[0]?.castecertificatestatus && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Do You Possess Caste Certificate
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(
                        courseFormDetails[0]?.castecertificatestatus
                      ) === 1
                        ? "Available"
                        : parseInt(
                            courseFormDetails[0]?.castecertificatestatus
                          ) === 2
                        ? "Applied But Not Received"
                        : parseInt(
                            courseFormDetails[0]?.castecertificatestatus
                          ) === 3
                        ? "Not Applied"
                        : "-----"}
                    </Typography>
                  </Grid>
                </>
              )}

              {((courseFormDetails[0]?.castevaliditystatus !== null &&
                courseFormDetails[0]?.castevaliditystatus !== undefined &&
                courseFormDetails[0]?.castevaliditystatus !== "") ||
                courseFormDetails[0]?.castevaliditystatus === 0) && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Do You Possess Caste/Tribe Validity Certificate
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(courseFormDetails[0]?.castevaliditystatus) === 1
                        ? "Available"
                        : parseInt(
                            courseFormDetails[0]?.castevaliditystatus
                          ) === 2
                        ? "Applied But Not Received"
                        : parseInt(
                            courseFormDetails[0]?.castevaliditystatus
                          ) === 0
                        ? "Not Applied"
                        : "-----"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.castevaliditycertificateno && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Caste Validity Certificate No
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.castevaliditycertificateno}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.cvcapplicationno && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Caste/Tribe Validity Certificate Application Number
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.cvcapplicationno}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.cvcapplicationdate && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Caste/Tribe Validity Certificate Application Date
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.cvcapplicationdate}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.cvcauthority && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Caste/Tribe Validity Certificate Issuing Authority Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.cvcauthority}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.cvcdistrict && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Caste/Tribe Validity Certificate Issuing District
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.cvcdistrict}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.cvcname && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Candidate Name As Per Caste Certificate
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.cvcname}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.castecertificateno && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Caste Certificate/Receipt No
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.castecertificateno}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.castecertificatereceiptno && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Caste Certificate Receipt No
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.castecertificatereceiptno}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.castecertificateappdate && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Caste Certificate Application Date
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.castecertificateappdate}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.appliedforews &&
                courseFormDetails[0]?.appliedforews != 0 && (
                  <>
                    <Grid
                      item
                      md={3}
                      xs={3}
                      sx={{ border: "1px solid #000", width: "74px" }}
                    >
                      <Typography
                        sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                      >
                        Do You Want To Apply For EWS
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      xs={3}
                      sx={{ border: "1px solid #000", width: "100px" }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          px: 1,
                          textAlign: "left",
                          color: "black",
                          fontWeight: 500,
                          textAlign: "left",
                        }}
                      >
                        {parseInt(courseFormDetails[0]?.appliedforews) === 1
                          ? "Yes"
                          : parseInt(courseFormDetails[0]?.appliedforews) == 0
                          ? "No"
                          : "----"}
                      </Typography>
                    </Grid>
                  </>
                )}

              {courseFormDetails[0]?.ewscertificatestatus !== null &&
                (courseFormDetails[0]?.ewscertificatestatus === 0 ||
                  courseFormDetails[0]?.ewscertificatestatus === 1 ||
                  courseFormDetails[0]?.ewscertificatestatus === 2) && (
                  <>
                    <Grid
                      item
                      md={3}
                      xs={3}
                      sx={{ border: "1px solid #000", width: "74px" }}
                    >
                      <Typography
                        sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                      >
                        EWS Certificate
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      xs={3}
                      sx={{ border: "1px solid #000", width: "100px" }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          px: 1,
                          textAlign: "left",
                          color: "black",
                          fontWeight: 500,
                          textAlign: "left",
                        }}
                      >
                        {parseInt(
                          courseFormDetails[0]?.ewscertificatestatus
                        ) === 1
                          ? "Available"
                          : parseInt(
                              courseFormDetails[0]?.ewscertificatestatus
                            ) === 2
                          ? "Not Available"
                          : parseInt(
                              courseFormDetails[0]?.ewscertificatestatus
                            ) === 0
                          ? "Applied But Not Received"
                          : "----"}
                      </Typography>
                    </Grid>
                  </>
                )}

              {courseFormDetails[0]?.ewsapplicationno && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      EWS Certificate Application Number
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.ewsapplicationno}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.ewsapplicationdate && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      EWS Certificate Application Date
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.ewsapplicationdate}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.ewsdistrict && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      EWS Certificate Issuing District
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.ewsdistrict}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.ewstaluka && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      EWS Certificate Issuing Taluka
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.ewstaluka}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.nclstatus && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Do You Have Non Creamy Layer Certificate
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(courseFormDetails[0]?.nclstatus) === 1
                        ? "Available"
                        : parseInt(courseFormDetails[0]?.nclstatus) === 2
                        ? "Applied But Not Received"
                        : parseInt(courseFormDetails[0]?.nclstatus) === 3
                        ? "Not Applied"
                        : "-----"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.nclapplicationno && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Non Creamy Layer Certificate Application Number
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.nclapplicationno}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.nclapplicationdate && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Non Creamy Layer Certificate Application Date
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.nclapplicationdate}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.nclauthority && (
                <>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "74px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "right",
                        textWrap: "wrap",
                      }}
                    >
                      Non Creamy Layer Certificate Issuing Authority Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ border: "1px solid #000", width: "100px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.nclauthority}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
            {/* {(courseFormDetails[0]?.isminority ||
              courseFormDetails[0]?.linguisticminority ||
              courseFormDetails[0]?.religiousminority) && (
              <>
                <Box
                  sx={{
                    border: "1px solid #000",
                    marginTop: ".1rem",
                    width: "100%",
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: 18 }}>
                      Minority Information
                    </Typography>
                  </Box>
                </Box>
              </>
            )} */}
            <Grid container md={12}>
              {courseFormDetails[0]?.isdisability && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Are You Person With Disability
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(courseFormDetails[0]?.isdisability) == 1
                        ? "Yes"
                        : parseInt(courseFormDetails[0]?.isdisability) == 0
                        ? "No"
                        : "---"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.pwdtype &&
                courseFormDetails[0]?.isdisability != 0 && (
                  <>
                    <Grid
                      item
                      md={6}
                      sx={{ border: "1px solid #000", width: "348px" }}
                    >
                      <Typography
                        sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                      >
                        Type Of Disability
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sx={{ border: "1px solid #000", width: "348px" }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          px: 1,
                          textAlign: "left",
                          color: "black",
                          fontWeight: 500,
                          textAlign: "left",
                        }}
                      >
                        {courseFormDetails[0]?.pwdtype}
                      </Typography>
                    </Grid>
                  </>
                )}

              {courseFormDetails[0]?.pwdsubtype &&
                courseFormDetails[0]?.isdisability != 0 && (
                  <>
                    <Grid
                      item
                      md={6}
                      sx={{ border: "1px solid #000", width: "348px" }}
                    >
                      <Typography
                        sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                      >
                        PWD Sub Type
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sx={{ border: "1px solid #000", width: "348px" }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          px: 1,
                          textAlign: "left",
                          color: "black",
                          fontWeight: 500,
                          textAlign: "left",
                        }}
                      >
                        {courseFormDetails[0]?.pwdsubtype}
                      </Typography>
                    </Grid>
                  </>
                )}

              {courseFormDetails[0]?.isextratime &&
                courseFormDetails[0]?.isdisability != 0 && (
                  <>
                    <Grid
                      item
                      md={6}
                      sx={{ border: "1px solid #000", width: "348px" }}
                    >
                      <Typography
                        sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                      >
                        Require Extra Time
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sx={{ border: "1px solid #000", width: "348px" }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          px: 1,
                          textAlign: "left",
                          color: "black",
                          fontWeight: 500,
                          textAlign: "left",
                        }}
                      >
                        {parseInt(courseFormDetails[0]?.isextratime) == 1
                          ? "Yes"
                          : parseInt(courseFormDetails[0]?.isextratime) == 0
                          ? "No"
                          : "----"}
                      </Typography>
                    </Grid>
                  </>
                )}

              {courseFormDetails[0]?.isscribe &&
                courseFormDetails[0]?.isdisability != 0 && (
                  <>
                    <Grid
                      item
                      md={6}
                      sx={{ border: "1px solid #000", width: "348px" }}
                    >
                      <Typography
                        sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                      >
                        Require Scribe
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sx={{ border: "1px solid #000", width: "348px" }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          px: 1,
                          textAlign: "left",
                          color: "black",
                          fontWeight: 500,
                          textAlign: "left",
                        }}
                      >
                        {parseInt(courseFormDetails[0]?.isscribe) == 1
                          ? "Yes"
                          : parseInt(courseFormDetails[0]?.isscribe) == 0
                          ? "No"
                          : "----"}
                      </Typography>
                    </Grid>
                  </>
                )}
            </Grid>
            <Grid container md={12}>
              {courseFormDetails[0]?.isminority && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Do You Belong To Minority Category
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(courseFormDetails[0]?.isminority) === 1
                        ? "Yes"
                        : parseInt(courseFormDetails[0]?.isminority) === 0
                        ? "No"
                        : "----"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.linguisticminority && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Linguistic Minority
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.linguisticminority}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.religiousminority && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Religious Minority
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.religiousminority}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>

            {(courseFormDetails[0]?.isorphan ||
              courseFormDetails[0]?.orphantype) && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    // border: "1px solid #000",
                    // marginTop: ".1rem",
                    width: "100%",
                    paddingX: 1,
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: 18 }}>
                      Orphan Information
                    </Typography>
                  </Box>

                  {/* {(location.search !== "?page=printapplication" && !edit && !print) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/course-registration?page=domicileandcategorydetails`,
                    {
                      state: {
                        courseid: courseid,
                        coursename: coursename,
                        status: status >= 5 ? 1 : 0,
                      },
                    }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )} */}
                </Box>
              </>
            )}
            <Grid container md={12}>
              {courseFormDetails[0]?.isorphan && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Are You Orphan
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(courseFormDetails[0]?.isorphan) == 1
                        ? "Yes"
                        : parseInt(courseFormDetails[0]?.isorphan) == 0
                        ? "No"
                        : "----"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.orphantype && (
                <>
                  <Grid item md={6} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "right",
                        width: "348px",
                      }}
                    >
                      Orphan Type
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.orphantype}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid container md={12}>
              {masterFields?.find(
                (field) => field.masterfieldlabel === masterFieldLabel.IS_SPORT
              ) && (
                // (courseFormDetails[0]?.issports ||
                //   courseFormDetails[0]?.sportsparticipation ||
                //   courseFormDetails[0]?.sportsparticipationyear ||
                //   courseFormDetails[0]?.sportsrank ||
                //   courseFormDetails[0]?.sports ||
                //   courseFormDetails[0]?.sportsbody ||
                //   courseFormDetails[0]?.isphysicaleducation ||
                //   courseFormDetails[0]?.isteachingexperience ||
                //   courseFormDetails[0]?.isappliedforelct ||
                //   courseFormDetails[0]?.isappliedforksu ||
                //   courseFormDetails[0]?.isappliedforspecialeducation ||
                //   courseFormDetails[0]?.specialeducationmethods)

                <>
                  <Box
                    sx={{
                      // border: "1px solid #000",
                      // marginTop: ".1rem",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      paddingX: 1,
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontSize: 18 }}>
                        Sports information
                      </Typography>
                    </Box>

                    {/* 
                  {(location.search !== "?page=printapplication" && !edit && !print) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "#3F41D1",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/course-registration?page=domicileandcategorydetails`,
                    {
                      state: {
                        courseid: courseid,
                        coursename: coursename,
                        status: status >= 5 ? 1 : 0,
                      },
                    }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )} */}
                  </Box>

                  <Grid container md={12}>
                    {courseFormDetails[0]?.issports && (
                      <>
                        <Grid
                          item
                          md={6}
                          xs={6}
                          sx={{ border: "1px solid #000", width: "74px" }}
                        >
                          <Typography
                            sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                          >
                            Is Sports
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={6}
                          xs={6}
                          sx={{ border: "1px solid #000", width: "100px" }}
                        >
                          <Typography
                            sx={{
                              fontSize: 12,
                              px: 1,
                              textAlign: "left",
                              color: "black",
                              fontWeight: 500,
                              textAlign: "left",
                            }}
                          >
                            {parseInt(courseFormDetails[0]?.issports) === 1
                              ? "Yes"
                              : "No"}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    {courseFormDetails[0]?.sportsparticipation &&
                      courseFormDetails[0]?.issports != 0 && (
                        <>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "74px" }}
                          >
                            <Typography
                              sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                            >
                              Sports Participation Level
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "100px" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 12,
                                px: 1,
                                textAlign: "left",
                                color: "black",
                                fontWeight: 500,
                                textAlign: "left",
                              }}
                            >
                              {courseFormDetails[0]?.sportsparticipation}
                            </Typography>
                          </Grid>
                        </>
                      )}

                    {courseFormDetails[0]?.sportsparticipationyear &&
                      courseFormDetails[0]?.issports != 0 && (
                        <>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "74px" }}
                          >
                            <Typography
                              sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                            >
                              Sports Participation Year
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "100px" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 12,
                                px: 1,
                                textAlign: "left",
                                color: "black",
                                fontWeight: 500,
                                textAlign: "left",
                              }}
                            >
                              {courseFormDetails[0]?.sportsparticipationyear}
                            </Typography>
                          </Grid>
                        </>
                      )}

                    {courseFormDetails[0]?.sportsrank &&
                      courseFormDetails[0]?.issports != 0 && (
                        <>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "74px" }}
                          >
                            <Typography
                              sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                            >
                              Sports Rank
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "100px" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 12,
                                px: 1,
                                textAlign: "left",
                                color: "black",
                                fontWeight: 500,
                                textAlign: "left",
                              }}
                            >
                              {courseFormDetails[0]?.sportsrank}
                            </Typography>
                          </Grid>
                        </>
                      )}

                    {courseFormDetails[0]?.sports &&
                      courseFormDetails[0]?.issports != 0 && (
                        <>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "74px" }}
                          >
                            <Typography
                              sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                            >
                              Sports Name
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "100px" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 12,
                                px: 1,
                                textAlign: "left",
                                color: "black",
                                fontWeight: 500,
                                textAlign: "left",
                              }}
                            >
                              {courseFormDetails[0]?.sports}
                            </Typography>
                          </Grid>
                        </>
                      )}

                    {courseFormDetails[0]?.sportsbody &&
                      courseFormDetails[0]?.issports != 0 && (
                        <>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "74px" }}
                          >
                            <Typography
                              sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                            >
                              Sports Body
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "100px" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 12,
                                px: 1,
                                textAlign: "left",
                                color: "black",
                                fontWeight: 500,
                                textAlign: "left",
                              }}
                            >
                              {courseFormDetails[0]?.sportsbody}
                            </Typography>
                          </Grid>
                        </>
                      )}

                    {courseFormDetails[0]?.isphysicaleducation &&
                      courseFormDetails[0]?.issports != 0 && (
                        <>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "74px" }}
                          >
                            <Typography
                              sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                            >
                              Is Studied Physical Education
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "100px" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 12,
                                px: 1,
                                textAlign: "left",
                                color: "black",
                                fontWeight: 500,
                                textAlign: "left",
                              }}
                            >
                              {parseInt(
                                courseFormDetails[0]?.isphysicaleducation
                              ) == 1
                                ? "Yes"
                                : parseInt(
                                    courseFormDetails[0]?.isphysicaleducation
                                  ) == 0
                                ? "No"
                                : "----"}
                            </Typography>
                          </Grid>
                        </>
                      )}

                    {courseFormDetails[0]?.isteachingexperience &&
                      courseFormDetails[0]?.issports != 0 && (
                        <>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "74px" }}
                          >
                            <Typography
                              sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                            >
                              Is Teaching Experience
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "100px" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 12,
                                px: 1,
                                textAlign: "left",
                                color: "black",
                              }}
                            >
                              {parseInt(
                                courseFormDetails[0]?.isteachingexperience
                              ) == 1
                                ? "Yes"
                                : parseInt(
                                    courseFormDetails[0]?.isteachingexperience
                                  ) == 0
                                ? "No"
                                : "----"}
                            </Typography>
                          </Grid>
                        </>
                      )}

                    {courseFormDetails[0]?.isappliedforelct &&
                      courseFormDetails[0]?.issports != 0 && (
                        <>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "74px" }}
                          >
                            <Typography
                              sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                            >
                              Is Applied For ELCT
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 12,
                                px: 1,
                                textAlign: "left",
                                color: "black",
                              }}
                            >
                              {parseInt(
                                courseFormDetails[0]?.isappliedforelct
                              ) == 1
                                ? "Yes"
                                : parseInt(
                                    courseFormDetails[0]?.isappliedforelct
                                  ) == 0
                                ? "No"
                                : "----"}
                            </Typography>
                          </Grid>
                        </>
                      )}

                    {courseFormDetails[0]?.isappliedforksu &&
                      courseFormDetails[0]?.issports != 0 && (
                        <>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "74px" }}
                          >
                            <Typography
                              sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                            >
                              Is Applied For KSU
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 12,
                                px: 1,
                                textAlign: "left",
                                color: "black",
                              }}
                            >
                              {parseInt(
                                courseFormDetails[0]?.isappliedforksu
                              ) == 1
                                ? "Yes"
                                : parseInt(
                                    courseFormDetails[0]?.isappliedforksu
                                  ) == 0
                                ? "No"
                                : "----"}
                            </Typography>
                          </Grid>
                        </>
                      )}

                    {courseFormDetails[0]?.isappliedforspecialeducation &&
                      courseFormDetails[0]?.issports != 0 && (
                        <>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "74px" }}
                          >
                            <Typography
                              sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                            >
                              Is Applied For Special Education
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "100px" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 12,
                                px: 1,
                                textAlign: "left",
                                color: "black",
                              }}
                            >
                              {parseInt(
                                courseFormDetails[0]
                                  ?.isappliedforspecialeducation
                              ) == 1
                                ? "Yes"
                                : parseInt(
                                    courseFormDetails[0]
                                      ?.isappliedforspecialeducation
                                  ) == 0
                                ? "No"
                                : "----"}
                            </Typography>
                          </Grid>
                        </>
                      )}

                    {courseFormDetails[0]?.specialeducationmethods &&
                      courseFormDetails[0]?.issports != 0 && (
                        <>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "74px" }}
                          >
                            <Typography
                              sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                            >
                              Special Education Methods
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            md={6}
                            xs={6}
                            sx={{ border: "1px solid #000", width: "100px" }}
                          >
                            <Typography
                              sx={{
                                fontSize: 12,
                                px: 1,
                                textAlign: "left",
                                color: "black",
                              }}
                            >
                              {parseInt(
                                courseFormDetails[0]?.specialeducationmethods
                              ) == 1
                                ? "Yes"
                                : parseInt(
                                    courseFormDetails[0]
                                      ?.specialeducationmethods
                                  ) == 0
                                ? "No"
                                : "----"}
                            </Typography>
                          </Grid>
                        </>
                      )}
                  </Grid>
                </>
              )}
              <Box
                sx={{
                  border: "1px solid #000",
                  marginTop: ".1rem",
                  width: "100%",
                }}
              >
                {/* <Box>
                <Typography sx={{ fontSize: 18 }}>
                  Category information
                </Typography>
              </Box> */}
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {(courseFormDetails[0]?.sscpassedfromindia ||
              courseFormDetails[0]?.sscschoolstate ||
              courseFormDetails[0]?.sscschooldistrict ||
              courseFormDetails[0]?.sscschooltaluka ||
              courseFormDetails[0]?.sscpassingyear ||
              courseFormDetails[0]?.sscboard ||
              courseFormDetails[0]?.sscschoolname ||
              courseFormDetails[0]?.ssctotalpercentage) && (
              <>
                <Box
                  sx={{
                    border: "1px solid #000",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingX: 1,
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: 18 }}>
                      Qualification Details
                    </Typography>
                  </Box>

                  {location.search !== "?page=printapplication" &&
                    !edit &&
                    !print && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          color: "#3F41D1",
                        }}
                        onClick={() =>
                          navigate(
                            `/${Cookies.get(
                              "mhet_cnd_project"
                            )}/course-registration?page=qualificationdetails`,
                            {
                              state: {
                                courseid: courseid,
                                coursename: coursename,
                                status: status >= 5 ? 1 : 0,
                              },
                            }
                          )
                        }
                      >
                        <MdOutlineModeEdit size={20} /> &nbsp;
                        <Typography sx={{ fontSize: 18 }}>Edit</Typography>
                      </Box>
                    )}
                </Box>
                <Box
                  sx={{
                    border: "1px solid #000",
                    marginTop: ".1rem",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingX: 1,
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: 18 }}>
                      SSC Information
                    </Typography>
                  </Box>
                  {/* {(location.search !== "?page=printapplication" && !edit && !print) && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#3F41D1",
                      }}
                      onClick={() =>
                        navigate(
                          `/${Cookies.get(
                            "mhet_cnd_project"
                          )}/course-registration?page=qualificationdetails`,
                          {
                            state: {
                              courseid: courseid,
                              coursename: coursename,
                              status: status >= 5 ? 1 : 0,
                            },
                          }
                        )
                      }
                    >
                      <MdOutlineModeEdit size={20} /> &nbsp;
                      <Typography sx={{ fontSize: 18 }}>Edit</Typography>
                    </Box>
                  )} */}
                </Box>
              </>
            )}

            <Grid container md={12}>
              {courseFormDetails[0]?.sscpassedfromindia && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      SSC Passed From India
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.sscpassedfromindia == 1
                        ? "Yes"
                        : courseFormDetails[0]?.sscpassedfromindia == 0
                        ? "No"
                        : "---"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.sscschoolstate && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      SSC School State
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.sscschoolstate}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.sscschooldistrict && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      SSC School District
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.sscschooldistrict}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.sscschooltaluka && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      SSC School Taluka
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.sscschooltaluka}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.sscpassingyear && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      SSC/Equivalent Passing Year
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.sscpassingyear}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.sscpassingmonth && (
                <>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      SSC/Equivalent Passing Month
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {
                        months.find(
                          (obj) =>
                            obj.value == courseFormDetails[0]?.sscpassingmonth
                        ).label
                      }
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.sscseatno && (
                <>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      SSC/Equivalent Seat Number
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.sscseatno}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.sscclass && (
                <>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      SSC/Equivalent Class
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {/* {courseFormDetails[0]?.sscclass} */}
                      {
                        educationClass?.find(
                          (obj) =>
                            obj.educationclassid ==
                            courseFormDetails[0]?.sscclass
                        )?.educationclass
                      }
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.sscboard && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      SSC/Equivalent Board
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.sscboard}
                    </Typography>
                  </Grid>
                </>
              )}
              {courseFormDetails[0]?.sscotherboardname &&
                courseFormDetails[0]?.sscboard == "Any Other Board" && (
                  <>
                    <Grid
                      item
                      md={3}
                      xs={3}
                      p={1}
                      sx={{ border: "1px solid #000" }}
                    >
                      <Typography
                        sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                      >
                        SSC/Equivalent other board name
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      p={1}
                      xs={3}
                      sx={{ border: "1px solid #000" }}
                    >
                      <Typography
                        sx={{
                          fontSize: 12,
                          textAlign: "left",
                          color: "black",
                          fontWeight: 500,
                          textAlign: "left",
                        }}
                      >
                        {courseFormDetails[0]?.sscotherboardname}
                      </Typography>
                    </Grid>
                  </>
                )}
              {courseFormDetails[0]?.sscschoolname && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      SSC School Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.sscschoolname}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.ssctotalpercentage && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      SSC/Equivalent Total Percentage
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.ssctotalpercentage}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
            {(courseFormDetails[0]?.hsccollegestate ||
              courseFormDetails[0]?.hsccollegedistrict ||
              courseFormDetails[0]?.hsccollegetaluka ||
              courseFormDetails[0]?.hsccollegename ||
              courseFormDetails[0]?.hscmarktype ||
              courseFormDetails[0]?.hscmarksobtained ||
              courseFormDetails[0]?.hscmarksoutof ||
              courseFormDetails[0]?.hscpercentage ||
              courseFormDetails[0]?.hscenglishmarksobtained ||
              courseFormDetails[0]?.hscenglishmarksoutof ||
              courseFormDetails[0]?.hscenglishpercentage ||
              courseFormDetails[0]?.hscpassingyear ||
              courseFormDetails[0]?.hscpassingstatus ||
              courseFormDetails[0]?.hscboard) && (
              <Box
                sx={{
                  // border: "1px solid #000",
                  marginTop: ".1rem",
                  paddingX: 1,
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: 18 }}>HSC Information</Typography>
                </Box>
              </Box>
            )}
            <Grid container md={12}>
              {courseFormDetails[0]?.hscpassingstatus && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Are you Appearing /Appeared 12th (HSC) exam in 2025{" "}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.hscpassingstatus == 1
                        ? "Yes"
                        : "No"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hsccollegestate && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      HSC College State
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.hsccollegestate}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hsccollegedistrict && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      HSC College District
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.hsccollegedistrict}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hsccollegetaluka && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      HSC College Taluka
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.hsccollegetaluka}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hscpassingyear && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      HSC Passing Year
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.hscpassingyear}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hscpassingmonth && (
                <>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      HSC Passing Month
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {
                        months.find(
                          (obj) =>
                            obj.value == courseFormDetails[0]?.hscpassingmonth
                        ).label
                      }
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hscstream && (
                <>
                  <Grid
                    item
                    md={3}
                    px={1}
                    xs={3}
                    sx={{ border: "1px solid #000" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      HSC Stream
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    px={1}
                    sx={{ border: "1px solid #000" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {/* {courseFormDetails[0]?.hscstream} */}
                      {
                        educationStream?.find(
                          (obj) =>
                            obj.educationstreamid ==
                            courseFormDetails[0]?.hscstream
                        )?.educationstream
                      }
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hscseatno && (
                <>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      HSC Seat Number
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.hscseatno}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hscclass && (
                <>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      HSC Class
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {/* {courseFormDetails[0]?.hscclass} */}
                      {
                        educationClass?.find(
                          (obj) =>
                            obj.educationclassid ==
                            courseFormDetails[0]?.hscclass
                        )?.educationclass
                      }
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hscboard && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      HSC Board
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.hscboard}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hsccollegename && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      HSC College Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.hsccollegename}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hscmarktype && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Marks Type
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(courseFormDetails[0]?.hscmarktype) === 1
                        ? "CGPA"
                        : parseInt(courseFormDetails[0]?.hscmarktype) === 2
                        ? "Percentage"
                        : "-----"}
                    </Typography>
                  </Grid>
                </>
              )}
              {courseFormDetails[0]?.hscmarksobtained && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      HSC Marks Obtained
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.hscmarksobtained}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hscmarksoutof && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      HSC Marks Out Of
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.hscmarksoutof}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hscpercentage && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      HSC Percentage
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.hscpercentage}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hscenglishmarksobtained && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      HSC English Marks Obtained
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.hscenglishmarksobtained}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hscenglishmarksoutof && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      HSC English Marks Out Of
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.hscenglishmarksoutof}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.hscenglishpercentage && (
                <>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      HSC English Percentage
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={3}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.hscenglishpercentage}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>

            {(courseFormDetails[0]?.eligibilityqualification ||
              courseFormDetails[0]?.graduationstatus ||
              courseFormDetails[0]?.graduationpassingyear ||
              courseFormDetails[0]?.graduationbranch ||
              courseFormDetails[0]?.graduationmarktype ||
              courseFormDetails[0]?.graduationmarksobtained ||
              courseFormDetails[0]?.graduationmarksoutof ||
              courseFormDetails[0]?.graduationpercentage ||
              courseFormDetails[0]?.awardedclass ||
              courseFormDetails[0]?.gradudationduration ||
              courseFormDetails[0]?.guniversityname) && (
              <Box
                sx={{
                  // border: "1px solid #000",
                  marginTop: ".1rem",
                  paddingX: 1,
                }}
              >
                <Box>
                  <Typography sx={{ fontSize: 18 }}>
                    Graduation Information
                  </Typography>
                </Box>
              </Box>
            )}
            <Grid container md={12}>
              {courseFormDetails[0]?.eligibilityqualification && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Eligiblity Qualification
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.eligibilityqualification}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.graduationstatus && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Graduation Status
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.graduationstatus == 1
                        ? "Appearing"
                        : courseFormDetails[0]?.graduationstatus == 2
                        ? "Passed"
                        : courseFormDetails[0]?.graduationstatus == 0
                        ? "Not Applicable"
                        : "---"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.graduationpassingyear && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Graduation Passing Year
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.graduationpassingyear}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.graduationbranch && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Graduation Branch Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.graduationbranch}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.graduationmarktype && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Graduation Marks Type
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {parseInt(courseFormDetails[0]?.graduationmarktype) == 1
                        ? "Percentage"
                        : parseInt(courseFormDetails[0]?.graduationmarktype) ===
                          2
                        ? "CGPA"
                        : "-----"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.graduationmarksobtained && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Graduation Marks Obtained
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.graduationmarksobtained}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.graduationmarksoutof && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Graduation Marks Out Of
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.graduationmarksoutof}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.graduationpercentage && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Graduation Percentage
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.graduationpercentage}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.awardedclass && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Awarded Class Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.awardedclass}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.gradudationduration && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Graduation Duration
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.gradudationduration}
                    </Typography>
                  </Grid>
                </>
              )}
              {courseFormDetails[0]?.guniversityname && (
                <>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      University Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.guniversityname}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.gotherdegreename && (
                <>
                  <Grid
                    item
                    md={6}
                    xs={6}
                    p={1}
                    sx={{ border: "1px solid #000" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      Graduation Other Degree Name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={6}
                    p={1}
                    sx={{ border: "1px solid #000" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.gotherdegreename}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.gotheruniversityname && (
                <>
                  <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, textAlign: "left", color: "#000" }}
                    >
                      Graduation Other University Name
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.gotheruniversityname}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.gpassingmonth && (
                <>
                  <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      Graduation Other Passing Month
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={6}
                    p={1}
                    sx={{ border: "1px solid #000" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {/* {courseFormDetails[0]?.gpassingmonth} */}
                      {
                        months.find(
                          (obj) =>
                            obj.value == courseFormDetails[0]?.gpassingmonth
                        ).label
                      }
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.gspecialsubject && (
                <>
                  <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      Graduation Specialization or Subject
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={6}
                    px={1}
                    sx={{ border: "1px solid #000" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {/* {courseFormDetails[0]?.gspecialsubject.length} */}
                      {options
                        .filter((obj) =>
                          courseFormDetails[0]?.gspecialsubject?.includes(
                            obj.value
                          )
                        )
                        ?.map((val, i) => (
                          <Typography
                            key={i}
                            sx={{
                              fontSize: 12,
                              textAlign: "left",
                              color: "black",
                              fontWeight: 500,
                              textAlign: "left",
                            }}
                          >
                            {/* {console.log(options.filter(obj=> courseFormDetails[0]?.gspecialsubject?.includes(obj.value) ),'gspecialsubject')} */}
                            {options.filter((obj) =>
                              courseFormDetails[0]?.gspecialsubject?.includes(
                                obj.value
                              )
                            ).length > 1 && `${i + 1}.`}
                            {val.label}
                            {/* {val.toString()} */}
                          </Typography>
                        ))}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.gmathssubject && (
                <>
                  <Grid
                    item
                    md={6}
                    xs={6}
                    px={1}
                    sx={{ border: "1px solid #000" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      Graduation Mathematics Subject
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={6}
                    px={1}
                    sx={{ border: "1px solid #000" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.gmathssubject == 1 ? "Yes" : "No"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.gexampassbefore1975 && (
                <>
                  <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      Graduation Exam Pass Before 1975
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={6}
                    p={1}
                    sx={{ border: "1px solid #000" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {/* {courseFormDetails[0]?.gexampassbefore1975} */}
                      {courseFormDetails[0]?.gexampassbefore1975 == 1
                        ? "Yes"
                        : "No"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.gengmediumcollege && (
                <>
                  <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      Graduation English Medium College
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={6}
                    px={1}
                    sx={{ border: "1px solid #000" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {/* {courseFormDetails[0]?.gengmediumcollege} */}
                      {courseFormDetails[0]?.gengmediumcollege == 1
                        ? "Yes"
                        : "No"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.genglangcontenttest && (
                <>
                  <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      Graduation English Language Contest Test
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    xs={6}
                    px={1}
                    sx={{ border: "1px solid #000" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {/* {courseFormDetails[0]?.genglangcontenttest} */}
                      {courseFormDetails[0]?.genglangcontenttest == 1
                        ? "Yes"
                        : "No"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.goptspecialedcollege && (
                <>
                  <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      Graduation Optional special Ed College
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {/* {courseFormDetails[0]?.goptspecialedcollege} */}
                      {courseFormDetails[0]?.goptspecialedcollege == 1
                        ? "Yes"
                        : "No"}
                    </Typography>
                  </Grid>
                </>
              )}

              {courseFormDetails[0]?.gspecialeducationmethod && (
                <>
                  <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, textAlign: "right", color: "#000" }}
                    >
                      Graduation Optional special Ed Methods / Subjects
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                    {/* <Typography
                    sx={{
                      fontSize: 15,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.gspecialeducationmethod}
                  </Typography> */}
                    {options
                      .filter((obj) =>
                        courseFormDetails[0]?.gspecialeducationmethod?.includes(
                          obj.value
                        )
                      )
                      ?.map((val, i) => (
                        <Typography
                          key={i}
                          sx={{
                            fontSize: 12,
                            textAlign: "left",
                            color: "black",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          {/* {console.log(months.filter(obj=> courseFormDetails[0]?.gspecialeducationmethod?.includes(obj.value) ),'gspecialeducationmethod')} */}
                          {options.filter((obj) =>
                            courseFormDetails[0]?.gspecialeducationmethod?.includes(
                              obj.value
                            )
                          ).length > 1 && `${i + 1}.`}
                          {val.label}
                          {/* {val.toString()} */}
                        </Typography>
                      ))}
                  </Grid>
                </>
              )}
            </Grid>

            {(courseFormDetails[0]?.councilregappno ||
              courseFormDetails[0]?.applicationdate ||
              courseFormDetails[0]?.pgpedupreference1 ||
              courseFormDetails[0]?.pgpedupreference2 ||
              courseFormDetails[0]?.pgpedupreference3) && (
              <>
                <Box
                  sx={{
                    // border: "1px solid #000",
                    marginTop: ".1rem",
                    paddingX: 1,
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: 18 }}>
                      Post Graduation Information
                    </Typography>
                  </Box>
                </Box>

                <Grid container md={12}>
                  {/* {courseFormDetails[0]?.eligibilityqualification && (
              <> */}
                  {/* <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  Document For Type A
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                 Document Of
</Typography>
                </Grid> */}
                  {/* </>
            )} */}

                  {/* {courseFormDetails[0]?.graduationstatus && (
              <> */}
                  {/* <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  CET Exam Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                  -
                  </Typography>
                </Grid> */}
                  {/* </>
            )} */}

                  {/* {courseFormDetails[0]?.graduationpassingyear && (
              <> */}
                  {/* <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                                Whether Pass Institution Situated MH?
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
-                  </Typography>
                </Grid> */}
                  {/* </>
            )} */}

                  {/* {courseFormDetails[0]?.graduationbranch && (
              <> */}
                  {/* <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  PF Graduation Date
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    -
                   </Typography>
                </Grid> */}
                  {/* </>
            )} */}

                  {/* {courseFormDetails[0]?.graduationmarktype && (
              <> */}
                  {/* <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  Internship Training Date                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                  -
                  </Typography>
                </Grid> */}
                  {/* </>
            )} */}

                  {/* {courseFormDetails[0]?.graduationmarksobtained && (
              <> */}
                  {/* <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  MH Council Registration
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    -
                  </Typography>
                </Grid> */}
                  {/* </>
            )} */}
                  {/* {courseFormDetails[0]?.graduationmarksobtained && (
              <> */}
                  {/* <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  Re Council Registration
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    -
                  </Typography>
                </Grid> */}
                  {/* </>
            )} */}
                  {/* {courseFormDetails[0]?.graduationmarksobtained && (
              <> */}
                  {/* <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                  CouncilRegAppNo                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sx={{ border: "1px solid #000", width: "348px" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                      textAlign: "left",
                    }}
                  >
                    {courseFormDetails[0]?.councilregappno}
                  </Typography>
                </Grid> */}
                  {/* </>
            )} */}
                  {/* {courseFormDetails[0]?.graduationmarksobtained && (
              <> */}
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      ApplicationDate{" "}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.applicationdate}
                    </Typography>
                  </Grid>
                  {/* </>
            )} */}
                  {/* {courseFormDetails[0]?.graduationmarksobtained && (
              <> */}
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Education Preference 1{" "}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {
                        PGPPreference?.find(
                          (item) =>
                            item?.value ==
                            courseFormDetails[0]?.pgpedupreference1
                        )?.label
                      }
                    </Typography>
                  </Grid>
                  {/* </>
            )} */}
                  {/* {courseFormDetails[0]?.graduationmarksobtained && (
              <> */}
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Education Preference 2{" "}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {
                        PGPPreference?.find(
                          (item) =>
                            item?.value ==
                            courseFormDetails[0]?.pgpedupreference2
                        )?.label
                      }
                    </Typography>
                  </Grid>
                  {/* </>
            )} */}
                  {/* {courseFormDetails[0]?.graduationmarksobtained && (
              <> */}
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Education Preference 3{" "}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{ border: "1px solid #000", width: "348px" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {
                        PGPPreference?.find(
                          (item) =>
                            item?.value ==
                            courseFormDetails[0]?.pgpedupreference3
                        )?.label
                      }
                    </Typography>
                  </Grid>
                  {/* </>
            )} */}
                </Grid>
              </>
            )}

            {(courseFormDetails[0]?.subjectgroup ||
              courseFormDetails[0]?.examstate ||
              courseFormDetails[0]?.languageid ||
              courseFormDetails[0]?.preference1district ||
              courseFormDetails[0]?.preference2district ||
              courseFormDetails[0]?.preference3district ||
              courseFormDetails[0]?.preference4district) && (
              <>
                <Box
                  sx={{
                    border: "1px solid #000",
                    display: "flex",
                    justifyContent: "space-between",
                    paddingX: 1,
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: 18 }}>
                      Exam Center Selection Details
                    </Typography>
                  </Box>
                  {location.search !== "?page=printapplication" && !print && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        color: "#3F41D1",
                      }}
                      onClick={() =>
                        navigate(
                          `/${Cookies.get(
                            "mhet_cnd_project"
                          )}/course-registration?page=examinationdetails`,
                          {
                            state: {
                              courseid: courseid,
                              coursename: coursename,
                              status: status >= 5 ? 1 : 0,
                            },
                          }
                        )
                      }
                    >
                      <MdOutlineModeEdit size={20} /> &nbsp;
                      <Typography sx={{ fontSize: 18 }}>Edit</Typography>
                    </Box>
                  )}
                </Box>
              </>
            )}
            {courseFormDetails[0]?.subjectgroup && (
              <>
                <Grid container md={12}>
                  <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Subject Group for Exam
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.subjectgroup}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.examstate && (
              <>
                <Grid container md={12}>
                  <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Examination Center State
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.examstate?.map((val, i) => (
                        <Typography
                          key={i}
                          sx={{
                            fontSize: 15,
                            textAlign: "left",
                            color: "black",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          {courseFormDetails[0]?.examstate.length > 1 &&
                            `${i + 1}.`}
                          {val}
                        </Typography>
                      ))}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            {courseFormDetails[0]?.languageid && (
              <Grid container md={12}>
                {/* Left Column */}
                <Grid
                  item
                  md={6}
                  xs={6}
                  p={1}
                  sx={{ border: "1px solid #000" }}
                >
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Language / Medium of the Question Paper
                  </Typography>
                </Grid>

                {/* Right Column */}
                <Grid
                  item
                  md={6}
                  p={1}
                  xs={6}
                  sx={{ border: "1px solid #000" }}
                >
                  <Typography
                    sx={{
                      fontSize: 12,
                      px: 1,
                      textAlign: "left",
                      color: "black",
                      fontWeight: 500,
                    }}
                  >
                    {/* Get the label from the Language array */}
                    {Language.find(
                      (obj) => obj.value === courseFormDetails[0]?.languageid
                    )?.label || "Language not found"}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {/* {courseFormDetails[0]?.language && (
            <>
              <Grid container md={12}>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  <Typography sx={{ fontSize: 12, px: 1, textAlign: "right" }}>
                    Language / Medium of the Question Paper
                  </Typography>
                </Grid>
                <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
                  {courseFormDetails[0]?.language?.map((val, i) => (
                    <Typography
                      key={i}
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.language.length > 1 && `${i + 1}.`}
                      {val}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            </>
          )} */}

            {courseFormDetails[0]?.preference1state && (
              <>
                <Grid container md={12}>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Examination Centre State at Preference Number 1{" "}
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.preference1state}
                    </Typography>
                  </Grid>

                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Examination Center District at Preference Number 1
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.preference1district}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
            <>
              <Grid container md={6}></Grid>
            </>

            {courseFormDetails[0]?.preference2state && (
              <>
                <Grid container md={12}>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Examination Centre State at Preference Number 2
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.preference2state}
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Examination Center District at Preference Number 2
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.preference2district}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.preference3state && (
              <>
                <Grid container md={12}>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Examination Centre State at Preference Number 3{" "}
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.preference3state}
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Examination Center District at Preference Number 3
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails[0]?.preference3district}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}

            {courseFormDetails[0]?.preference4state && (
              <>
                <Grid container md={12}>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Examination Centre State at Preference Number 4{" "}
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails?.length > 0 &&
                        (courseFormDetails[0]?.preference4state || "-----")}
                    </Typography>
                  </Grid>

                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{ fontSize: 12, px: 1, textAlign: "right" }}
                    >
                      Examination Center District at Preference Number 4
                    </Typography>
                  </Grid>
                  <Grid item md={3} xs={3} sx={{ border: "1px solid #000" }}>
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {courseFormDetails?.length > 0 &&
                        (courseFormDetails[0]?.preference4district || "-----")}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}

            {/* <Box
            
            sx={{
              border:'1px solid #000',
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Post Graduation Information</Typography>
            </Box>
          </Box> */}
            {/* <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Document For Type A
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Document Of
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                CET Exam Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Whether Pass Institution Situated MH?
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
             <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                PF Graduation Date
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Internship Training Date
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                MH Council Registration
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Re Council Registration
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
             <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Already Admitted
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
                      <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                College Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
                      <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                University Name
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
                      <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid> */}
            {/* <Box
            
            sx={{
              border:'1px solid #000',
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>First Year Marks</Typography>
            </Box>
          </Box> */}
            {/* <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FY Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FY No Of Attempts
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid> */}
            {/* <Box
            
            sx={{
              border:'1px solid #000',
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Second Year Marks</Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                SY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                SY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                SY Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                SY No Of Attempts
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid>
          <Box
            
            sx={{
              border:'1px solid #000',
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Third Year Marks</Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                TY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                TY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                TY Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                TY No Of Attempts
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid>
          <Box
            
            sx={{
              border:'1px solid #000',
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Fourth Year Marks</Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FourY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FourY Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FourY Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                FourY No Of Attempts
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid>
          <Box
            
            sx={{
              border:'1px solid #000',
              marginTop:".1rem"
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>Aggregate Marks</Typography>
            </Box>
          </Box>
          <Grid container md={12}>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Aggregate Marks Obtained
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Aggregate Marks Out Of
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              xs={12}
              
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{ fontSize: 12,
                px:1, textAlign: "left",  }}
              >
                Aggregate Percentage
              </Typography>
            </Grid>
            <Grid
              item
              md={3}
              
              xs={12}
              sx={{ border: "1px solid #000" }}
            >
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "left",
                }}
              >
                
              </Typography>
            </Grid>
          </Grid> */}
          </Grid>

          {/* <Grid item md={12} xs={12}>
          <Box
            sx={{
              border: "1px solid #000",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 18 }}>
                Photograph and Signature
              </Typography>
            </Box>
            {location.search !== "?page=printapplication" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() =>
                  navigate(
                    `/${Cookies.get(
                      "mhet_cnd_project"
                    )}/registration?page=uploads`,
                    {
                      state: {
                        courseid: courseid,
                        coursename: coursename,
                        status: status >= 5 ? 1 : 0,
                      },
                    }
                  )
                }
              >
                <MdOutlineModeEdit size={20} /> &nbsp;
                <Typography sx={{ fontSize: 18 }}>Edit</Typography>
              </Box>
            )}
          </Box>
          <Grid container md={12}>
            <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "center",
                  color: "black",
                  fontWeight: 500,
                }}
              >
                Photograph
              </Typography>
            </Grid>
            <Grid item md={6} xs={6} sx={{ border: "1px solid #000" }}>
              <Typography
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",
                  color: "black",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Signature
              </Typography>
            </Grid>
          </Grid>
          <Grid container md={12}>
            <Grid
              item
              md={6}
              xs={6}
              sx={{
                border: "1px solid #000",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "140px", width: "auto" }}
                src={uploadDetails[0]?.document}
                alt="photo"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={6}
              sx={{
                border: "1px solid #000",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "53px", width: "auto" }}
                src={uploadDetails[1]?.document}
                alt="signatute"
              />
            </Grid>
          </Grid>
        </Grid>
*/}
          <Grid item md={12} xs={12}>
            <Grid
              container
              // md={12}
              // xs={12}

              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingX: 1,
              }}
            >
              <Box>
                <Typography sx={{ fontSize: 18 }}>Document Uploaded</Typography>
              </Box>

              {location.search !== "?page=printapplication" &&
                !edit &&
                !print && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      color: "#3F41D1",
                    }}
                    onClick={() =>
                      navigate(
                        `/${Cookies.get(
                          "mhet_cnd_project"
                        )}/registration?page=uploads`,
                        {
                          state: {
                            courseid: courseid,
                            coursename: coursename,
                            status: status >= 5 ? 1 : 0,
                            fromPreview: true,
                          },
                        }
                      )
                    }
                  >
                    <MdOutlineModeEdit size={20} /> &nbsp;
                    <Typography sx={{ fontSize: 18 }}>Edit</Typography>
                  </Box>
                )}
            </Grid>
            <Grid container md={12}>
              <Grid item md={2} xs={2} sx={{ border: "1px solid #000" }}>
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "center",

                    fontWeight: 500,
                  }}
                >
                  S.No
                </Typography>
              </Grid>
              <Grid item md={10} xs={10} sx={{ border: "1px solid #000" }}>
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Document Name
                </Typography>
              </Grid>
            </Grid>
            <Grid container md={12}>
              <Grid item md={2} xs={2} sx={{ border: "1px solid #000" }}>
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "center",
                    color: "black",
                    fontWeight: 500,
                  }}
                >
                  1
                </Typography>
              </Grid>
              <Grid
                item
                md={10}
                xs={10}
                sx={{
                  border: "1px solid #000",
                  display: "flex",
                  justifyContent: "space-between",
                  paddingX: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  {uploadDetails[0]?.documentname}
                </Typography>
                {!print &&
                  location.pathname + location.search ==
                    "/mhcet/course-registration?page=preview" && (
                    <Box
                      component="a"
                      sx={{
                        fontSize: 15,
                        textAlign: "left",
                        color: "#3F41D1",
                        fontWeight: 500,
                        textAlign: "center",
                        cursor: "pointer",
                        ":hover": {
                          textDecoration: "underline",
                        },
                      }}
                      target="_blank"
                      href={
                        uploadDetails
                          ? uploadDetails?.find(
                              (item, index) => item?.documenttype == 3
                            )?.fileurl
                          : ""
                      }
                    >
                      View
                    </Box>
                  )}
              </Grid>

              {(location.pathname + location.search ===
                "/mhcet/course-registration?page=preview" ||
                edit) && (
                <>
                  <Grid
                    item
                    md={12}
                    p={1}
                    xs={12}
                    sx={{ border: "1px solid #000" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      Note :
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    p={1}
                    xs={12}
                    sx={{ border: "1px solid #f7f7f7" }}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        textAlign: "left",
                        color: "red",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      {/* *Candidate Shall Carry Disability Certificate at the time of
                    Examination.( For All PWD Candidate) */}
                      *Candidate to note the districts given in the options may
                      not be available necessarily. If the Infrastructure is not
                      available for conducting computer based Test in the First
                      District. In such a case, the candidate will be shifted to
                      the district of the next choice, and the allotted district
                      will be mentioned in the Admit Card
                    </Typography>
                  </Grid>
                  {
                    <Grid
                      item
                      md={12}
                      p={1}
                      xs={12}
                      sx={{
                        border: "1px solid #F7F7F7",
                        borderRadius: "0px 0px 8px 8px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "8px",
                          justifyContent: "space-between",
                          paddingX: 1,
                        }}
                      >
                        {!print && (
                          // <Box
                          //   sx={{
                          //     display: "flex",
                          //     alignItems: "flex-end",
                          //     gap: 1,

                          //   }}
                          // >
                          //   <Checkbox
                          //     onChange={handleCheckbox}
                          //     checked={CheckboxValue}
                          //     sx={{ margin: 0, padding: 0 }}
                          //   />
                          //   <Typography
                          //     sx={{
                          //       fontSize: 15,
                          //       textAlign: "left",
                          //       color: "red",
                          //       fontWeight: 500,
                          //       textAlign: "left",
                          //     }}
                          //   >
                          //     I confirm that I have read and understood the
                          //     application instructions and reviewed all details
                          //     provided. I acknowledge that no changes can be made
                          //     once the fee is paid
                          //   </Typography>
                          // </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              gap: 1,
                            }}
                          >
                            <Checkbox
                              onChange={handleCheckbox}
                              checked={CheckboxValue}
                              sx={{ margin: 0, padding: 0 }}
                            />
                            <Typography
                              sx={{
                                fontSize: 15,
                                textAlign: "left",
                                color: "red",
                                fontWeight: 500,
                                lineHeight: 1.5,
                              }}
                            >
                              I confirm that I have read and understood the
                              application instructions and reviewed all details
                              provided. I acknowledge that no changes can be
                              made once the fee is paid.
                            </Typography>
                          </Box>
                        )}

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <img
                            style={{
                              width: "120px",
                              height: "auto",
                              aspectRatio: "4/3",
                            }}
                            src={
                              courseUploadDetails?.length
                                ? courseUploadDetails?.find(
                                    (item, index) => item?.documenttype == 2
                                  )?.fileurl
                                : uploadDetails?.find(
                                    (item, index) => item?.documenttype == 2
                                  )?.fileurl
                            }
                            alt="signatute"
                          />
                          <Typography
                            sx={{
                              fontSize: 15,
                              textAlign: "left",
                              color: "red",
                              fontWeight: 500,
                              textAlign: "left",
                            }}
                          >
                            Signature of candidate
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  }
                  {error && (
                    <small style={{ marginTop: ".5rem" }} className="error">
                      {error}
                    </small>
                  )}
                </>
              )}

              {location.pathname + location.search ===
                "/mhcet/course-registration?page=printapplication" && (
                <Grid item md={12} xs={12} sx={{ border: "1px solid #000" }}>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box>
                      <Typography sx={{ fontSize: 12, px: 1, fontWeight: 600 }}>
                        Note :
                      </Typography>
                      <List
                        sx={{
                          listStyleType: "disc",
                          pl: 3,
                          fontSize: 12,
                          color: "#e6393f",
                        }}
                      >
                        {/* <ListItem sx={{ display: "list-item" }}>
                  You are required to Upload the Caste Certificate clearly
                  mentioning the Category of the Candidate and also the remarks
                  that the Caste is recognised as backward class in the State of
                  Maharashtra at the time of Centralised Admission Process(CAP
                  2024).
                </ListItem>

                <ListItem sx={{ display: "list-item" }}>
                  You are required to Upload the Caste / Tribe Validity
                  Certificate in the name of the Candidate, issued by the caste
                  validity committee of Maharashtra State at the time of
                  Centralised Admission Process(CAP 2024).
                </ListItem> */}

                        <ListItem sx={{ display: "list-item" }}>
                          Candidate to note the districts given in the options
                          may not be available necessarily. If the
                          Infrastructure is not available for conducting
                          computer based Test in the First District. In such a
                          case, the candidate will be shifted to the district of
                          the next choice, and the allotted district will be
                          mentioned in the Admit Card
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      border: "1px solid #000",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 15,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 600,
                        textAlign: "left",
                      }}
                    >
                      Declaration:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 12,
                        px: 1,
                        textAlign: "left",
                        color: "black",
                        fontWeight: 500,
                        textAlign: "left",
                      }}
                    >
                      I {courseFormDetails[0]?.candidatename} have downloaded
                      the Information Brochure of {coursename} and have read all
                      the rules for appearing the CET and on understanding these
                      Rules, I have filled this Application Form for appearing
                      to {coursename} for the Academic Year 2024-25. This
                      information given by me in this application is true to the
                      best of my knowledge & belief. If at later stage, it is
                      found that I have furnished wrong informatation and/or
                      submitted false certificate(s), I am aware that my
                      admission stands cancelled and fees paid by me will be
                      forfeited. Further I will be subject to legal and/or penal
                      action as per the provisions of the law.
                    </Typography>
                  </Box>
                  <Grid container sx={{ width: "100%" }}>
                    <Grid item md={8} xs={8}>
                      <Box
                        sx={{
                          border: "1px solid #000",

                          width: "398px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 12,
                            px: 1,
                            textAlign: "left",
                            color: "black",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Date :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {dayjs().format("DD-MM-YYYY")}
                          </span>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          border: "1px solid #000",

                          width: "398px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 12,
                            px: 1,
                            textAlign: "left",
                            color: "black",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Last Modified By :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {courseFormDetails[0]?.ipaddress}
                          </span>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          border: "1px solid #000",

                          width: "398px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 12,
                            px: 1,
                            textAlign: "left",
                            color: "black",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Last Modified On :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {courseFormDetails[0]?.updateddate}
                          </span>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          border: "1px solid #000",

                          width: "398px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 12,
                            px: 1,
                            textAlign: "left",
                            color: "black",
                            fontWeight: 500,
                            textAlign: "left",
                          }}
                        >
                          Printed On :{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}
                          </span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={4} xs={4}>
                      <Box
                        sx={{
                          border: "1px solid #000",
                          display: "grid",
                          placeItems: "center",
                          width: "298px",
                          height: "100%",
                        }}
                      >
                        <img
                          style={{
                            width: "75px",
                            height: "auto",
                            aspectRatio: "4/3",
                          }}
                          src={
                            courseUploadDetails
                              ? courseUploadDetails?.find(
                                  (item, index) => item?.documenttype == 2
                                )?.fileurl
                              : ""
                          }
                          alt="signatute"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {(location.pathname + location.search ===
                "/mhcet/course-registration?page=preview" ||
                edit) && (
                <Grid
                  item
                  md={12}
                  pt={2}
                  sx={{
                    display: "flex",
                    gap: "8px",
                    justifyContent: "flex-end",
                    flexDirection: isMobile ? "column" : null,
                    padding: 1,
                  }}
                >
                  {!print && (
                    <MauvelousPrimaryButton
                      title={
                        buttonLoader
                          ? "loader"
                          : isExtraFee
                          ? "Continue to payment"
                          : "Continue"
                      }
                      handleClick={
                        courseList?.all?.length > 0 &&
                        courseList?.all?.find((obj) => obj.courseid == courseid)
                          ?.isotbs == true &&
                        new Date(currentOtbsVenue[0]?.validtime) <= new Date()
                          ? () => {
                              navigate(
                                `/${Cookies.get(
                                  "mhet_cnd_project"
                                )}/course-registration?page=examinationdetails`,
                                {
                                  state: {
                                    courseid: courseid,
                                    coursename: coursename,
                                    status: status >= 5 ? 1 : 0,
                                  },
                                }
                              );
                            }
                          : handleProcced
                      }
                      // disabled={(edit ? false : true || !CheckboxValue || isPaid === "1")}
                    />
                  )}
                </Grid>
              )}
              {/* <Grid
              item
              md={6}
              xs={6}
              sx={{
                border: "1px solid #000",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                component="a"
                sx={{
                  fontSize: 12,
                  px:1,
                  textAlign: "left",

                  fontWeight: 500,
                  textAlign: "center",
                  cursor: "pointer",
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
                target="_blank"
                href={pdfUrl}
              >
                View
              </Box>
            </Grid> */}
            </Grid>
          </Grid>
          {/* <Grid item md={12} xs={12} sx={{ border: "1px solid #000" }}>
          <Box
            sx={{
              width: "696px",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: 12, px: 1, fontWeight: 600 }}>
                Note :
              </Typography>
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 3,
                  fontSize: 12,
                  color: "#e6393f",
                }}
              >
                <ListItem sx={{ display: "list-item" }}>
                  You are required to Upload the Caste Certificate clearly
                  mentioning the Category of the Candidate and also the remarks
                  that the Caste is recognised as backward class in the State of
                  Maharashtra at the time of Centralised Admission Process(CAP
                  2024).
                </ListItem>

                <ListItem sx={{ display: "list-item" }}>
                  You are required to Upload the Caste / Tribe Validity
                  Certificate in the name of the Candidate, issued by the caste
                  validity committee of Maharashtra State at the time of
                  Centralised Admission Process(CAP 2024).
                </ListItem>
              </List>
            </Box>
          </Box>
          <Box
            sx={{
              border: "1px solid #000",
              width: "696px",
            }}
          >
            <Typography
              sx={{
                fontSize: 15,
                px: 1,
                textAlign: "left",
                color: "black",
                fontWeight: 600,
                textAlign: "left",
              }}
            >
              Declaration:
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                px: 1,
                textAlign: "left",
                color: "black",
                fontWeight: 500,
                textAlign: "left",
              }}
            >
              I {courseFormDetails[0]?.candidatename} have downloaded the
              Information Brochure of {coursename} and have read all the rules
              for appearing the CET and on understanding these Rules, I have
              filled this Application Form for appearing to {coursename} for the
              Academic Year 2024-25. This information given by me in this
              application is true to the best of my knowledge & belief. If at
              later stage, it is found that I have furnished wrong informatation
              and/or submitted false certificate(s), I am aware that my
              admission stands cancelled and fees paid by me will be forfeited.
              Further I will be subject to legal and/or penal action as per the
              provisions of the law.
            </Typography>
          </Box>
          <Grid container sx={{ width: "598px" }}>
            <Grid item md={8} xs={8}>
              <Box
                sx={{
                  border: "1px solid #000",

                  width: "398px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  Date :{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {dayjs().format("DD-MM-YYYY")}
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "1px solid #000",

                  width: "398px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  Last Modified By :{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {courseFormDetails[0]?.ipaddress}
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "1px solid #000",

                  width: "398px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  Last Modified On :{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {courseFormDetails[0]?.updateddate}
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "1px solid #000",

                  width: "398px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 12,
                    px: 1,
                    textAlign: "left",
                    color: "black",
                    fontWeight: 500,
                    textAlign: "left",
                  }}
                >
                  Printed On :{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")}
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} xs={4}>
              <Box
                sx={{
                  border: "1px solid #000",
                  display: "grid",
                  placeItems: "center",
                  width: "298px",
                  height: "100%",
                }}
              >
                <img
                  style={{ width: "75px", height: "auto", aspectRatio: "4/3" }}
                  src={
                    courseUploadDetails
                      ? courseUploadDetails?.find(
                          (item, index) => item?.documenttype == 2
                        )?.fileurl
                      : ""
                  }
                  alt="signatute"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid> */}
        </Grid>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: 0,
          left: 0,
          // backgroundColor: '#21212173',
          // backgroundColor: `${cssProperties?.backgroundcolor?.secondary}`,
          zIndex: 9999,
        }}
      >
        <CircularProgress
          sx={{ color: `${cssProperties?.color?.primary}` }}
          size={70}
          thickness={4.5}
        />
      </Box>
    );
  }
}

// import React, { useEffect, useRef, useState } from "react";
// import {
//   Box,
//   Checkbox,
//   Grid,
//   Typography,
//   useMediaQuery,
//   useTheme,
// } from "@mui/material";
// import { MdOutlineModeEdit } from "react-icons/md";
// import { useDispatch, useSelector } from "react-redux";
// import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import Cookies from "js-cookie";
// import candidatesActions from "../../redux/candidates/actions";
// import masterActions from "../../redux/master/action";
// import dayjs from "dayjs";
// import authActions from "../../redux/auth/actions";
// import MauvelousPrimaryButton from "../../common/button/mauvelousPrimaryButton";
// import GovernmentImage from "../../assets/images/courseRegistration/Government.png";
// import CetImage from "../../assets/images/courseRegistration/Cet.png";
// import { DecryptObjectFunction, EncryptObjectFunction } from "../../utils/cryptoFunction";

// export default function PreviewForm({ targetRef, courseId }) {
//   const [CheckboxValue, setCheckBoxValue] = useState(false);
//   const [error, setError] = useState("");
//   const dispatch = useDispatch();
//   const theme = useTheme();
//   const location = useLocation();
//   const courseid = location.state?.courseid;
//   const coursename = location.state?.coursename;
//   const [searchParams] = useSearchParams();
//   const encryptedData = searchParams.get('c_data');
//   const decrypted = DecryptObjectFunction(encryptedData);
//   const courseIdVal = decrypted?.courseid;
//   const edit = decrypted?.edit;
//   const isExtraFee = decrypted?.isExtraFee;
//   const courseNameVal = decrypted?.coursename;
//   let subjectgroup = decrypted?.subjectgroup

//   console.log(isExtraFee, 'isExtraFee');

//   const PGPPreference = [
//     { value: 1, label: "Engineering" },
//     { value: 2, label: "Pharmacy" },
//     { value: 3, label: "Agriculture" },
//   ];
//   const Language = [
//     { value: 1, label: "English" },
//     { value: 2, label: "Marati" },
//     { value: 3, label: "Urdu" },
//     { value: 4, label: "Hindhi" },
//   ];
//   const months = [
//     { value: 1, label: "January" },
//     { value: 2, label: "February" },
//     { value: 3, label: "March" },
//     { value: 4, label: "April" },
//     { value: 5, label: "May" },
//     { value: 6, label: "June" },
//     { value: 7, label: "July" },
//     { value: 8, label: "August" },
//     { value: 9, label: "September" },
//     { value: 10, label: "October" },
//     { value: 11, label: "November" },
//     { value: 12, label: "December" },
//   ];
//   const options = [
//     { value: 1, label: "Option 1" },
//     { value: 2, label: "Option 2" },
//     { value: 3, label: "Option 3" },
//     { value: 4, label: "Option 4" },
//     { value: 5, label: "Option 5" },

//   ];
//   const navigate = useNavigate();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const { buttonLoader } = useSelector((state) => state.commonReducer);
//   const candidateid = useSelector(
//     (state) => state.authReducer.candidateTokenDetails?.candidateid
//   );

//   const { courseFormDetails, personalDetails, examState,courseList,otbsVenueDetails,currentOtbsVenue } = useSelector(
//     (state) => state.candidatesReducer
//   );

//   const applicantid = useSelector(
//     (state) => state.candidatesReducer.applicantid.cnd_getapplicantid_bycourseid
//   );
//   const {
//     board,
//     educationClass,
//     educationStream,
//   } = useSelector((state) => state.masterReducer);

//   // console.log(months.filter(obj=> console.log(typeof obj.value,'gspecialsubject',courseFormDetails[0]?.gspecialsubject) ),'gspecialsubject')
//   // console.log(examState,'examState');

//   useEffect(() => {
//     dispatch({ type: authActions.CANDIDATE_TOKEN_VERIFY });
//     dispatch({ type: masterActions.GET_EDUCATION_CLASS });
//     dispatch({ type: masterActions.GET_EDUCATION_STREAM });

//   }, []);
//   // console.log(courseFormDetails,otbsVenueDetails, "courseFormDetails,otbsVenueDetails");
//   console.log(new Date(currentOtbsVenue[0]?.validtime) >= new Date(),new Date(currentOtbsVenue[0]?.validtime) , new Date() ,currentOtbsVenue[0]?.validtime,'new Date(currentOtbsVenue[0]?.validtime) >= new Date()')

//   // console.log(otbsVenueDetails?.Venues?.find(obj=>obj.venueid == courseFormDetails[0]?.venueid),'otbsVenueDetails?.Venues?.find(obj=>obj.venueid == courseFormDetails[0]?.venueid)')

//   useEffect(() => {
//     if (
//       courseFormDetails.length > 0 &&
//       courseList?.all?.length > 0 &&
//       courseList?.all?.find((obj) => obj.courseid == courseid)?.isotbs == true
//     ) {
//       dispatch({
//         type: candidatesActions.GET_CURRENT_VENUE_DETAILS,
//         payload: { courseid :courseid,candidateid: candidateid},
//       });
//     }
//   }, [courseList,courseid, courseFormDetails,candidateid]);

//   const { uploadDetails, courseUploadDetails } = useSelector((state) => state.candidatesReducer);

//   useEffect(() => {
//     setTimeout(() => {
//       dispatch({
//         type: candidatesActions.GET_COURSE_UPLOAD_PERSONAL_DETAILS,
//         data: {
//           courseid: courseFormDetails
//             ? courseFormDetails[0]?.courseid || courseid
//             : null,
//         },
//       });
//     }, 1000);
//   }, [courseFormDetails])

//   console.log('uploadDetails----', board);
//   console.log('courseFormDetails----', courseFormDetails);
//   const status = courseFormDetails[0]?.status;
//   // const isPaid = courseFormDetails[0]?.ispaid;

//   const handleProcced = () => {
//     if (CheckboxValue && edit) {
//       let encryptedParams = {
//         courseid: courseIdVal,
//         isExtraFee: isExtraFee,
//         coursename: courseNameVal,
//         edit: edit,
//         subjectgroup: subjectgroup
//       }
//       navigate(isExtraFee ? `/${Cookies.get('mhet_cnd_project')}/course-registration?page=applicationfee&&c_data=${EncryptObjectFunction(encryptedParams)}` : `/${Cookies.get('mhet_cnd_project')}/course-registration?page=printapplication&&c_data=${EncryptObjectFunction(encryptedParams)}`)
//     } else if (CheckboxValue) {
//       dispatch({
//         type: candidatesActions.COURSE_PREVIEW_UPDATION,
//         payload: {
//           data: { courseid: courseid || courseIdVal, candidateid: candidateid },
//           coursename: coursename,
//           navigate: navigate,
//         },
//       });
//     } else {
//       if (!CheckboxValue) {
//         setError("Select the checkbox is required");
//       }
//     }
//   };
//   const handleCheckbox = (e) => {
//     const checked = e.target.checked;
//     setCheckBoxValue(checked);
//     setError("");
//   };

//   useEffect(() => {
//     if (courseid) {

//       dispatch({
//         type: candidatesActions.GET_EXAM_STATE,
//         payload: {
//           data: { courseid: courseid },
//         },
//       });
//       dispatch({
//         type: candidatesActions.GET_EXAM_DISTRICT,
//         payload: {
//           data: { courseid: courseid },
//         },
//       });
//       dispatch({
//         type: candidatesActions.GET_EXAM_LANGUAGE,
//         payload: {
//           data: { courseid: courseid },
//         },
//       });
//     }
//   }, [courseid]);
//   useEffect(() => {
//     if (candidateid) {
//       dispatch({
//         type: candidatesActions.GET_COURSE_FORM_DETAILS,
//         payload: { courseid: courseid || courseIdVal },
//       });
//       dispatch({
//         type: candidatesActions.GET_COURSE_APPLICANTID,
//         payload: {
//           courseid: courseid ? courseid || courseIdVal : courseId,
//           candidateid: candidateid,
//         },
//       });
//       dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
//       dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
//       dispatch({ type: candidatesActions.GET_ALL_COURSE_LIST });
//     }
//   }, [candidateid]);

//   const [pdfUrl, setPdfUrl] = useState(null);

//   useEffect(() => {
//     if (uploadDetails) {
//       if (uploadDetails[2]?.document) {
//         try {
//           const base64Data =
//             uploadDetails[2]?.document.split(",")[1] ||
//             uploadDetails[2]?.document;
//           const byteCharacters = atob(base64Data);
//           const byteNumbers = new Array(byteCharacters.length);
//           for (let i = 0; i < byteCharacters.length; i++) {
//             byteNumbers[i] = byteCharacters.charCodeAt(i);
//           }
//           const byteArray = new Uint8Array(byteNumbers);
//           const blob = new Blob([byteArray], { type: "application/pdf" });
//           const url = URL.createObjectURL(blob);
//           setPdfUrl(uploadDetails ? uploadDetails.find((item, index) => item?.documenttype == 3)?.fileurl : "");
//           return () => URL.revokeObjectURL(url);
//         } catch (error) {
//           console.error("Error decoding Base64 string:", error);
//         }
//       }
//     }

//     console.log('pdfUrl---', pdfUrl);
//   }, [uploadDetails[2]?.document]);

//   return (
//     <Grid
//       p={3}
//       style={{
//         minHeight: "50vh",
//         backgroundColor: "#fff",
//         borderRadius: "8px",
//       }}
//       ref={targetRef}
//     >
//       {location.search === "?page=printapplication" && (
//         <Grid
//           container
//           p={3}
//           sx={{ display: "flex", justifyContent: "flex-start" }}
//         >
//           <Grid item md={3}>
//             <img
//               style={{ height: "141px", width: "auto" }}
//               src={CetImage}
//               alt="image"
//             />
//           </Grid>
//           <Grid
//             item
//             md={6}
//             xs={7.5}
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             <Typography>Government of Maharashtra</Typography>
//             <Typography>State Common Entrance Test Cell, Mumbai</Typography>
//             <Typography>{coursename}</Typography>
//             <Typography>APPLICATION FORM</Typography>
//           </Grid>
//           <Grid
//             item
//             md={3}
//             sx={{ display: "flex", justifyContent: "flex-end" }}
//           >
//             <img
//               style={{ height: "143px", width: "auto" }}
//               src={GovernmentImage}
//               alt="image"
//             />
//           </Grid>
//         </Grid>
//       )}
//       <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
//         {location.pathname + location.search ===
//           "/mhcet/course-registration?page=printapplication" &&
//           applicantid !== "" && (
//             <Typography
//               variant="h4"
//               sx={{
//                 textWrap: "wrap",
//                 fontSize: 28,
//                 fontWeight: "bold",
//                 color: "#3F41D1",
//               }}
//             >
//               Application No - {applicantid}
//             </Typography>
//           )}
//       </Box>
//       <Grid container mt={1}>
//         <Grid item xs={12}>
//           <Box
//             p={1}
//             sx={{
//               height: "48px",
//               backgroundColor: "#EDEDED",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               borderRadius: "8px 8px 0px 0px",
//             }}
//           >
//             <Box>
//               <Typography sx={{ fontSize: 18 }}>Personal Details</Typography>
//             </Box>
//             {(location.search !== "?page=printapplication" && !edit && !print) && (
//               <Box
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   cursor: "pointer",
//                   color: "#3F41D1",
//                 }}
//                 onClick={() =>
//                   navigate(
//                     `/${Cookies.get(
//                       "mhet_cnd_project"
//                     )}/course-registration?page=personalinformation`,
//                     {
//                       state: {
//                         courseid: courseid,
//                         coursename: coursename,
//                         status: status >= 5 ? 1 : 0,
//                       },
//                     }
//                   )
//                 }
//               >
//                 <MdOutlineModeEdit size={20} /> &nbsp;
//                 <Typography sx={{ fontSize: 18 }}>Edit</Typography>
//               </Box>
//             )}
//           </Box>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 {" "}
//                 Candidate’s Full name
//               </Typography>
//             </Grid>
//             <Grid item md={9} p={1} xs={9} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.candidatename}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 {" "}
//                 Father’s Full Name
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.fathersname}
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 {" "}
//                 Mother’s Full Name
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.mothername}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Marital Status
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {parseInt(courseFormDetails[0]?.maritalstatusid) === 1
//                   ? "Married"
//                   : parseInt(courseFormDetails[0]?.maritalstatusid) === 2
//                     ? "Unmarried"
//                     : "----"}
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Spouse’s Full Name
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails?.length > 0 &&
//                   (courseFormDetails[0]?.spousename || "-----")}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Gender
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.gender}
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Date Of Birth
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.dob &&
//                   dayjs(courseFormDetails[0]?.dob).format("DD-MM-YYYY")}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Religion
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.religion}
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Region
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.region}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Mother Tongue
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.mothertongue}
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Annual Family Income
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.incomerange}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Nationality
//               </Typography>
//             </Grid>
//             <Grid item md={9} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.nationality}
//               </Typography>
//             </Grid>
//           </Grid>
//         </Grid>
//         <Grid item xs={12}>
//           <Box
//             p={1}
//             sx={{
//               height: "48px",
//               backgroundColor: "#EDEDED",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               // borderRadius: "8px 8px 0px 0px",
//             }}
//           >
//             <Box>
//               <Typography sx={{ fontSize: 18 }}>Permanent Address</Typography>
//             </Box>
//             {(location.search !== "?page=printapplication" && !edit && !print) && (
//               <Box
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   cursor: "pointer",
//                   color: "#3F41D1",
//                 }}
//                 onClick={() =>
//                   navigate(
//                     `/${Cookies.get(
//                       "mhet_cnd_project"
//                     )}/course-registration?page=address`,
//                     {
//                       state: {
//                         courseid: courseid,
//                         coursename: coursename,
//                         status: status >= 5 ? 1 : 0,
//                       },
//                     }
//                   )
//                 }
//               >
//                 <MdOutlineModeEdit size={20} /> &nbsp;
//                 <Typography sx={{ fontSize: 18 }}>Edit</Typography>
//               </Box>
//             )}
//           </Box>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 {" "}
//                 Address Line 1
//               </Typography>
//             </Grid>
//             <Grid item md={9} p={1} xs={3} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.paddressline1}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Address Line 2
//               </Typography>
//             </Grid>
//             <Grid item md={9} p={1} xs={9} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.paddressline2}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Address Line 3
//               </Typography>
//             </Grid>
//             <Grid item md={9} p={1} xs={9} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails?.length > 0 &&
//                   (courseFormDetails[0]?.paddressline3 || "-----")}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 State
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.pstate}
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 District
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.pdistrict}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Taluka
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.ptaluka}
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Village
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.pvillage}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Pincode
//               </Typography>
//             </Grid>
//             <Grid item md={3} p={1} xs={3} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.ppincode}
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Telephone No
//               </Typography>
//             </Grid>
//             <Grid item md={3} p={1} xs={3} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.phoneno}
//               </Typography>
//             </Grid>
//           </Grid>
//         </Grid>
//         <Grid item xs={12}>
//           <Box
//             p={1}
//             sx={{
//               height: "48px",
//               backgroundColor: "#EDEDED",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               // borderRadius: "8px 8px 0px 0px",
//             }}
//           >
//             <Box>
//               <Typography sx={{ fontSize: 18 }}>
//                 Address for Correspondence
//               </Typography>
//             </Box>
//             {(location.search !== "?page=printapplication" && !edit && !print) && (
//               <Box
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   cursor: "pointer",
//                   color: "#3F41D1",
//                 }}
//                 onClick={() =>
//                   navigate(
//                     `/${Cookies.get(
//                       "mhet_cnd_project"
//                     )}/course-registration?page=address`,
//                     {
//                       state: {
//                         courseid: courseid,
//                         coursename: coursename,
//                         status: status >= 5 ? 1 : 0,
//                       },
//                     }
//                   )
//                 }
//               >
//                 <MdOutlineModeEdit size={20} /> &nbsp;
//                 <Typography sx={{ fontSize: 18 }}>Edit</Typography>
//               </Box>
//             )}
//           </Box>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 {" "}
//                 Address Line 1
//               </Typography>
//             </Grid>
//             <Grid item md={9} p={1} xs={9} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.caddressline1}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Address Line 2
//               </Typography>
//             </Grid>
//             <Grid item md={9} p={1} xs={9} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.caddressline2}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Address Line 3
//               </Typography>
//             </Grid>
//             <Grid item md={9} p={1} xs={9} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails?.length > 0 &&
//                   (courseFormDetails[0]?.caddressline3 || "-----")}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 State
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.cstate}
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 District
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.cdistrict === null
//                   ? "Not Applicable"
//                   : courseFormDetails[0]?.cdistrict}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Taluka
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.ctaluka === null
//                   ? "Not Applicable"
//                   : courseFormDetails[0]?.ctaluka}
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Village
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.cvillage === null
//                   ? "Not Applicable"
//                   : courseFormDetails[0]?.cvillage}
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Pin code
//               </Typography>
//             </Grid>
//             <Grid item md={3} p={1} xs={3} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.cpincode}
//               </Typography>
//             </Grid>
//             <Grid item md={3} xs={3} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 STD code
//               </Typography>
//             </Grid>
//             <Grid item md={3} p={1} xs={3} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {courseFormDetails[0]?.stdcode}
//               </Typography>
//             </Grid>
//           </Grid>
//         </Grid>

//         <Grid item xs={12}>
//           {(courseFormDetails[0]?.sscpassedfromindia ||
//             courseFormDetails[0]?.sscschoolstate ||
//             courseFormDetails[0]?.sscschooldistrict ||
//             courseFormDetails[0]?.sscschooltaluka ||
//             courseFormDetails[0]?.sscpassingyear ||
//             courseFormDetails[0]?.sscboard ||
//             courseFormDetails[0]?.sscschoolname ||
//             courseFormDetails[0]?.ssctotalpercentage ||
//             courseFormDetails[0]?.hscboard ||

//             courseFormDetails[0]?.hscmarksobtained ||
//             courseFormDetails[0]?.hscmarksoutof ||

//             courseFormDetails[0]?.hscpassingyear ||
//             courseFormDetails[0]?.hscpercentage ||

//             courseFormDetails[0]?.marktype ||

//             courseFormDetails[0]?.appearingfor

//           ) && (
//               <>
//                 <Box
//                   p={1}
//                   sx={{
//                     height: "48px",
//                     backgroundColor: "#EDEDED",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                   }}
//                 >
//                   <Box>
//                     <Typography sx={{ fontSize: 18 }}>
//                       Qualification Details
//                     </Typography>
//                   </Box>
//                   {(location.search !== "?page=printapplication" && !edit && !print) && (
//                     <Box
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         cursor: "pointer",
//                         color: "#3F41D1",
//                       }}
//                       onClick={() =>
//                         navigate(
//                           `/${Cookies.get(
//                             "mhet_cnd_project"
//                           )}/course-registration?page=qualificationdetails`,
//                           {
//                             state: {
//                               courseid: courseid,
//                               coursename: coursename,
//                               status: status >= 5 ? 1 : 0,
//                             },
//                           }
//                         )
//                       }
//                     >
//                       <MdOutlineModeEdit size={20} /> &nbsp;
//                       <Typography sx={{ fontSize: 18 }}>Edit</Typography>
//                     </Box>
//                   )}
//                 </Box>
//                 <Box
//                   p={1}
//                   sx={{
//                     height: "48px",
//                     backgroundColor: "#EDEDED",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                     marginTop: ".1rem",
//                   }}
//                 >
//                   <Box>
//                     <Typography sx={{ fontSize: 18 }}>SSC Information</Typography>
//                   </Box>
//                 </Box>
//               </>
//             )}

//           <Grid container md={12}>
//             {courseFormDetails[0]?.sscpassedfromindia && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     SSC Passed From India
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.sscpassedfromindia == 1
//                       ? "Yes"
//                       : courseFormDetails[0]?.sscpassedfromindia == 0
//                         ? "No"
//                         : "---"}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.sscschoolstate && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     SSC School State
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.sscschoolstate}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.sscschooldistrict && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     SSC School District
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.sscschooldistrict}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.sscschooltaluka && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     SSC School Taluka
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.sscschooltaluka}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.sscpassingyear && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     SSC/Equivalent Passing Year
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.sscpassingyear}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.sscpassingmonth && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     SSC/Equivalent Passing Month
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {months.find(obj => obj.value == courseFormDetails[0]?.sscpassingmonth).label}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.sscseatno && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     SSC/Equivalent Seat Number
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.sscseatno}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.sscclass && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     SSC/Equivalent Seat Class
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* {courseFormDetails[0]?.sscclass} */}
//                     {educationClass?.find(obj => obj.educationclassid == courseFormDetails[0]?.sscclass)?.educationclass}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.sscboard && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     SSC/Equivalent Board
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.sscboard}
//                   </Typography>
//                 </Grid>
//               </>
//             )}
//             {courseFormDetails[0]?.sscotherboardname && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     SSC/Equivalent other board name
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.sscotherboardname}
//                   </Typography>
//                 </Grid>
//               </>
//             )}
//             {courseFormDetails[0]?.sscschoolname && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     SSC School Name
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.sscschoolname}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.ssctotalpercentage && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     SSC/Equivalent Total Percentage
//                   </Typography>
//                 </Grid>

//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.ssctotalpercentage}
//                   </Typography>
//                 </Grid>
//               </>
//             )}
//           </Grid>
//           {(courseFormDetails[0]?.hsccollegestate ||
//             courseFormDetails[0]?.hsccollegedistrict ||
//             courseFormDetails[0]?.hsccollegetaluka ||
//             courseFormDetails[0]?.hsccollegename ||
//             courseFormDetails[0]?.hscmarktype ||
//             courseFormDetails[0]?.hscmarksobtained ||
//             courseFormDetails[0]?.hscmarksoutof ||
//             courseFormDetails[0]?.hscpercentage ||
//             courseFormDetails[0]?.hscenglishmarksobtained ||
//             courseFormDetails[0]?.hscenglishmarksoutof ||
//             courseFormDetails[0]?.hscenglishpercentage ||
//             courseFormDetails[0]?.hscpassingyear ||
//             courseFormDetails[0]?.hscboard) && (
//               <Box
//                 p={1}
//                 sx={{
//                   height: "48px",
//                   backgroundColor: "#EDEDED",
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "space-between",
//                   marginTop: ".1rem",
//                 }}
//               >
//                 <Box>
//                   <Typography sx={{ fontSize: 18 }}>HSC Information</Typography>
//                 </Box>
//               </Box>
//             )}
//           <Grid container md={12}>
//             {courseFormDetails[0]?.hsccollegestate && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC College State
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.hsccollegestate}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.hsccollegedistrict && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC College District
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.hsccollegedistrict}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.hsccollegetaluka && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC College Taluka
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.hsccollegetaluka}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.hscpassingyear && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC Passing Year
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.hscpassingyear}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.hscpassingmonth && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC Passing Month
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {months.find(obj => obj.value == courseFormDetails[0]?.hscpassingmonth).label}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.hscstream && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC Stream
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* {courseFormDetails[0]?.hscstream} */}
//                     {educationStream.find(obj => obj.educationstreamid == courseFormDetails[0]?.hscstream)?.educationstream}

//                   </Typography>
//                 </Grid>
//               </>
//             )}
//             {courseFormDetails[0]?.hscboard && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC Board
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.hscboard}
//                     {/* {board.find(obj => obj.board == courseFormDetails[0]?.hscboardid)?.board}
//                     {
//                       board.find(obj => obj.boardid === courseFormDetails[0]?.hscboardid)?.board
//                       || "Board Name Not Found"
//                     } */}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.hscseatno && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC Seat Number
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.hscseatno}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.hscclass && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC Class
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* {courseFormDetails[0]?.hscclass} */}
//                     {educationClass?.find(obj => obj.educationclassid == courseFormDetails[0]?.hscclass)?.educationclass}

//                   </Typography>
//                 </Grid>
//               </>
//             )}

// {/*
//             {courseFormDetails[0]?.hscboard && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC Board
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.hscboard}
//                   </Typography>
//                 </Grid>
//               </>
//             )} */}

//             {courseFormDetails[0]?.hsccollegename && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC College Name
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.hsccollegename}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.hscmarktype && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Marks Type
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {parseInt(courseFormDetails[0]?.hscmarktype) === 1
//                       ? "CGPA"
//                       : parseInt(courseFormDetails[0]?.hscmarktype) === 2
//                         ? "Percentage"
//                         : "-----"}
//                   </Typography>
//                 </Grid>
//               </>
//             )}
//             {courseFormDetails[0]?.hscmarksobtained && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC Marks Obtained
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.hscmarksobtained}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.hscmarksoutof && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC Marks Out Of
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.hscmarksoutof}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.hscpercentage && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC Percentage
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.hscpercentage}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.hscenglishmarksobtained && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC English Marks Obtained
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.hscenglishmarksobtained}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.hscenglishmarksoutof && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC English Marks Out Of
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.hscenglishmarksoutof}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.hscenglishpercentage && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     HSC English Percentage
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.hscenglishpercentage}
//                   </Typography>
//                 </Grid>
//               </>
//             )}
//           </Grid>

//           {(courseFormDetails[0]?.eligibilityqualification ||
//             courseFormDetails[0]?.graduationstatus ||
//             courseFormDetails[0]?.graduationpassingyear ||
//             courseFormDetails[0]?.graduationbranch ||
//             courseFormDetails[0]?.graduationmarktype ||
//             courseFormDetails[0]?.graduationmarksobtained ||
//             courseFormDetails[0]?.graduationmarksoutof ||
//             courseFormDetails[0]?.graduationpercentage ||
//             courseFormDetails[0]?.awardedclass ||
//             courseFormDetails[0]?.gradudationduration) && (
//               <Box
//                 p={1}
//                 sx={{
//                   height: "48px",
//                   backgroundColor: "#EDEDED",
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "space-between",
//                   marginTop: ".1rem",
//                 }}
//               >
//                 <Box>
//                   <Typography sx={{ fontSize: 18 }}>
//                     Graduation Information
//                   </Typography>
//                 </Box>
//               </Box>
//             )}
//           <Grid container md={12}>
//             {courseFormDetails[0]?.eligibilityqualification && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Eligiblity Qualification
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.eligibilityqualification}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.graduationstatus && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Status
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.graduationstatus == 1
//                       ? "Appearing"
//                       : courseFormDetails[0]?.graduationstatus == 2
//                         ? "Passed"
//                         : courseFormDetails[0]?.graduationstatus == 0
//                           ? "Not Applicable"
//                           : "---"}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.graduationpassingyear && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Passing Year
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.graduationpassingyear}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.graduationbranch && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Branch Name
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.graduationbranch}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.graduationmarktype && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Marks Type
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {parseInt(courseFormDetails[0]?.graduationmarktype) == 1
//                       ? "Percentage"
//                       : parseInt(courseFormDetails[0]?.graduationmarktype) === 2
//                         ? "CGPA"
//                         : "-----"}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.graduationmarksobtained && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Marks Obtained
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.graduationmarksobtained}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.graduationmarksoutof && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Marks Out Of
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   p={1}
//                   xs={3}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.graduationmarksoutof}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.graduationpercentage && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Percentage
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.graduationpercentage}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.awardedclass && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Awarded Class Name
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.awardedclass}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.gradudationduration && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Duration
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.gradudationduration}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.gotherdegreename && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Other Degree Name
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.gotherdegreename}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.gotheruniversityname && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Other University Name
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.gotheruniversityname}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.gpassingmonth && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Other Passing Month
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* {courseFormDetails[0]?.gpassingmonth} */}
//                     {months.find(obj => obj.value == courseFormDetails[0]?.gpassingmonth).label}

//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.gspecialsubject && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Specialization or Subject
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* {courseFormDetails[0]?.gspecialsubject.length} */}
//                     {options.filter(obj => courseFormDetails[0]?.gspecialsubject?.includes(obj.value))?.map((val, i) => (
//                       <Typography
//                         key={i}
//                         sx={{
//                           fontSize: 15,
//                           textAlign: "left",
//                           color: "black",
//                           fontWeight: 500,
//                           textAlign: "left",
//                         }}
//                       >
//                         {/* {console.log(options.filter(obj=> courseFormDetails[0]?.gspecialsubject?.includes(obj.value) ),'gspecialsubject')} */}
//                         {options.filter(obj => courseFormDetails[0]?.gspecialsubject?.includes(obj.value)).length > 1 && `${i + 1}.`}
//                         {val.label}
//                         {/* {val.toString()} */}
//                       </Typography>
//                     ))}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.gmathssubject && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Mathematics Subject
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.gmathssubject == 1 ? "Yes" : "No"}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.gexampassbefore1975 && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Exam Pass Before 1975
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* {courseFormDetails[0]?.gexampassbefore1975} */}
//                     {courseFormDetails[0]?.gexampassbefore1975 == 1 ? "Yes" : "No"}

//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.gengmediumcollege && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation English Medium College
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* {courseFormDetails[0]?.gengmediumcollege} */}
//                     {courseFormDetails[0]?.gengmediumcollege == 1 ? "Yes" : "No"}

//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.genglangcontenttest && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation English Language Contest Test
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* {courseFormDetails[0]?.genglangcontenttest} */}
//                     {courseFormDetails[0]?.genglangcontenttest == 1 ? "Yes" : "No"}

//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.goptspecialedcollege && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Optional special Ed College
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* {courseFormDetails[0]?.goptspecialedcollege} */}
//                     {courseFormDetails[0]?.goptspecialedcollege == 1 ? "Yes" : "No"}

//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.gspecialeducationmethod && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Graduation Optional special Ed Methods / Subjects
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   {/* <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.gspecialeducationmethod}
//                   </Typography> */}
//                   {options.filter(obj => courseFormDetails[0]?.gspecialeducationmethod?.includes(obj.value))?.map((val, i) => (
//                     <Typography
//                       key={i}
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {/* {console.log(months.filter(obj=> courseFormDetails[0]?.gspecialeducationmethod?.includes(obj.value) ),'gspecialeducationmethod')} */}
//                       {options.filter(obj => courseFormDetails[0]?.gspecialeducationmethod?.includes(obj.value)).length > 1 && `${i + 1}.`}
//                       {val.label}
//                       {/* {val.toString()} */}
//                     </Typography>
//                   ))}
//                 </Grid>
//               </>
//             )}

//           </Grid>

//           {(courseFormDetails[0]?.councilregappno || courseFormDetails[0]?.applicationdate || courseFormDetails[0]?.pgpedupreference1 || courseFormDetails[0]?.pgpedupreference2 || courseFormDetails[0]?.pgpedupreference3 )&&
//           <Box
//             p={1}
//             sx={{
//               height: "48px",
//               backgroundColor: "#EDEDED",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               marginTop: ".1rem"
//             }}
//           >
//             <Box>
//               <Typography sx={{ fontSize: 18 }}>Post Graduation Information</Typography>
//             </Box>
//           </Box>
//           }
//           <Grid container md={12}>
//             {/*  <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Document For Type A
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Document Of
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 CET Exam Name
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Whether Pass Institution Situated MH?
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//              <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 PF Graduation Date
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Internship Training Date
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 MH Council Registration
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Re Council Registration
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//              <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Already Admitted
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//                       <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 College Name
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//                       <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 University Name
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//                       <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//           </Grid> */}
//             {/* <Box
//             p={1}
//             sx={{
//               height: "48px",
//               backgroundColor: "#EDEDED",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               marginTop:".1rem"
//             }}
//           >
//             <Box>
//               <Typography sx={{ fontSize: 18 }}>First Year Marks</Typography>
//             </Box>
//           </Box> */}
//             {/* <Grid container md={12}>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 FY Marks Obtained
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 FY Marks Obtained
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 FY Percentage
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 FY No Of Attempts
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//           </Grid> */}
//             {/* <Box
//             p={1}
//             sx={{
//               height: "48px",
//               backgroundColor: "#EDEDED",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               marginTop:".1rem"
//             }}
//           >
//             <Box>
//               <Typography sx={{ fontSize: 18 }}>Second Year Marks</Typography>
//             </Box>
//           </Box>
//           <Grid container md={12}>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 SY Marks Obtained
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 SY Marks Obtained
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 SY Percentage
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 SY No Of Attempts
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//           </Grid>
//           <Box
//             p={1}
//             sx={{
//               height: "48px",
//               backgroundColor: "#EDEDED",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               marginTop:".1rem"
//             }}
//           >
//             <Box>
//               <Typography sx={{ fontSize: 18 }}>Third Year Marks</Typography>
//             </Box>
//           </Box>
//           <Grid container md={12}>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 TY Marks Obtained
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 TY Marks Obtained
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 TY Percentage
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 TY No Of Attempts
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//           </Grid>
//           <Box
//             p={1}
//             sx={{
//               height: "48px",
//               backgroundColor: "#EDEDED",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               marginTop:".1rem"
//             }}
//           >
//             <Box>
//               <Typography sx={{ fontSize: 18 }}>Fourth Year Marks</Typography>
//             </Box>
//           </Box>
//           <Grid container md={12}>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 FourY Marks Obtained
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 FourY Marks Obtained
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 FourY Percentage
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 FourY No Of Attempts
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//           </Grid>
//           <Box
//             p={1}
//             sx={{
//               height: "48px",
//               backgroundColor: "#EDEDED",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               marginTop:".1rem"
//             }}
//           >
//             <Box>
//               <Typography sx={{ fontSize: 18 }}>Aggregate Marks</Typography>
//             </Box>
//           </Box>
//           <Grid container md={12}>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Aggregate Marks Obtained
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Aggregate Marks Out Of
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               xs={12}
//               p={1}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//               >
//                 Aggregate Percentage
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={3}
//               p={1}
//               xs={12}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >

//               </Typography>
//             </Grid> */}

//             {courseFormDetails[0]?.councilregappno && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Council Registration Application Number
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.councilregappno}
//                     {/* {courseFormDetails[0]?.goptspecialedcollege == 1 ? "Yes" : "No"} */}

//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.applicationdate && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Application Date
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* {courseFormDetails[0]?.applicationdate} */}
//                     {/* {courseFormDetails[0]?.goptspecialedcollege == 1 ? "Yes" : "No"} */}
//                     {courseFormDetails[0]?.applicationdate &&
//                       dayjs(courseFormDetails[0]?.applicationdate).format("DD-MM-YYYY")}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.pgpedupreference1 && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     PG Education Preference 1
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* {courseFormDetails[0]?.pgpedupreference1} */}
//                     {/* {courseFormDetails[0]?.goptspecialedcollege == 1 ? "Yes" : "No"} */}
//                     {PGPPreference.find(obj => obj.value == courseFormDetails[0]?.pgpedupreference1).label}
//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.pgpedupreference2 && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     PG Education Preference 2
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* {courseFormDetails[0]?.pgpedupreference2} */}
//                     {/* {courseFormDetails[0]?.goptspecialedcollege == 1 ? "Yes" : "No"} */}
//                     {PGPPreference.find(obj => obj.value == courseFormDetails[0]?.pgpedupreference2).label}

//                   </Typography>
//                 </Grid>
//               </>
//             )}

//             {courseFormDetails[0]?.pgpedupreference3 && (
//               <>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     PG Education Preference 3
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={3}
//                   xs={3}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* {courseFormDetails[0]?.pgpedupreference3} */}
//                     {/* {courseFormDetails[0]?.goptspecialedcollege == 1 ? "Yes" : "No"} */}
//                     {PGPPreference.find(obj => obj.value == courseFormDetails[0]?.pgpedupreference3).label}

//                   </Typography>
//                 </Grid>
//               </>
//             )}
//           </Grid>

//         </Grid>

//         <Grid item xs={12} md={12}>
//           <Box
//             p={1}
//             sx={{
//               height: "48px",
//               backgroundColor: "#EDEDED",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//             }}
//           >
//             <Box>
//               <Typography sx={{ fontSize: 18 }}>
//                 Domicile and Category Details
//               </Typography>
//             </Box>
//             {(location.search !== "?page=printapplication" && !edit && !print) && (
//               <Box
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   cursor: "pointer",
//                   color: "#3F41D1",
//                 }}
//                 onClick={() =>
//                   navigate(
//                     `/${Cookies.get(
//                       "mhet_cnd_project"
//                     )}/course-registration?page=domicileandcategorydetails`,
//                     {
//                       state: {
//                         courseid: courseid,
//                         coursename: coursename,
//                         status: status >= 5 ? 1 : 0,
//                       },
//                     }
//                   )
//                 }
//               >
//                 <MdOutlineModeEdit size={20} /> &nbsp;
//                 <Typography sx={{ fontSize: 18 }}>Edit</Typography>
//               </Box>
//             )}
//           </Box>
//           <Grid container md={12}>
//             {(courseFormDetails[0]?.issports ||
//               courseFormDetails[0]?.sportsparticipation ||
//               courseFormDetails[0]?.sportsparticipationyear ||
//               courseFormDetails[0]?.sportsrank ||
//               courseFormDetails[0]?.sports ||
//               courseFormDetails[0]?.sportsbody ||
//               courseFormDetails[0]?.isphysicaleducation ||
//               courseFormDetails[0]?.isteachingexperience ||
//               courseFormDetails[0]?.isappliedforelct ||
//               courseFormDetails[0]?.isappliedforksu ||
//               courseFormDetails[0]?.isappliedforspecialeducation ||
//               courseFormDetails[0]?.specialeducationmethods) && (
//                 <>
//                   <Box
//                     p={1}
//                     sx={{
//                       height: "48px",
//                       backgroundColor: "#EDEDED",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "space-between",
//                       marginTop: ".1rem",
//                       width: "100%",
//                     }}
//                   >
//                     <Box>
//                       <Typography sx={{ fontSize: 18 }}>
//                         Sports information
//                       </Typography>
//                     </Box>
//                   </Box>
//                 </>
//               )}
//             <Grid container md={12}>
//               {courseFormDetails[0]?.issports && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Is Sports
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {parseInt(courseFormDetails[0]?.issports) === 1
//                         ? "Yes"
//                         : "No"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.sportsparticipation && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Sports Participation Level
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.sportsparticipation}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.sportsparticipationyear && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Sports Participation Year
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.sportsparticipationyear}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.sportsrank && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Sports Rank
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.sportsrank}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.sports && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Sports Name
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.sports}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.sportsbody && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Sports Body
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.sportsbody}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.isphysicaleducation && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Is Studied Physical Education
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {parseInt(courseFormDetails[0]?.isphysicaleducation) == 1
//                         ? "Yes"
//                         : parseInt(courseFormDetails[0]?.isphysicaleducation) ==
//                           0
//                           ? "No"
//                           : "----"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.isteachingexperience && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Is Teaching Experience
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "black" }}
//                     >
//                       {parseInt(courseFormDetails[0]?.isteachingexperience) == 1
//                         ? "Yes"
//                         : parseInt(
//                           courseFormDetails[0]?.isteachingexperience
//                         ) == 0
//                           ? "No"
//                           : "----"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.isappliedforelct && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Is Applied For ELCT
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "black" }}
//                     >
//                       {parseInt(courseFormDetails[0]?.isappliedforelct) == 1
//                         ? "Yes"
//                         : parseInt(courseFormDetails[0]?.isappliedforelct) == 0
//                           ? "No"
//                           : "----"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.isappliedforksu && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Is Applied For KSU
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "black" }}
//                     >
//                       {parseInt(courseFormDetails[0]?.isappliedforksu) == 1
//                         ? "Yes"
//                         : parseInt(courseFormDetails[0]?.isappliedforksu) == 0
//                           ? "No"
//                           : "----"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.isappliedforspecialeducation && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Is Applied For Special Education
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "black" }}
//                     >
//                       {parseInt(
//                         courseFormDetails[0]?.isappliedforspecialeducation
//                       ) == 1
//                         ? "Yes"
//                         : parseInt(
//                           courseFormDetails[0]?.isappliedforspecialeducation
//                         ) == 0
//                           ? "No"
//                           : "----"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.specialeducationmethods && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Special Education Methods
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "black" }}
//                     >
//                       {parseInt(
//                         courseFormDetails[0]?.specialeducationmethods
//                       ) == 1
//                         ? "Yes"
//                         : parseInt(
//                           courseFormDetails[0]?.specialeducationmethods
//                         ) == 0
//                           ? "No"
//                           : "----"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}
//             </Grid>
//             {(courseFormDetails[0]?.isdisability ||
//               courseFormDetails[0]?.pwdsubtype ||
//               courseFormDetails[0]?.isextratime ||
//               courseFormDetails[0]?.isscribe) && (
//                 <>
//                   <Box
//                     p={1}
//                     sx={{
//                       height: "48px",
//                       backgroundColor: "#EDEDED",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "space-between",
//                       marginTop: ".1rem",
//                       width: "100%",
//                     }}
//                   >
//                     <Box>
//                       <Typography sx={{ fontSize: 18 }}>
//                         Disability Information
//                       </Typography>
//                     </Box>
//                   </Box>
//                 </>
//               )}
//             <Grid container md={12}>
//               {courseFormDetails[0]?.isdisability && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Are You Person With Disability
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {parseInt(courseFormDetails[0]?.isdisability) == 1
//                         ? "Yes"
//                         : parseInt(courseFormDetails[0]?.isdisability) == 0
//                           ? "No"
//                           : "---"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.pwdtype && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Type Of Disability
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.pwdtype}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.pwdsubtype && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       PWD Sub Type
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.pwdsubtype}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.isextratime && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Require Extra Time
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {parseInt(courseFormDetails[0]?.isextratime) == 1
//                         ? "Yes"
//                         : parseInt(courseFormDetails[0]?.isextratime) == 0
//                           ? "No"
//                           : "----"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.isscribe && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Require Scribe
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {parseInt(courseFormDetails[0]?.isscribe) == 1
//                         ? "Yes"
//                         : parseInt(courseFormDetails[0]?.isscribe) == 0
//                           ? "No"
//                           : "----"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}
//             </Grid>
//             {(courseFormDetails[0]?.isorphan ||
//               courseFormDetails[0]?.orphantype) && (
//                 <>
//                   <Box
//                     p={1}
//                     sx={{
//                       height: "48px",
//                       backgroundColor: "#EDEDED",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "space-between",
//                       marginTop: ".1rem",
//                       width: "100%",
//                     }}
//                   >
//                     <Box>
//                       <Typography sx={{ fontSize: 18 }}>
//                         Orphan Information
//                       </Typography>
//                     </Box>
//                   </Box>
//                 </>
//               )}
//             <Grid container md={12}>
//               {courseFormDetails[0]?.isorphan && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Are You Orphan
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {parseInt(courseFormDetails[0]?.isorphan) == 1
//                         ? "Yes"
//                         : parseInt(courseFormDetails[0]?.isorphan) == 0
//                           ? "No"
//                           : "----"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.orphantype && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Orphan Type
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.orphantype}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}
//             </Grid>
//             <Box
//               p={1}
//               sx={{
//                 height: "48px",
//                 backgroundColor: "#EDEDED",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//                 marginTop: ".1rem",
//                 width: "100%",
//               }}
//             >
//               <Box>
//                 <Typography sx={{ fontSize: 18 }}>
//                   Category information
//                 </Typography>
//               </Box>
//             </Box>
//             <Grid container md={12}>
//               {courseFormDetails[0]?.ismhcandidate && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                      Are you Domiciled in the State of Maharashtra?
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {parseInt(courseFormDetails[0]?.ismhcandidate) == 1
//                         ? "Yes"
//                         : parseInt(courseFormDetails[0]?.ismhcandidate) == 0
//                           ? "No"
//                           : "----"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.category && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Category of Candidate
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.category}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.castename && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Caste Name
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.castename}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.caste && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Caste To Which You Belong
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.caste}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.appliedforews && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Wish to Apply for EWS(Economically Weaker Section) Seats?
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {parseInt(courseFormDetails[0]?.appliedforews) === 1
//                         ? "Yes"
//                         : parseInt(courseFormDetails[0]?.appliedforews) == 0
//                           ? "No"
//                           : "----"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.ewscertificatestatus !== null &&
//                 (courseFormDetails[0]?.ewscertificatestatus === 0 ||
//                   courseFormDetails[0]?.ewscertificatestatus === 1 ||
//                   courseFormDetails[0]?.ewscertificatestatus === 2) && (
//                   <>
//                     <Grid
//                       item
//                       md={3}
//                       xs={3}
//                       p={1}
//                       sx={{ border: "1px solid #F7F7F7" }}
//                     >
//                       <Typography
//                         sx={{
//                           fontSize: 15,
//                           textAlign: "left",
//                           color: "#3F41D1",
//                         }}
//                       >
//                         EWS Certificate Status
//                       </Typography>
//                     </Grid>
//                     <Grid
//                       item
//                       md={3}
//                       p={1}
//                       xs={3}
//                       sx={{ border: "1px solid #F7F7F7" }}
//                     >
//                       <Typography
//                         sx={{
//                           fontSize: 15,
//                           textAlign: "left",
//                           color: "black",
//                           fontWeight: 500,
//                           textAlign: "left",
//                         }}
//                       >
//                         {parseInt(
//                           courseFormDetails[0]?.ewscertificatestatus
//                         ) === 1
//                           ? "Available"
//                           : parseInt(
//                             courseFormDetails[0]?.ewscertificatestatus
//                           ) === 2
//                             ? "Not Available"
//                             : parseInt(
//                               courseFormDetails[0]?.ewscertificatestatus
//                             ) === 0
//                               ? "Applied But Not Received"
//                               : "----"}
//                       </Typography>
//                     </Grid>
//                   </>
//                 )}

//               {courseFormDetails[0]?.ewsapplicationno && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       EWS Certificate Application Number
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.ewsapplicationno}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.ewsapplicationdate && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       EWS Certificate Application Date
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.ewsapplicationdate}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.ewsdistrict && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       EWS Certificate Issuing District
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.ewsdistrict}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.ewstaluka && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       EWS Certificate Issuing Taluka
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.ewstaluka}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.castecertificatestatus && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Do you possess Caste Certificate?
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {parseInt(
//                         courseFormDetails[0]?.castecertificatestatus
//                       ) === 1
//                         ? "Available"
//                         : parseInt(
//                           courseFormDetails[0]?.castecertificatestatus
//                         ) === 2
//                           ? "Applied But Not Received"
//                           : parseInt(
//                             courseFormDetails[0]?.castecertificatestatus
//                           ) === 3
//                             ? "Not Applied"
//                             : "-----"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {((courseFormDetails[0]?.castevaliditystatus !== null &&
//                 courseFormDetails[0]?.castevaliditystatus !== undefined &&
//                 courseFormDetails[0]?.castevaliditystatus !== "") ||
//                 courseFormDetails[0]?.castevaliditystatus === 0) && (
//                   <>
//                     <Grid
//                       item
//                       md={3}
//                       xs={3}
//                       p={1}
//                       sx={{ border: "1px solid #F7F7F7" }}
//                     >
//                       <Typography
//                         sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                       >
//                         Possess Caste / Tribe Validity Certificate
//                       </Typography>
//                     </Grid>
//                     <Grid
//                       item
//                       md={3}
//                       xs={3}
//                       p={1}
//                       sx={{ border: "1px solid #F7F7F7" }}
//                     >
//                       <Typography
//                         sx={{
//                           fontSize: 15,
//                           textAlign: "left",
//                           color: "black",
//                           fontWeight: 500,
//                           textAlign: "left",
//                         }}
//                       >
//                         {parseInt(courseFormDetails[0]?.castevaliditystatus) === 1
//                           ? "Available"
//                           : parseInt(
//                             courseFormDetails[0]?.castevaliditystatus
//                           ) === 2
//                             ? "Applied But Not Received"
//                             : parseInt(
//                               courseFormDetails[0]?.castevaliditystatus
//                             ) === 0
//                               ? "Not Applied"
//                               : "-----"}
//                       </Typography>
//                     </Grid>
//                   </>
//                 )}

//               {courseFormDetails[0]?.castevaliditycertificateno && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Caste Validity Certificate Number
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.castevaliditycertificateno}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.cvcapplicationno && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Caste/Tribe Validity Certificate Application Number
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.cvcapplicationno}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.cvcapplicationdate && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Caste/Tribe Validity Certificate Application Date
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.cvcapplicationdate}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.cvcauthority && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Caste/Tribe Validity Certificate Issuing Authority Name
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.cvcauthority}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.cvcdistrict && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Caste/Tribe Validity Certificate Issuing District
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.cvcdistrict}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.cvcname && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Name as per Caste / Tribe Validity Certificate
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.cvcname}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.castecertificateno && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Caste Certificate  Number
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.castecertificateno}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.castecertificatereceiptno && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Caste Certificate Receipt No
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.castecertificatereceiptno}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.castecertificateappdate && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Caste Certificate Application Date
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.castecertificateappdate}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.nclstatus && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Do you have Non Creamy Layer Certificate valid up to 31st March 2026?
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {parseInt(courseFormDetails[0]?.nclstatus) === 1
//                         ? "Available"
//                         : parseInt(courseFormDetails[0]?.nclstatus) === 2
//                           ? "Applied But Not Received"
//                           : parseInt(courseFormDetails[0]?.nclstatus) === 3
//                             ? "Not Applied"
//                             : "-----"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.nclapplicationno && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Non Creamy Layer Certificate Application Number
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.nclapplicationno}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.nclapplicationdate && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Non Creamy Layer Certificate Application Date
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.nclapplicationdate}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.nclauthority && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                      Non Creamy Layer Certificate Issuing Authority Name
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.nclauthority}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}
//             </Grid>
//             {(courseFormDetails[0]?.isminority ||
//               courseFormDetails[0]?.linguisticminority ||
//               courseFormDetails[0]?.religiousminority) && (
//                 <>
//                   <Box
//                     p={1}
//                     sx={{
//                       height: "48px",
//                       backgroundColor: "#EDEDED",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "space-between",
//                       marginTop: ".1rem",
//                       width: "100%",
//                     }}
//                   >
//                     <Box>
//                       <Typography sx={{ fontSize: 18 }}>
//                         Minority Information
//                       </Typography>
//                     </Box>
//                   </Box>
//                 </>
//               )}
//             <Grid container md={12}>
//               {courseFormDetails[0]?.isminority && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Belongs to Minority Category
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {parseInt(courseFormDetails[0]?.isminority) === 1
//                         ? "Yes"
//                         : parseInt(courseFormDetails[0]?.isminority) === 0
//                           ? "No"
//                           : "----"}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.linguisticminority && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Linguistic Minority
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.linguisticminority}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}

//               {courseFormDetails[0]?.religiousminority && (
//                 <>
//                   <Grid
//                     item
//                     md={3}
//                     xs={3}
//                     p={1}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                     >
//                       Religious Minority
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={3}
//                     p={1}
//                     xs={3}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.religiousminority}
//                     </Typography>
//                   </Grid>
//                 </>
//               )}
//             </Grid>
//           </Grid>
//           {(courseFormDetails[0]?.subjectgroup ||
//             courseFormDetails[0]?.preferencestate1id ||
//             courseFormDetails[0]?.preferencestate2id ||
//             courseFormDetails[0]?.preferencestate3id ||
//             courseFormDetails[0]?.preferencestate4id ||
//             courseFormDetails[0]?.languageid ||
//             courseFormDetails[0]?.preference1district ||
//             courseFormDetails[0]?.preference2district ||
//             courseFormDetails[0]?.preference3district ||
//             courseFormDetails[0]?.preference4district) && (
//               <>
//                 <Box
//                   p={1}
//                   sx={{
//                     height: "48px",
//                     backgroundColor: "#EDEDED",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "space-between",
//                   }}
//                 >
//                   <Box>
//                     <Typography sx={{ fontSize: 18 }}>
//                       Exam Center Selection Details
//                     </Typography>
//                   </Box>
//                   {(location.search !== "?page=printapplication" && !edit && !print) && (
//                     <Box
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         cursor: "pointer",
//                         color: "#3F41D1",
//                       }}
//                       onClick={() =>
//                         navigate(
//                           `/${Cookies.get(
//                             "mhet_cnd_project"
//                           )}/course-registration?page=examinationdetails`,
//                           {
//                             state: {
//                               courseid: courseid,
//                               coursename: coursename,
//                               status: status >= 5 ? 1 : 0,
//                             },
//                           }
//                         )
//                       }
//                     >
//                       <MdOutlineModeEdit size={20} /> &nbsp;
//                       <Typography sx={{ fontSize: 18 }}>Edit</Typography>
//                     </Box>
//                   )}
//                 </Box>
//               </>
//             )}
//           {courseFormDetails[0]?.subjectgroup && (
//             <>
//               <Grid container md={12}>
//                 <Grid
//                   item
//                   md={6}
//                   xs={6}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Subject Group for Exam
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={6}
//                   p={1}
//                   xs={6}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.subjectgroup}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </>
//           )}
//           {courseFormDetails[0]?.languageid && (
//             <>
//               <Grid container md={12}>
//                 <Grid
//                   item
//                   md={6}
//                   xs={6}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Language / Medium of the Question Paper
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={6}
//                   p={1}
//                   xs={6}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   {/* {courseFormDetails[0]?.languageid?.map((val, i) => ( */}
//                   <Typography
//                     // key={i}
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* {courseFormDetails[0]?.languageid.length > 1 && `${i + 1}.`} */}
//                     {/* {val} */}
//                     {Language.find(obj => obj.value == courseFormDetails[0]?.languageid).label}

//                   </Typography>
//                   {/* ))} */}
//                 </Grid>
//               </Grid>
//             </>
//           )}
//           {courseFormDetails[0]?.preferencestate1id && (
//             <>
//               <Grid container md={12}>
//                 <Grid
//                   item
//                   md={6}
//                   xs={6}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Examination Centre State at Preference Number 1
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={6}
//                   p={1}
//                   xs={6}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* preferencestate1id */}
//                     {/* {courseFormDetails[0]?.preferencestate1id} */}
//                     {examState?.find(obj => obj.stateid === courseFormDetails[0]?.preferencestate1id)?.state}
//                     {/* {courseFormDetails[0]?.examstate?.map((val, i) => (
//                     <Typography
//                       key={i}
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.examstate.length > 1 && `${i + 1}.`}
//                       {val}
//                     </Typography>
//                   ))} */}

//                   </Typography>
//                 </Grid>
//               </Grid>
//             </>
//           )}
//           {courseFormDetails[0]?.preference1district && (
//             <>
//               <Grid container md={12}>
//                 <Grid
//                   item
//                   md={6}
//                   xs={6}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Examination Center District at Preference Number 1
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={6}
//                   p={1}
//                   xs={6}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.preference1district}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </>
//           )}
//           {courseFormDetails[0]?.preferencestate2id && (
//             <>
//               <Grid container md={12}>
//                 <Grid
//                   item
//                   md={6}
//                   xs={6}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                    Examination Centre State at Preference Number 2
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={6}
//                   p={1}
//                   xs={6}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* preferencestate2id */}
//                     {/* {courseFormDetails[0]?.preferencestate2id} */}
//                     {examState?.find(obj => obj.stateid === courseFormDetails[0]?.preferencestate2id)?.state}

//                     {/* {courseFormDetails[0]?.examstate?.map((val, i) => (
//                     <Typography
//                       key={i}
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.examstate.length > 1 && `${i + 1}.`}
//                       {val}
//                     </Typography>
//                   ))} */}

//                   </Typography>
//                 </Grid>
//               </Grid>
//             </>
//           )}
//           {courseFormDetails[0]?.preference2district && (
//             <>
//               <Grid container md={12}>
//                 <Grid
//                   item
//                   md={6}
//                   xs={6}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Examination Center District at Preference Number 2
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={6}
//                   p={1}
//                   xs={6}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.preference2district}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </>
//           )}

//           {courseFormDetails[0]?.preferencestate3id && (
//             <>
//               <Grid container md={12}>
//                 <Grid
//                   item
//                   md={6}
//                   xs={6}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Examination Centre State at Preference Number 3
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={6}
//                   p={1}
//                   xs={6}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* preferencestate3id */}
//                     {/* {courseFormDetails[0]?.preferencestate3id} */}
//                     {examState?.find(obj => obj.stateid === courseFormDetails[0]?.preferencestate3id)?.state}

//                     {/* {courseFormDetails[0]?.examstate?.map((val, i) => (
//                     <Typography
//                       key={i}
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.examstate.length > 1 && `${i + 1}.`}
//                       {val}
//                     </Typography>
//                   ))} */}

//                   </Typography>
//                 </Grid>
//               </Grid>
//             </>
//           )}
//           {courseFormDetails[0]?.preference3district && (
//             <>
//               <Grid container md={12}>
//                 <Grid
//                   item
//                   md={6}
//                   xs={6}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Examination Center District at Preference Number 3
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={6}
//                   p={1}
//                   xs={6}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails[0]?.preference3district}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </>
//           )}

//           {courseFormDetails[0]?.preferencestate4id && (
//             <>
//               <Grid container md={12}>
//                 <Grid
//                   item
//                   md={6}
//                   xs={6}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Examination Centre State at Preference Number 4
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={6}
//                   p={1}
//                   xs={6}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {/* preferencestate4id */}
//                     {/* {courseFormDetails[0]?.preferencestate4id} */}
//                     {examState?.find(obj => obj.stateid === courseFormDetails[0]?.preferencestate4id)?.state}

//                     {/* {courseFormDetails[0]?.examstate?.map((val, i) => (
//                     <Typography
//                       key={i}
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {courseFormDetails[0]?.examstate.length > 1 && `${i + 1}.`}
//                       {val}
//                     </Typography>
//                   ))} */}

//                   </Typography>
//                 </Grid>
//               </Grid>
//             </>
//           )}
//           {courseFormDetails[0]?.preference4district && (
//             <>
//               <Grid container md={12}>
//                 <Grid
//                   item
//                   md={6}
//                   xs={6}
//                   p={1}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{ fontSize: 15, textAlign: "left", color: "#3F41D1" }}
//                   >
//                     Examination Center District at Preference Number 4
//                   </Typography>
//                 </Grid>
//                 <Grid
//                   item
//                   md={6}
//                   p={1}
//                   xs={6}
//                   sx={{ border: "1px solid #F7F7F7" }}
//                 >
//                   <Typography
//                     sx={{
//                       fontSize: 15,
//                       textAlign: "left",
//                       color: "black",
//                       fontWeight: 500,
//                       textAlign: "left",
//                     }}
//                   >
//                     {courseFormDetails?.length > 0 &&
//                       (courseFormDetails[0]?.preference4district || "-----")}
//                   </Typography>
//                 </Grid>
//               </Grid>
//             </>
//           )}
//         </Grid>

//         <Grid item md={12} xs={12}>
//           <Box
//             p={1}
//             sx={{
//               height: "48px",
//               backgroundColor: "#EDEDED",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//             }}
//           >
//             <Box>
//               <Typography sx={{ fontSize: 18 }}>
//                 Photograph and Signature
//               </Typography>
//             </Box>
//             {(location.search !== "?page=printapplication" && !edit && !print) && (
//               <Box
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   cursor: "pointer",
//                   color: "#3F41D1",
//                 }}
//                 onClick={() =>
//                   navigate(
//                     `/${Cookies.get(
//                       "mhet_cnd_project"
//                     )}/registration?page=uploads`,
//                     { state: { courseid: courseid, coursename: coursename, status: status >= 5 ? 1 : 0, fromPreview: true } }
//                   )
//                 }
//               >

//                 <MdOutlineModeEdit size={20} /> &nbsp;
//                 <Typography sx={{ fontSize: 18 }}>Edit</Typography>
//               </Box>
//             )}
//           </Box>
//           <Grid container md={12}>
//             <Grid item md={6} xs={6} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "center",
//                   color: "black",
//                   fontWeight: 500,
//                 }}
//               >
//                 Photograph
//               </Typography>
//             </Grid>
//             <Grid item md={6} p={1} xs={6} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "center",
//                 }}
//               >
//                 Signature
//               </Typography>
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid
//               item
//               md={6}
//               xs={6}
//               p={1}
//               sx={{
//                 border: "1px solid #F7F7F7",
//                 height: "200px",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <img
//                 style={{ height: "140px", width: "auto" }}
//                 src={
//                   courseUploadDetails?.length
//                     ? courseUploadDetails?.find(
//                       (item) => item?.documenttype == 1
//                     )?.fileurl
//                     : uploadDetails?.find(
//                       (item) => item?.documenttype == 1
//                     )?.fileurl
//                 }
//                 alt="photo"
//               />
//             </Grid>
//             <Grid
//               item
//               md={6}
//               p={1}
//               xs={6}
//               sx={{
//                 border: "1px solid #F7F7F7",
//                 height: "200px",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               <img
//                 style={{ height: "140px", maxWidth: "250px" }}
//                 src={courseUploadDetails?.length ? courseUploadDetails?.find((item, index) => item?.documenttype == 2)?.fileurl :
//                   uploadDetails?.find(
//                     (item, index) => item?.documenttype == 2
//                   )?.fileurl
//                 }
//                 alt="signatute"
//               />
//             </Grid>
//           </Grid>
//         </Grid>

//         <Grid item md={12} xs={12}>
//           <Grid
//             container
//             // md={12}
//             // xs={12}
//             p={1}
//             sx={{
//               height: "48px",
//               width: "100%",
//               backgroundColor: "#EDEDED",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//             }}
//           >
//             <Box>
//               <Typography sx={{ fontSize: 18 }}>Document Uploaded</Typography>
//             </Box>
//             {(location.search !== "?page=printapplication" && !edit && !print) && (
//               <Box
//                 sx={{
//                   display: "flex",
//                   alignItems: "center",
//                   cursor: "pointer",
//                   color: "#3F41D1",
//                 }}
//                 onClick={() =>
//                   navigate(
//                     `/${Cookies.get(
//                       "mhet_cnd_project"
//                     )}/registration?page=uploads`,
//                     { state: { courseid: courseid, coursename: coursename, status: status >= 5 ? 1 : 0, fromPreview: true } }
//                   )
//                 }
//               >
//                 <MdOutlineModeEdit size={20} /> &nbsp;
//                 <Typography sx={{ fontSize: 18 }}>Edit</Typography>
//               </Box>
//             )}
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={2} xs={2} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "center",
//                   color: "#3F41D1",
//                   fontWeight: 500,
//                 }}
//               >
//                 S.No
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={10}
//               p={1}
//               xs={10}
//               sx={{ border: "1px solid #F7F7F7" }}
//             >
//               {/* <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "#3F41D1",
//                   fontWeight: 500,
//                   textAlign: "center",
//                 }}
//               >
//                 Signature
//               </Typography> */}
//             </Grid>
//           </Grid>
//           <Grid container md={12}>
//             <Grid item md={2} xs={2} p={1} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "center",
//                   color: "black",
//                   fontWeight: 500,
//                 }}
//               >
//                 1
//               </Typography>
//             </Grid>
//             <Grid item md={4} p={1} xs={4} sx={{ border: "1px solid #F7F7F7" }}>
//               <Typography
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "black",
//                   fontWeight: 500,
//                   textAlign: "left",
//                 }}
//               >
//                 {uploadDetails ? uploadDetails?.find((item, index) => item?.documenttype == 1)?.documentname : ""}
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               md={6}
//               p={1}
//               xs={6}
//               sx={{
//                 border: "1px solid #F7F7F7",
//                 display: "flex",
//                 justifyContent: "center",
//               }}
//             >
//               <Box
//                 component="a"
//                 sx={{
//                   fontSize: 15,
//                   textAlign: "left",
//                   color: "#3F41D1",
//                   fontWeight: 500,
//                   textAlign: "center",
//                   cursor: "pointer",
//                   ":hover": {
//                     textDecoration: "underline",
//                   },
//                 }}
//                 target="_blank"
//                 href={uploadDetails ? uploadDetails?.find((item, index) => item?.documenttype == 3)?.fileurl : ""}
//               >
//                 View
//               </Box>
//             </Grid>
//             {(location.pathname + location.search ===
//               "/mhcet/course-registration?page=preview" || edit) && (
//                 <>
//                   <Grid
//                     item
//                     md={12}
//                     p={1}
//                     xs={12}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "black",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       Note :
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={12}
//                     p={1}
//                     xs={12}
//                     sx={{ border: "1px solid #F7F7F7" }}
//                   >
//                     <Typography
//                       sx={{
//                         fontSize: 15,
//                         textAlign: "left",
//                         color: "red",
//                         fontWeight: 500,
//                         textAlign: "left",
//                       }}
//                     >
//                       {/* *Candidate Shall Carry Disability Certificate at the time of
//                     Examination.( For All PWD Candidate) */}
//                       *Candidate to note the districts given in the options may not be available necessarily. If the Infrastructure is not available for conducting computer based Test in the First District. In such a case, the candidate will be shifted to the district of the next choice, and the allotted district will be mentioned in the Admit Card
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     md={12}
//                     p={1}
//                     xs={12}
//                     sx={{
//                       border: "1px solid #F7F7F7",
//                       borderRadius: "0px 0px 8px 8px",
//                     }}
//                   >
//                     <Box
//                       sx={{ display: "flex", gap: "8px", alignItems: "center" }}
//                     >
//                       <Checkbox
//                         onChange={handleCheckbox}
//                         checked={CheckboxValue}
//                         sx={{ margin: 0, padding: 0 }}
//                       />
//                       <Typography
//                         sx={{
//                           fontSize: 15,
//                           textAlign: "left",
//                           color: "red",
//                           fontWeight: 500,
//                           textAlign: "left",
//                         }}
//                       >
//                         I have read all important Instructions.
//                       </Typography>
//                     </Box>
//                   </Grid>
//                   {error && (
//                     <small style={{ marginTop: ".5rem" }} className="error">
//                       {error}
//                     </small>
//                   )}
//                 </>
//               )}
//           </Grid>
//         </Grid>
//       </Grid>
//       {(location.pathname + location.search ===
//         "/mhcet/course-registration?page=preview" || edit) && (
//           <Grid
//             item
//             md={12}
//             pt={2}
//             sx={{
//               display: "flex",
//               gap: "8px",
//               justifyContent: "flex-end",
//               flexDirection: isMobile ? "column" : null,
//             }}
//           >
//             <MauvelousPrimaryButton
//               title={buttonLoader ? "loader" : isExtraFee ? "Continue to payment" : 'Continue'}
//               handleClick={courseList?.all?.length > 0 &&
//                 courseList?.all?.find((obj) => obj.courseid == courseid)?.isotbs == true && new Date(currentOtbsVenue[0]?.validtime) <= new Date() ? ()=>{
//                   navigate(
//                     `/${Cookies.get(
//                       "mhet_cnd_project"
//                     )}/course-registration?page=examinationdetails`,
//                     {
//                       state: {
//                         courseid: courseid,
//                         coursename: coursename,
//                         status: status >= 5 ? 1 : 0,
//                       },
//                     }
//                   )
//                 } : handleProcced }
//             // disabled={(edit ? false : true || !CheckboxValue || isPaid === "1")}
//             />
//           </Grid>
//         )}
//     </Grid>
//   );
// }
