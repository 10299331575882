import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useForm, useFormState } from "react-hook-form";
import dayjs from "dayjs";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { masterFieldLabel } from "../../utils/courseValues";
import { useDispatch, useSelector } from "react-redux";
import candidatesActions from "../../redux/candidates/actions";
import masterActions from "../../redux/master/action";
import { LuPencilLine } from "react-icons/lu";
import SubmitButton from "../../common/submitButton";
import {
  DecryptObjectFunction,
  EncryptObjectFunction,
} from "../../utils/cryptoFunction";
import Cookies from "js-cookie";
import imageCompression from "browser-image-compression";
import ImageCrop from "../registration/imageCrop";
import ImageCropSignature from "../registration/imageCropSignature";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import userphoto from "../../../src/assets/images/example.png";
import sign from "../../../src/assets/images/sign.png";
import { AlphabetsValidationUppercase } from "../../utils/validations";

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const fileInputRefSignature = useRef(null);
  const [open, setOpen] = useState(false);
  const [signfilename, setSignatureImage] = useState("");
  const [showModalSignature, setShowModalSignature] = useState({
    show: false,
    data: null,
  });
  const [cropSignatureImage, setCropSignatureImage] = useState(null);
  const [photoError, setError] = useState("");
  const [signatureError, setSignatureError] = useState("");
  const [signatureSize, setSignatureSize] = useState("");
  const [signatureOpen, setSignatureOpen] = useState(false);
  const [photoCheckError, setPhotoCheckError] = useState("");
  const [cropPhotoImage, setCropPhotoImage] = useState(null);
  const [signatureCheckError, setSignatureCheckError] = useState("");
  const [signatureSizeCompressed, setSignatureSizeCompressed] = useState("");
  const [signURL, setSignURL] = useState("");
  const [photofilename, setPhotoImage] = useState("");
  const [photoSize, setPhotoSize] = useState("");
  const [size, setSize] = useState("");
  const [confirmPhotoImage, setConfirmPhotoImage] = useState(true);
  const [photoSizeCompressed, setPhotoSizeCompressed] = useState("");
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isUploaded, setIsUploaded] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    masterFields,
    uploadDetails,
    courseFormDetails,
    personalDetails,
    courseUploadDetails,
  } = useSelector((state) => state.candidatesReducer);
  const {
    showImage,
    showCompressedImage,
    showSignature,
    showCompressedSignature,
  } = useSelector((state) => state.candidatesReducer);
  const [showModal, setShowModal] = useState({ show: false, data: null });

  const { gender } = useSelector((state) => state.masterReducer);
  const { buttonLoader } = useSelector((state) => state.commonReducer);
  const candidateid = useSelector(
    (state) => state.authReducer.candidateTokenDetails?.candidateid
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    setValue,
    trigger,
    reset,
    control,
  } = useForm();
  const [searchParams] = useSearchParams();
  const encryptedParams = searchParams.get("c_data");
  let decrypted = DecryptObjectFunction(encryptedParams);
  const courseid = decrypted?.courseid;
  const coursename = decrypted?.coursename;
  const [testGroup, setTestGroup] = useState("");

  const watchedValues = watch();
  useEffect(() => {
    const isModified = Object.values(watchedValues).some(
      (value) => value !== ""
    );
    setIsButtonEnabled(isModified);
  }, [watchedValues]);

  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_MASTER_FIELDS,
        payload: {
          data: { courseid: courseid },
        },
      });
    }
  }, [courseid]);

  useEffect(() => {
    if (candidateid) {
      dispatch({ type: masterActions.GET_GENDER });
      dispatch({ type: candidatesActions.GET_UPLOAD_PERSONAL_DETAILS });
      dispatch({
        type: candidatesActions.GET_COURSE_FORM_DETAILS,
        payload: { courseid: courseid },
      });
      dispatch({ type: candidatesActions.GET_PROFILE_PERSONAL_DETAILS });
    }
  }, [candidateid]);

  useEffect(() => {
    if (courseFormDetails?.length > 0) {
      const formattedDob = dayjs(personalDetails[0]?.dob).format("DD/MM/YYYY");
      reset({ ...courseFormDetails[0], dob: formattedDob });
      setValue("subjectgroup", courseFormDetails[0]?.subjectgroup);
      setTestGroup(courseFormDetails[0]?.subjectgroup);
    }
  }, [courseFormDetails, personalDetails]);

  useEffect(() => {
    if (courseid) {
      dispatch({
        type: candidatesActions.GET_COURSE_UPLOAD_PERSONAL_DETAILS,
        data: { courseid: courseid },
      });
    }
  }, []);

  const handleSave = (data) => {

    let isExtraFee = false;
    const currentGroup = testGroup;
    if (
      (currentGroup === "PCM" || currentGroup === "PCB") &&
      data.subjectgroup === "PCMB"
    ) {
      isExtraFee = true;
    }

    dispatch({
      type: candidatesActions.EDIT_COURSE_DETAILS,
      payload: {
        data: {
          ...data,
          subjectgroup:
            data?.subjectgroup === "PCM"
              ? "PCM"
              : data?.subjectgroup === "PCB"
              ? "PCB"
              : currentGroup,
          courseid: courseid,
          candidateid: candidateid,
          datalog: data,
          photourl: !photofilename
            ? courseUploadDetails?.find((item) => item?.documenttype == 1)
                ?.fileurl ||
              uploadDetails?.find((item) => item?.documenttype == 1)?.fileurl
            : "",
          signurl: !signfilename
            ? courseUploadDetails?.find((item) => item?.documenttype == 2)
                ?.fileurl ||
              uploadDetails?.find((item) => item?.documenttype == 2)?.fileurl
            : "",
        },
        navigate: navigate,
        edit: true,
        isExtraFee: isExtraFee,
        coursename: coursename,
        subjectgroup: data?.subjectgroup,
        photofilename: photofilename || "",
        signfilename: signfilename || "",
      },
    });
  };

  const handleIconClick = () => {
    setOpen(true);
    setIsUploaded(false);
  };

  const handleNextClick = () => {
    setOpen(false);
    fileInputRef.current.click();
  };

  const handleIconClickSignature = () => {
    setSignatureOpen(true);
    setIsUploaded(false);
  };

  const handleNextSignatureClick = () => {
    setSignatureOpen(false);
    fileInputRefSignature.current.click();
  };

  const handleDialogClose = () => {
    setOpen(false);
    setSignatureOpen(false);
  };

  const handleImageUpload = async (event) => {
    setIsUploaded(false);
    const file = event.target.files[0];
    if (!file) return;
    const fileSizeInKB = file.size / 1024;
    setPhotoSize(fileSizeInKB.toFixed(2));
    setSize(fileSizeInKB);
    setShowModal({ show: true, data: null });
    try {
      const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);
      const convertedFileCompressed = URL.createObjectURL(compressedFile);
      setConfirmPhotoImage("");
      setCropPhotoImage(convertedFileCompressed);
      setConfirmPhotoImage(false);
      setSize("");
      setPhotoImage("");
      setPhotoImage(compressedFile);
      setPhotoSizeCompressed("");
      setPhotoSizeCompressed(compressedFile);
    } catch (err) {
      console.log(err);
    }
    event.target.value = null;
  };

  const onCropDone = (dataURL, crop) => {
    if (!dataURL || !crop) return;
    const byteString = atob(dataURL.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: "image/jpeg" });
    const file = new File([blob], "croppedImage.jpg", { type: "image/jpeg" });
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.files = dataTransfer.files;
    setPhotoImage(fileInput.files[0]);
    dispatch({
      type: candidatesActions.SHOW_IMAGE,
      payload: dataURL,
    });
    dispatch({
      type: candidatesActions.SHOW_COMPRESSED_IMAGE,
      payload: dataURL,
    });
    setShowModal({ show: false, data: null });
  };

  const onCropDoneSignature = (dataURL, crop) => {
    if (!dataURL || !crop) return;
    const byteString = atob(dataURL.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([uint8Array], { type: "image/jpeg" });
    const file = new File([blob], "croppedImage.jpg", { type: "image/jpeg" });
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.files = dataTransfer.files;
    setSignatureImage(fileInput.files[0]);
    dispatch({
      type: candidatesActions.SHOW_SIGNATURE,
      payload: dataURL,
    });
    dispatch({
      type: candidatesActions.SHOW_COMPRESSED_SIGNATURE,
      payload: dataURL,
    });
    setShowModalSignature({ show: false, data: null });
  };

  const onCropCancelSignature = () => {
    setShowModalSignature({ show: false, data: null });
    dispatch({
      type: candidatesActions.SHOW_SIGNATURE,
      payload: null,
    });
    dispatch({
      type: candidatesActions.SHOW_COMPRESSED_SIGNATURE,
      payload: null,
    });
  };

  const onCropCancel = () => {
    setShowModal({ show: false, data: null });
    dispatch({
      type: candidatesActions.SHOW_IMAGE,
      payload: null,
    });
    dispatch({
      type: candidatesActions.SHOW_COMPRESSED_IMAGE,
      payload: null,
    });
  };

  const handleClose = () => {
    setShowModal({ show: false, data: null });
  };

  const handleSignatureUpload = async (event) => {
    const file = event.target.files[0];
    const fileSizeInKB = file.size / 1024;
    setSize(fileSizeInKB);
    setSignatureSize(fileSizeInKB.toFixed(2));
    setShowModalSignature({ show: true, data: null });
    try {
      const options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 800,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);
      const convertedFileCompressed = URL.createObjectURL(compressedFile);
      setCropSignatureImage(convertedFileCompressed);
      setSignatureSizeCompressed(compressedFile);
      setSignatureImage(compressedFile);
    } catch (err) {
      console.log(err);
    }
    event.target.value = null;
  };

  return (
    <>
      <Grid
        style={{
          padding: "20px 25px 20px 25px",
          minHeight: "60vh",
          backgroundColor: "#fff",
          borderRadius: "8px",
          border: "1px solid #0F4F96",
        }}
      >
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography sx={{ fontSize: 24 }}>Update Details</Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              label={
                <>
                  Candidate Name<span className="error">*</span>
                </>
              }
              placeholder="Enter candidate name"
              {...register("candidatename", {
                required: "Candidate name is required",
              })}
              onInput={AlphabetsValidationUppercase}
              inputProps={{
                maxLength: 50,
              }}
              error={!!errors.candidatename}
              helperText={errors?.candidatename?.message}
              FormHelperTextProps={{
                style: { margin: 0 },
              }}
              InputLabelProps={{
                shrink: !!watch("candidatename"),
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                fullWidth
                label={
                  <>
                    DOB<span className="error">*</span>
                  </>
                }
                name="dob"
                id="dob"
                {...register("dob", {
                  required: "DOB is required",
                  validate: {
                    validDate: (date) => {
                      const isValid =
                        date &&
                        dayjs(date).isBetween(
                          dayjs("1900-01-01"),
                          dayjs().subtract(15, "year"),
                          null,
                          "[]"
                        );

                      return (
                        isValid ||
                        `Date must be between ${dayjs("1900-01-01").format(
                          "DD-MM-YYYY"
                        )} and ${dayjs()
                          .subtract(15, "year")
                          .endOf("year")
                          .format("DD-MM-YYYY")}`
                      );
                    },
                  },
                })}
                value={watch("dob") ? dayjs(watch("dob"), "DD/MM/YYYY") : null}
                onChange={(date) => {
                  const localDate = dayjs(date).format("DD/MM/YYYY");
                  setValue(
                    "dob",
                    localDate,
                    { shouldDirty: true },
                    { shouldValidate: true }
                  );
                }}
                format="DD/MM/YYYY"
                maxDate={dayjs().subtract(15, "year").endOf("year")}
                minDate={dayjs("1900-01-01")}
                slotProps={{
                  textField: {
                    helperText: errors.dob ? errors.dob.message : "",
                    error: !!errors.dob,
                    style: {
                      width: "100%",
                      borderRadius: "8px",
                    },
                    FormHelperTextProps: {
                      style: {
                        margin: 0,
                      },
                    },
                  },
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth error={!!errors.genderid}>
              <InputLabel shrink={!!watch("genderid")} htmlFor="genderid">
                Gender
                <span className="error" style={{ color: "red" }}>
                  {" "}
                  *
                </span>
              </InputLabel>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="genderid"
                label="Gender"
                {...register("genderid", { required: "Gender is required" })}
                onChange={(e) => {
                  setValue("genderid", e.target.value, { shouldDirty: true });
                  trigger("genderid");
                }}
                value={watch("genderid") || ""}
              >
                <MenuItem value="">Select One</MenuItem>
                {gender?.map((val, i) => (
                  <MenuItem value={val?.genderid} key={i}>
                    {val?.gender}
                  </MenuItem>
                ))}
              </Select>
              {errors.genderid && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.genderid.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth error={!!errors.genderid}>
              <InputLabel
                shrink={!!watch("subjectgroup")}
                htmlFor="subjectgroup"
              >
                <>
                  {
                    masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SELECT_SUBJECT_GROUP
                    )?.fieldlabel
                  }

                  {masterFields?.find(
                    (field) =>
                      field?.masterfieldlabel ===
                      masterFieldLabel.SELECT_SUBJECT_GROUP
                  )?.ismandatory === "1" && (
                    <span className="error" style={{ color: "red" }}>
                      {" "}
                      *
                    </span>
                  )}
                </>
              </InputLabel>
              <Select
                MenuProps={{
                  disableScrollLock: true,
                }}
                inputProps={{
                  style: { height: "56px", boxSizing: "border-box" },
                }}
                id="subjectgroup"
                label={
                  <>
                    {
                      masterFields?.find(
                        (field) =>
                          field?.masterfieldlabel ===
                          masterFieldLabel.SELECT_SUBJECT_GROUP
                      )?.fieldlabel
                    }

                    {masterFields?.find(
                      (field) =>
                        field?.masterfieldlabel ===
                        masterFieldLabel.SELECT_SUBJECT_GROUP
                    )?.ismandatory === "1" && (
                      <span className="error" style={{ color: "red" }}>
                        {" "}
                        *
                      </span>
                    )}
                  </>
                }
                {...register("subjectgroup", {
                  required: "Subject group is required",
                })}
                onChange={(e) => {
                  setValue("subjectgroup", e.target.value, {
                    shouldDirty: true,
                  });
                  trigger("subjectgroup");
                }}
                value={watch("subjectgroup") || ""}
                disabled={
                  courseFormDetails[0]?.ispcmbpaid === "1" ? true : false
                }
              >
                <MenuItem value="" disabled>
                  Select one
                </MenuItem>
                <MenuItem value="PCM">PCM</MenuItem>
                <MenuItem value="PCB">PCB</MenuItem>
                <MenuItem value="PCMB">PCMB</MenuItem>
              </Select>
              {errors.subjectgroup && (
                <FormHelperText style={{ margin: 0 }}>
                  {errors.subjectgroup.message}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          {uploadDetails?.find((item, index) => item?.documenttype == 1)
            ?.fileurl && !showImage ? (
            <Grid
              item
              md={12}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <InputLabel style={{ fontWeight: "bold" }}>
                Upload Image
                <span className="error" style={{ color: "#666666" }}>
                  *
                </span>
              </InputLabel>
              <Box
                sx={{
                  display: "flex",
                  gap: "2%",
                  marginTop: ".4rem",
                  flexDirection: isMobile ? "column" : "",
                }}
              >
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "150px",
                    }}
                  >
                    <img
                      src={
                        courseUploadDetails?.length
                          ? courseUploadDetails?.find(
                              (item) => item?.documenttype == 1
                            )?.fileurl
                          : uploadDetails?.find(
                              (item) => item?.documenttype == 1
                            )?.fileurl
                      }
                      alt="Original Image"
                      style={{
                        height: "140px",
                        width: "auto",
                        borderRadius: "4px",
                      }}
                    />

                    <IconButton
                      onClick={handleIconClick}
                      style={{
                        position: "absolute",
                        top: "-5px",
                        right: "-11px",
                        backgroundColor: "#0F4F96",
                        borderRadius: "50%",
                        padding: "4px",
                        color: "#ffffff",
                        border: "2px solid #FFFFFF",
                      }}
                    >
                      <LuPencilLine style={{ width: "16px", height: "16px" }} />
                    </IconButton>

                    <input
                      type="file"
                      ref={fileInputRef}
                      name="photofilename"
                      id="photofilename"
                      accept=".jpg, .jpeg, .png"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                    />
                  </Box>
                </Box>
              </Box>
              {photoError && <small className="error">{photoError}</small>}
              <br />
              {photoCheckError && (
                <small className="error">{photoCheckError}</small>
              )}
            </Grid>
          ) : showImage ? (
            <Grid item md={12}>
              <InputLabel sx={{ fontWeight: "bold", mb: 1 }}>
                Upload Image
                <span className="error" style={{ color: "#666666" }}>
                  *
                </span>
              </InputLabel>
              <Grid item md={12} style={{ display: "flex", gap: "5%" }}>
                {showImage && (
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Box
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={showImage}
                        alt="Original Image"
                        style={{
                          height: "180px",
                          width: "auto",
                          borderRadius: "4px",
                        }}
                      />
                      <IconButton
                        onClick={handleIconClick}
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-18px",
                          backgroundColor: "#0F4F96",
                          borderRadius: "50%",
                          padding: "4px",
                          color: "#ffffff",
                          border: "2px solid #FFFFFF",
                        }}
                      >
                        <LuPencilLine
                          style={{ width: "16px", height: "16px" }}
                        />
                      </IconButton>
                    </Box>

                    <input
                      type="file"
                      ref={fileInputRef}
                      name="photofilename"
                      id="photofilename"
                      accept=".jpg, .jpeg, .png"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                    />

                    <Typography
                      variant="caption"
                      style={{ paddingTop: "10px" }}
                    >
                      Uploaded image
                    </Typography>

                    {cropPhotoImage !== "" && photoSize > 300 && (
                      <small className="photo-size">{photoSize} KB</small>
                    )}
                  </Box>
                )}
                {showCompressedImage && photoSize > 300 && (
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <img
                      src={showCompressedImage}
                      alt="Compressed Image"
                      style={{ height: "180px", width: "auto" }}
                    />
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Typography
                        variant="caption"
                        style={{ paddingTop: "10px" }}
                      >
                        Compressed image
                      </Typography>
                    </Box>
                    {cropPhotoImage !== "" && (
                      <small className="photo-size">
                        {(photoSizeCompressed.size / 1024).toFixed(2)} KB
                      </small>
                    )}
                  </Box>
                )}
              </Grid>
              {photoCheckError && (
                <small className="error">{photoCheckError}</small>
              )}
            </Grid>
          ) : null}
          <Grid
            item
            md={12}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {uploadDetails?.find((item, index) => item?.documenttype == 2)
              ?.fileurl && !showSignature ? (
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <InputLabel style={{ fontWeight: "bold" }}>
                  Upload Signature
                  <span className="error" sx={{ color: "#666666" }}>
                    *
                  </span>
                </InputLabel>
                <Box
                  style={{
                    display: "flex",
                    gap: "2%",
                    marginTop: ".4rem",
                    flexDirection: isMobile ? "column" : "",
                  }}
                >
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Box
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <img
                        src={
                          courseUploadDetails?.length
                            ? courseUploadDetails?.find(
                                (item) => item?.documenttype == 2
                              )?.fileurl
                            : uploadDetails?.find(
                                (item) => item?.documenttype == 2
                              )?.fileurl
                        }
                        alt="Original Signature"
                        style={{
                          height: "140px",
                          width: "auto",
                          borderRadius: "4px",
                        }}
                      />

                      <IconButton
                        onClick={handleIconClickSignature}
                        style={{
                          position: "absolute",
                          top: "-5px",
                          right: "-18px",
                          backgroundColor: "#0F4F96",
                          borderRadius: "50%",
                          padding: "4px",
                          color: "#ffffff",
                          border: "2px solid #FFFFFF",
                        }}
                      >
                        <LuPencilLine
                          style={{ width: "16px", height: "16px" }}
                        />
                      </IconButton>

                      <input
                        type="file"
                        ref={fileInputRefSignature}
                        name="signaturefilename"
                        id="signaturefilename"
                        accept=".jpg, .jpeg, .png"
                        style={{ display: "none" }}
                        onChange={handleSignatureUpload}
                      />
                    </Box>
                  </Box>
                </Box>
                {signatureError && (
                  <small className="error">{signatureError}</small>
                )}
                <br />
                {signatureCheckError && (
                  <small className="error">{signatureCheckError}</small>
                )}
              </Box>
            ) : showSignature ? (
              <>
                <InputLabel sx={{ fontWeight: "bold", mb: 1 }}>
                  Upload Signature
                  <span className="error" style={{ color: "#666666" }}>
                    *
                  </span>
                </InputLabel>
                <Box style={{ display: "flex", gap: "5%" }}>
                  {showSignature && (
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        <img
                          src={showSignature}
                          alt="Original Signature"
                          style={{
                            height: "140px",
                            width: "auto",
                            borderRadius: "4px",
                          }}
                        />
                        <IconButton
                          onClick={handleIconClickSignature}
                          style={{
                            position: "absolute",
                            top: "-5px",
                            right: "-18px",
                            backgroundColor: "#0F4F96",
                            borderRadius: "50%",
                            padding: "4px",
                            color: "#ffffff",
                            border: "2px solid #FFFFFF",
                          }}
                        >
                          <LuPencilLine
                            style={{ width: "16px", height: "16px" }}
                          />
                        </IconButton>
                      </Box>
                      <input
                        type="file"
                        ref={fileInputRefSignature}
                        name="signaturefilename"
                        id="signaturefilename"
                        accept=".jpg, .jpeg, .png"
                        style={{ display: "none" }}
                        onChange={handleSignatureUpload}
                      />
                      <Typography
                        variant="caption"
                        style={{ paddingTop: "10px" }}
                      >
                        Uploaded signature
                      </Typography>

                      {cropSignatureImage !== "" && signatureSize > 300 && (
                        <small className="photo-size">{signatureSize} KB</small>
                      )}
                    </Box>
                  )}
                  {showCompressedSignature && signatureSize > 300 && (
                    <Box style={{ display: "flex", flexDirection: "column" }}>
                      <img
                        src={showCompressedSignature}
                        alt="Compressed Signature"
                        style={{
                          height: "140px",
                          width: "auto",
                          borderRadius: "4px",
                        }}
                      />
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <Typography
                          variant="caption"
                          style={{ paddingTop: "10px" }}
                        >
                          Compressed signature
                        </Typography>
                      </Box>
                      {cropSignatureImage !== "" && (
                        <small className="photo-size">
                          {(signatureSizeCompressed.size / 1024).toFixed(2)} KB
                        </small>
                      )}
                    </Box>
                  )}
                </Box>
                {signatureCheckError && (
                  <small className="error">{signatureCheckError}</small>
                )}
              </>
            ) : null}
          </Grid>
          <Grid
            item
            md={12}
            pt={2}
            sx={{
              display: "flex",
              gap: "8px",
              justifyContent: "flex-end",
              flexDirection: isMobile ? "column" : null,
              marginBottom: 1,
            }}
          >
            {isUploaded ? (
              <>
                <SubmitButton
                  name={buttonLoader ? "loader" : "Save and Proceed"}
                  handleSubmit={handleSubmit(handleSave)}
                  disabled={!isDirty}
                />
              </>
            ) : (
              <>
                <SubmitButton
                  name={buttonLoader ? "loader" : "Save and Proceed"}
                  handleSubmit={handleSubmit(handleSave)}
                  disabled={isUploaded}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        pb={2}
        pt={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth="xs"
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{ textAlign: "center", position: "relative" }}
          >
            SAMPLE IMAGE
            <IconButton
              aria-label="close"
              onClick={handleDialogClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "red",
              }}
            >
              <IoCloseOutline />
            </IconButton>
          </DialogTitle>

          <DialogContent
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <img
                src={userphoto}
                alt="Sample"
                style={{
                  width: "135px",
                  height: "148px",
                  borderRadius: "8px",
                }}
              />
            </Box>
            <Typography variant="h6" sx={{ color: "#504E50", fontWeight: 500 }}>
              Instructions for Photo:-
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: "3px" }}>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  1. Your photo must be taken within the last six months to
                  reflect your current appearance.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  2. Your head should be centered and facing forward, covering
                  about 50-70% of the photo.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  3. Both of your ears should be visible, and your eyes should
                  be open and clearly visible.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  4. Use a plain white or off-white background, free from
                  shadows or other decorations in your photo.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  5. Ensure your photo is well-lit with no shadows on your face
                  or in the background.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={handleNextClick}
              name={buttonLoader ? "loader" : "Next"}
            />
          </DialogActions>
        </Dialog>

        <Dialog
          open={signatureOpen}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          maxWidth="xs"
        >
          <DialogTitle
            id="responsive-dialog-title"
            sx={{ textAlign: "center" }}
          >
            SAMPLE SIGNATURE{" "}
            <IconButton
              aria-label="close"
              onClick={handleDialogClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "red",
              }}
            >
              <IoCloseOutline />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              maxHeight: "400px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <img
                src={sign}
                alt="Sample"
                style={{
                  width: "181px",
                  height: "88px",
                  borderRadius: "8px",
                  marginTop: "15px",
                }}
              />
              <IoCheckmarkOutline
                style={{
                  width: "23px",
                  height: "23px",
                  right: 14,
                  position: "relative",
                  top: -31,
                  backgroundColor: "#03A600",
                  borderRadius: "50%",
                  color: "#FFFFFF",
                }}
              />
            </Box>
            <Typography variant="h6" sx={{ color: "#504E50", fontWeight: 500 }}>
              Instructions for signature:-
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: "3px" }}>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  1. Make sure your signature is clear and legible without any
                  smudges or overlaps.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  2. Use a plain white background for your signature to ensure
                  clear visibility.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  3. Use a black or dark blue ink pen to sign. Avoid using other
                  colors as they may not scan well.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" sx={{ color: "#504E50" }}>
                  4.The signature should be done by you personally, not by any
                  other person or digitally generated.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <SubmitButton
              handleSubmit={handleNextSignatureClick}
              name={buttonLoader ? "loader" : "Next"}
            />
          </DialogActions>
        </Dialog>
      </Grid>
      {cropPhotoImage && (
        <ImageCrop
          showModal={showModal?.show}
          handleClose={handleClose}
          image={cropPhotoImage}
          onCropDone={onCropDone}
          onCropCancel={onCropCancel}
        />
      )}
      {cropSignatureImage && (
        <ImageCropSignature
          showModal={showModalSignature?.show}
          handleClose={handleClose}
          image={cropSignatureImage}
          onCropDone={onCropDoneSignature}
          onCropCancel={onCropCancelSignature}
        />
      )}
    </>
  );
}
